<template>
    <div class="root d-flex justify-content-center align-items-center postion-relative">
        <div class="selectors position-absolute">
            <div class="action position-relative">
                <!-- Background image, that fits the width and grows in height as necessary; this image will change according to the action selected -->                
                <div class="action-background w-100 position-absolute" :style="{'background-image': 'url(' + require(`../assets/Login/${backgroundImg}`) + ')', 'background-size': action == LOGINACTION || action == REGISTERACTION ? 'cover' : '100% calc(100%)' }"></div>
                <div class="d-flex flex-column">
                    <!-- Action icons -->
                    <div class="action-selectors d-flex justify-content-around mt-5">
                        <div class="d-flex flex-column align-items-center justify-content-center cursor-pointer" @click="action = LOGINACTION">
                            <img class="action-button" src="../assets/Login/login-button.svg"/>
                            <span class="action-text">{{$t("login.login").toUpperCase()}}</span>
                        </div>
                        <div class="d-flex flex-column align-items-center justify-content-center cursor-pointer" @click="action = REGISTERACTION">
                            <img class="action-button" src="../assets/Login/register-button.svg"/>
                            <span class="action-text">{{$t("login.register").toUpperCase()}}</span>
                        </div>
                        <div class="d-flex flex-column align-items-center justify-content-center cursor-pointer" @click="action = RECOVERACTION">
                            <img class="action-button" src="../assets/Login/recover-button.svg"/>
                            <span class="action-text">{{$t("login.recover").toUpperCase()}}</span>
                        </div>
                    </div>
                    <!-- Login -->
                    <form id="login-form" v-if="action == LOGINACTION" class="d-flex flex-column justify-content-between align-items-center h-100 text-center p-5 mt-5" @submit.prevent="handleLoginSubmit">
                        <!-- Username -->
                        <div class="form-group w-100">
                            <label for="username">{{ $t("login.user") }}</label>
                            <input v-model="username" id="username" name="username" class="form-control" :class="{ 'is-invalid': submitted && hasError('Username') }" />
                            <div v-if="submitted && hasError('Username')" class="invalid-feedback">
                                <span v-if="!$v.username.required">{{ $t("login.valUser") }}</span>
                                <span v-if="errors.Username">{{errors.Username}}</span>
                            </div>
                        </div>
                        <!-- Password -->
                        <div class="form-group w-100" id="input-password">
                            <label for="password">{{ $t("login.password") }}</label>
                            <input id="password" type="password" name="password" class="form-control input-form" :class="{ 'is-invalid': submitted && hasError('Password') }"
                            v-model="password"/>
                            <img class="cursor-pointer" v-show="!hasError(errors, 'password')" id="visibility-icon"
                            @click="switchVisibility('password')" src="@/assets/Login/visibilityPassword-icon.svg" alt="">
                            <div v-if="submitted && hasError('Password')" class="invalid-feedback">
                                <span v-if="!$v.password.required">{{ $t("login.valPassword") }}</span>
                                <span v-if="!$v.password.minLength">{{ $t("login.lengthPass") }}</span>
                                <span v-if="errors.Password">{{errors.Password}}</span>
                            </div>
                        </div>
                        <!-- Submit button -->
                        <div class="form-group w-100">
                            <button class="submit-button w-100" v-bind:class="{disabled: loading || !username || !password}">
                                <span v-show="!loading">{{$t("login.login").toUpperCase()}}</span>
                                <span v-show="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                <span v-show="loading" class="sr-only">{{$t("loading")}}</span>
                            </button>                    
                        </div>
                        <!-- Info message -->
                        <span class="action-info text-center">{{ $t("login.mens") }}</span>
                    </form>
                    <!-- Register -->
                    <form id="register-form" v-else-if="action == REGISTERACTION" class="d-flex flex-column justify-content-between align-items-center h-100 text-center p-5 mt-5" @submit.prevent="handleRegisterSubmit">
                        <!-- First name -->
                        <div class="form-group w-100">
                            <label for="firstName">{{ $t("login.firstName") }}</label>
                            <input type="text" v-model="firstName" id="firstName" name="firstName" class="form-control" :class="{ 'is-invalid': submitted && hasError('FirstName') }" />
                            <div v-if="submitted && hasError('FirstName')" class="invalid-feedback">
                                <span v-if="!$v.firstName.required">{{ $t("login.valFirstName") }}</span>
                                <span v-if="errors.FirstName">{{errors.FirstName}}</span>
                            </div>
                        </div>
                        <!-- Last name -->
                        <div class="form-group w-100">
                            <label for="lastName">{{ $t("login.lastName") }}</label>
                            <input type="text" v-model="lastName" id="lastName" name="lastName" class="form-control" :class="{ 'is-invalid': submitted && hasError('LastName') }" />
                            <div v-if="submitted && hasError('LastName')" class="invalid-feedback">
                                <span v-if="!$v.lastName.required">{{ $t("login.valLastName") }}</span>
                                <span v-if="errors.LastName">{{errors.LastName}}</span>
                            </div>
                        </div>
                        <!-- Username -->
                        <div class="form-group w-100">
                            <label for="username">{{ $t("login.user") }}</label>
                            <input v-model="username" id="username" name="username" class="form-control" :class="{ 'is-invalid': submitted && (hasError('Username') || hasError('InvalidUserName') || hasError('DuplicateUserName')) }" />
                            <div v-if="submitted && (hasError('Username') || hasError('InvalidUserName') || hasError('DuplicateUserName'))" class="invalid-feedback">
                                <span v-if="!$v.username.required">{{ $t("login.valUser") }}</span>
                                <span v-if="errors.InvalidUserName">{{errors.InvalidUserName}}</span>
                                <span v-if="errors.DuplicateUserName">{{errors.DuplicateUserName}}</span>
                            </div>
                        </div>
                        <!-- Email -->
                        <div class="form-group w-100">
                            <label for="email">{{ $t("login.email") }}</label>
                            <input type="email" v-model="email" id="email" name="email" class="form-control" :class="{ 'is-invalid': submitted && (hasError('Email') || hasError('DuplicateEmail') || hasError('InvalidEmail')) }" />
                            <div v-if="submitted && (hasError('Email') || hasError('DuplicateEmail') || hasError('InvalidEmail'))" class="invalid-feedback">
                                <span v-if="!$v.email.required">{{ $t("login.valEmail") }}</span>
                                <span v-if="!$v.email.email">{{ $t("login.invEmail") }}</span>
                                <span v-if="errors.DuplicateEmail">{{errors.DuplicateEmail}}</span>
                                <span v-if="errors.InvalidEmail">{{errors.InvalidEmail}}</span>
                            </div>
                        </div>
                        <!-- Password -->
                        <div class="form-group w-100" id="input-password">
                            <label for="password">Password</label>
                            <input id="password" type="password" name="password" class="form-control input-form" :class="{ 'is-invalid': submitted && (hasError('Password') || hasError('PasswordTooShort') || hasError('PasswordRequiresNonAlphanumeric') || hasError('PasswordRequiresDigit') || hasError('PasswordRequiresLower') || hasError('PasswordRequiresUpper')) }"
                            v-model="password"/>
                            <img class="cursor-pointer" v-show="!hasError(errors, 'password')" id="visibility-icon"
                            @click="switchVisibility('password')" src="@/assets/Login/visibilityPassword-icon.svg" alt="">
                            <div v-if="submitted && (hasError('Password') || hasError('PasswordTooShort') || hasError('PasswordRequiresNonAlphanumeric') || hasError('PasswordRequiresDigit') || hasError('PasswordRequiresLower') || hasError('PasswordRequiresUpper'))" class="invalid-feedback">
                                <span v-if="!$v.password.required">{{ $t("login.valPassword") }}</span>
                                <span v-if="errors.PasswordTooShort">{{errors.PasswordTooShort}}</span>
                                <span v-if="errors.PasswordRequiresNonAlphanumeric">{{errors.PasswordRequiresNonAlphanumeric}}</span>
                                <span v-if="errors.PasswordRequiresDigit">{{errors.PasswordRequiresDigit}}</span>
                                <span v-if="errors.PasswordRequiresLower">{{errors.PasswordRequiresLower}}</span>
                                <span v-if="errors.PasswordRequiresUpper">{{errors.PasswordRequiresUpper}}</span>
                            </div>
                        </div>
                        <!-- Confirm password -->
                        <div class="form-group w-100" id="input-password">
                            <label for="password">{{ $t("login.rpass") }}</label>
                            <input id="confirmPassword" type="password" name="confirmPassword" class="form-control input-form" :class="{ 'is-invalid': submitted && hasError('ConfirmPassword') }"
                            v-model="confirmPassword"/>
                            <img class="cursor-pointer" v-show="!hasError(errors, 'confirmPassword')" id="visibility-icon"
                            @click="switchVisibility('confirmPassword')" src="@/assets/Login/visibilityPassword-icon.svg" alt="">
                            <div v-if="submitted && hasError('ConfirmPassword')" class="invalid-feedback">
                                <span v-if="!$v.confirmPassword.required">{{ $t("login.valPass") }}</span>
                                <span v-else-if="!$v.confirmPassword.sameAsPassword">{{ $t("login.matchPass") }}</span>
                            </div>
                        </div>
                        <!-- Terms and conditions -->
                        <div class="form-group d-flex w-100 action-info mt-4">
                            <input type="checkbox" v-model="acceptTerms"/>
                            <span class="w-100">{{ $t("login.termsOne") }}</span>
                        </div>
                        <div class="form-group d-flex w-100 action-info mb-2">
                            <input type="checkbox" v-model="newsletter"/>
                            <span class="w-100">{{ $t("login.termsTwo") }}</span>
                        </div>
                        <!-- Submit button -->
                        <div class="form-group w-100" v-bind:class="{disabled: loading || !acceptTerms}">
                            <button  class="submit-button w-100">
                                <span v-show="!loading">{{$t("login.register").toUpperCase()}}</span>
                                <span v-show="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                <span v-show="loading" class="sr-only">{{$t("loading")}}</span>
                            </button>
                        </div>
                    </form>
                    <!-- Recover (request link) -->
                    <form id="recover-form" v-if="action == RECOVERACTION && !requestedLink" class="d-flex flex-column justify-content-between align-items-center h-100 text-center p-5 mt-5" @submit.prevent="handleRecoverSubmit">
                        <!-- Email -->
                        <div class="form-group w-100">
                            <label for="email">{{ $t("login.email") }}</label>
                            <input type="email" v-model="email" id="email" name="email" class="form-control" :class="{ 'is-invalid': submitted && hasError('Email') }" />
                            <div v-if="submitted && hasError('Email')" class="invalid-feedback">
                                <span v-if="!$v.email.required">{{ $t("login.valEmail") }}</span>
                                <span v-if="!$v.email.email">{{ $t("login.invEmail") }}</span>
                                <span v-if="errors.Email">{{errors.Email}}</span>
                            </div>
                        </div>
                        <!-- Submit button -->
                        <div class="form-group w-100" v-bind:class="{disabled: loading || !email}">
                            <button class="submit-button w-100">
                                <span v-show="!loading">{{$t("login.recover").toUpperCase()}}</span>
                                <span v-show="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                <span v-show="loading" class="sr-only">{{$t("loading")}}</span>
                            </button>
                        </div>
                        <!-- Info message -->
                        <span class="action-info text-center">{{ $t("login.infoMsg") }}</span>
                    </form>
                    <!-- Recover (define password) -->
                    <form id="recover-form" v-else-if="action == RECOVERACTION && requestedLink" class="d-flex flex-column justify-content-between align-items-center h-100 text-center p-5 mt-5" @submit.prevent="handleResetSubmit">
                        <!-- Username -->
                        <div class="form-group w-100">
                            <label for="username">{{ $t("login.user") }}</label>
                            <input v-model="username" id="username" name="username" class="form-control" :class="{ 'is-invalid': submitted && hasError('Username') }" />
                            <div v-if="submitted && hasError('Username')" class="invalid-feedback">
                                <span v-if="!$v.username.required">{{ $t("login.valUser") }}</span>
                                <span v-if="errors.Username">{{errors.Username}}</span>
                            </div>
                        </div>
                        <!-- Token -->
                        <div class="form-group w-100">
                            <label for="token">{{ $t("login.securityToken") }}</label>
                            <input type="number" v-model="token" id="token" name="token" class="form-control" :class="{ 'is-invalid': submitted && hasError('Token') }" />
                            <div v-if="submitted && hasError('Token')" class="invalid-feedback">
                                <span v-if="!$v.token.required">{{ $t("login.valToken") }}</span>
                                <span v-if="errors.Token">{{errors.Token}}</span>
                            </div>
                        </div>
                        <!-- Password -->
                        <div class="form-group w-100" id="input-password">
                            <label for="password">Password</label>
                            <input id="password" type="password" name="password" class="form-control input-form" :class="{ 'is-invalid': submitted && (hasError('Password') || hasError('PasswordTooShort') || hasError('PasswordRequiresNonAlphanumeric') || hasError('PasswordRequiresDigit') || hasError('PasswordRequiresLower') || hasError('PasswordRequiresUpper')) }"
                            v-model="password"/>
                            <img class="cursor-pointer" v-show="!hasError(errors, 'password')" id="visibility-icon"
                            @click="switchVisibility('password')" src="@/assets/Login/visibilityPassword-icon.svg" alt="">
                            <div v-if="submitted && (hasError('Password') || hasError('PasswordTooShort') || hasError('PasswordRequiresNonAlphanumeric') || hasError('PasswordRequiresDigit') || hasError('PasswordRequiresLower') || hasError('PasswordRequiresUpper'))" class="invalid-feedback">
                                <span v-if="!$v.password.required">{{ $t("login.valPassword") }}</span>
                                <span v-if="errors.PasswordTooShort">{{errors.PasswordTooShort}}</span>
                                <span v-if="errors.PasswordRequiresNonAlphanumeric">{{errors.PasswordRequiresNonAlphanumeric}}</span>
                                <span v-if="errors.PasswordRequiresDigit">{{errors.PasswordRequiresDigit}}</span>
                                <span v-if="errors.PasswordRequiresLower">{{errors.PasswordRequiresLower}}</span>
                                <span v-if="errors.PasswordRequiresUpper">{{errors.PasswordRequiresUpper}}</span>
                            </div>
                        </div>
                        <!-- Confirm password -->
                        <div class="form-group w-100" id="input-password">
                            <label for="password">{{ $t("login.rpass") }}</label>
                            <input id="confirmPassword" type="password" name="confirmPassword" class="form-control input-form" :class="{ 'is-invalid': submitted && hasError('ConfirmPassword') }"
                            v-model="confirmPassword"/>
                            <img class="cursor-pointer" v-show="!hasError(errors, 'confirmPassword')" id="visibility-icon"
                            @click="switchVisibility('confirmPassword')" src="@/assets/Login/visibilityPassword-icon.svg" alt="">
                            <div v-if="submitted && hasError('ConfirmPassword')" class="invalid-feedback">
                                <span v-if="!$v.confirmPassword.required">{{ $t("login.valPass") }}</span>
                                <span v-else-if="!$v.confirmPassword.sameAsPassword">{{ $t("login.matchPass") }}</span>
                            </div>
                        </div>
                        <!-- Submit button -->
                        <div class="form-group w-100" v-bind:class="{disabled: loading || !confirmPassword}">
                            <button class="submit-button w-100">
                                <span v-show="!loading">{{$t("login.recover").toUpperCase()}}</span>
                                <span v-show="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                <span v-show="loading" class="sr-only">{{ $t("loading") }}</span>
                            </button>
                        </div>
                        <!-- Info message -->
                        <span class="action-info text-center">{{ $t("login.infoMsg") }}</span>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { required, email, sameAs } from "vuelidate/lib/validators";
import { UTILS_HELPER } from "@/helpers/utils.js";  
import { mapState, mapActions } from 'vuex';
import { API_HELPER } from "@/helpers/api.js";


const LOGINACTION = 0;
const REGISTERACTION = 1;
const RECOVERACTION = 2;

export default {
       metaInfo () {
      return { 
            title: this.actionName ? `${this.actionName} - Wild Douro` : "Wild Douro", 
            htmlAttrs: {
                lang: this.$i18n.locale,
            },
            meta: 
            [
                {name: 'description', content: this.$t("vueRoutes.intro")},
                {name: 'keywords', content: this.$t("vueRoutes.defaultKeywords")},

                // // Twitter Card
                // {name: 'twitter:card', content: "summary"},
                // {name: 'twitter:title', content: `${this.$t("vueRoutes.vouchersTitle")} - Wild Douro`},
                // {name: 'twitter:description', content: this.$t("vueRoutes.intro")},
                // {name: 'twitter:image', content: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, "assets/hero/hero.jpg"]) },

                // //Facebook OpenGraph
                // {property: 'og:title', content: `${this.$t("vueRoutes.vouchersTitle")} - Wild Douro`},
                // {property: 'og:site_name', content: 'Wild Douro'},
                // {property: 'og:type', content: 'website'},
                // {property: 'og:image', content: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, "assets/hero/hero.jpg"]) },
                // {property: 'og:description', content: this.$t("vueRoutes.intro")}
            ], 
            link: [
                {rel: 'alternate', hreflang: 'en', href: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.$t("vueRoutes.login", 'en')])},
                {rel: 'alternate', hreflang: 'pt', href: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.$t("vueRoutes.login", 'pt')])},
            ]
        } 
    },
components:{},
 data: function () {
    return {
        action: LOGINACTION,
        username: "",
        firstName: "",
        lastName: "",
        email: "",
        token: '',
        password: "",
        confirmPassword: "",
        newsletter: false,
        submitted: false,
        requestedLink: false,
        acceptTerms: false,
        loading: false,
        // Expose for HTML
        LOGINACTION: LOGINACTION,
        REGISTERACTION: REGISTERACTION,
        RECOVERACTION: RECOVERACTION
    };
  },
    validations() {
        switch(this.action) 
        {
            case LOGINACTION:
            {
                return {
                    username: { required },
                    password: { required }
                }
            }
            case REGISTERACTION:
            {
                return {
                    firstName: { required },
                    lastName: { required },
                    username: { required },
                    email: { required, email },
                    password: { required },
                    confirmPassword: { required, sameAsPassword: sameAs('password') }
                }
            }
            case RECOVERACTION:
            {
                if(!this.requestedLink)
                {
                    return {
                        email: { required, email }
                    }
                }
                else
                {
                    return {
                        username: { required },
                        token: { required },
                        password: { required },
                        confirmPassword: { required, sameAsPassword: sameAs('password') }
                    }
                }
            }

        } 
    },
    methods: {
        ...mapActions('account', ['login']),
        ...mapActions({
            clearAlert: 'alert/clear' 
        }),
        ...mapActions('account', ['signup']),
        ...mapActions('account', ['requestLinkPassword']),
        ...mapActions('account', ['resetPassword']),
        handleLoginSubmit() {
            this.clearAlert();

            this.submitted = true;
            // stop here if form is invalid
            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            this.loading = true;

            const { username, password } = this;
            this.login({  username, password })
        },
        handleRegisterSubmit() {
            this.clearAlert();
            this.submitted = true;

            // stop here if form is invalid
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }
            this.loading = true;
            
            const { firstName,lastName,username,email, password, newsletter} = this;
            this.signup({firstName,lastName,username,email, password, newsletter});
        },
        handleRecoverSubmit(){
            this.clearAlert();
            this.submitted = true;

            // stop here if form is invalid
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }
            this.loading = true;

            const { email } = this;
            this.requestLinkPassword(email)
        },
        handleResetSubmit(){
            this.clearAlert();
            this.submitted = true;

            // stop here if form is invalid
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }
            this.loading = true;

            const { username, password, token } = this;
            this.resetPassword({ username, password, token })
        },
        resetForm(){
            this.submitted= false;
            this.username= "";
            this.firstName= "";
            this.lastName= "";
            this.email= "";
            this.token= '';
            this.password= "";
            this.confirmPassword= "";
            this.newsletter= false;
            this.acceptTerms= false;
        },
        validator(val) 
        {
            var result = undefined; // This is the default, in case the user hasn't submitted yet

            if(this.submitted && (!val || this.loginError))
            {
                result = false;
            }

            return result;
        },
        hasError(fieldName)
        {
            var hasLocalError = false;

            if(this.$v[UTILS_HELPER.convertToCamelCase(fieldName)])
            {
                hasLocalError = this.$v[UTILS_HELPER.convertToCamelCase(fieldName)].$error;
            }

            return hasLocalError || Object.prototype.hasOwnProperty.call(this.errors, fieldName);
        },
        switchVisibility(selector) {
            const passwordField = document.querySelector(`#${selector}`)
            if (passwordField.getAttribute('type') === 'password') passwordField.setAttribute('type', 'text')
            else passwordField.setAttribute('type', 'password')
        },
    },
  computed: 
  {
    ...mapState({ 
        alertType: state => state.alert.type,
        alertOutput: state => state.alert.output
    }),
    ...mapState('account', ['status']),

    errors: function()
    {
      return this.alertOutput && this.alertOutput.Errors ? this.alertOutput.Errors : {};   
    },
    actionName: function()
    {
        var result;

        switch(this.action) 
        {
            case LOGINACTION:
            {
                return this.$t('vueRoutes.loginTitle')
            }
            case REGISTERACTION:
            {
                return this.$t('vueRoutes.register')
            }
            case RECOVERACTION:

            if(!this.requestedLink)
                {
                    return this.$t('vueRoutes.recoverPassword')
                }
                else
                {
                    return this.$t('vueRoutes.resetPassword')
                }
        } 
        return result
    },
      backgroundImg: function()
      {
          var result;

          switch(this.action)
          {
            case LOGINACTION:
            {
                result = "login-background.svg";
                break;
            }   
            case REGISTERACTION:
            {
                result = "register-background.svg";
                break;
            }  
            case RECOVERACTION:
            {
                result = "recover-background.svg";
                break;
            }   
          }

          return result;
      }
    },
    watch:
    {
        action: function()
        {
            this.submitted = false;
        },
        alertType: function(val)
        {
            if(val != null)
            {
                this.loading = false;
                
                if(val == "alert-danger")
                {
                    this.$emit("error-alert-requested", { message: this.$t("errorAcc") });
                }
                else if(val == "alert-success")
                {
                    if(this.action == REGISTERACTION)
                    {
                        this.$emit("success-alert-requested", { message: this.$t("registAcc")});
                        this.resetForm();
                    }
                    else if(this.action == RECOVERACTION)
                    {
                        this.$emit("success-alert-requested", { message: this.requestedLink ? this.$t("resetAcc") : this.$t("recoverAcc")});
                        if(this.requestedLink)
                        {
                            this.handleLoginSubmit();
                            this.resetForm();
                        }
                        else
                        {
                            this.requestedLink = true;
                            this.submitted = false;
                        }
                    }
                }
            }         
        },
    }
}
</script>

<style scoped>
.root
{
    background-color: #a3161c;
    min-height: 985px;
}
.selectors{
    top: 11%;
}
.action
{
    width: 25vw;
    min-width: 500px;
    min-height: 500px;
}

.action-selectors,
form
{
    z-index: 999;
}

.action-button
{
    width: 60px;
}

.action-text
{
    color: white;
}

.action-background
{
    background-repeat: no-repeat;
    top: 0;
    left: 0;
    right:0;
    bottom: 0;
    border-radius: 40px;
}

form label,
form span
{
    color: white;
}

.form-group
{
    margin-top: 0.2rem;
}

.action-info
{
    font-size: 12px;
}

.submit-button
{
    background-color: #962422;
    border: 2px solid white;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
}
#login-form .submit-button,
#recover-form .submit-button
{
    margin: 1rem 0;
}
>>>input:focus{
  border-color: rgba(150, 36, 34, 1);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(150, 36, 34, 0.075);
  outline: 0 none;
}
input[type="checkbox"] {
  /* remove standard background appearance */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* create custom radiobutton appearance */
  display: inline-block;
  width: 20px;
  height: 20px;
  /* background-color only for content */
  background-clip: content-box;
  border: 4px solid white;
  background-color: unset;
  border-radius: 50%;
}
/* appearance for checked radiobutton */
input[type="checkbox"] {
    background-color: white!important;
}
input[type="checkbox"]:checked {
    background-color: black!important;
}
#input-password {
  position: relative;
}

#visibility-icon {
  position: absolute;
  top: 35px;
  right: 15px;
}
@media screen and (max-height: 900px){
    .root{
      min-height: 1150px;
    }
  .selectors {
    top: 20%;
}
}
@media (max-width: 500px), screen and (max-height: 500px){
  .root{
      min-height: 1150px;
  }
  .action{
      min-width: unset;
      min-height: unset;
      width: unset;
  }
  .action-background{
      background: none !important;
      height: 680px !important;
  }
  .form{
      margin-top: 0;
  }
  .selectors {
    top: 10%;
}
}
@media screen and (max-height: 520px){
  .selectors {
    top: 40%;
}
}

</style>
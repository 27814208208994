<template>
    <section id="root-container">
      <!-- Desktop -->
      <div class="popup-style container-fluid">
            <div class="row form-content">
              <div class="col-12">
                  <div class="close-button cursor-pointer d-flex w-100">
                      <p @click="closeClicked"><img src="@/assets/close.svg" class="w-100"/></p>
                  </div>
              </div>
                <div class="row table-style">
                  <div class="col col-style" v-if="conditions.find(x => x.type == 'Includes')">
                    <span class="title-style">{{$t("experience.priceIncludes")}}</span>
                    <div v-for="condition in conditions.filter(x => x.type == 'Includes')" :key="condition.id">
                      <span class="text-style">{{condition.text}}</span>
                    </div>
                  </div>
                  <div class="col col-style" v-if="conditions.find(x => x.type == 'Excludes')">
                    <span class="title-style">{{$t("experience.priceExcludes")}}</span>
                    <div v-for="condition in conditions.filter(x => x.type == 'Excludes')" :key="condition.id">
                      <span class="text-style">{{condition.text}}</span>
                    </div>
                  </div>
                  <div class="col col-style" v-if="conditions.find(x => x.type == 'Notes')">
                    <span class="title-style">{{$t("experience.importantNotes")}}</span>
                    <div v-for="condition in conditions.filter(x => x.type == 'Notes')" :key="condition.id">
                      <span class="text-style">{{condition.text}}</span>
                    </div>
                  </div>
                </div>
            </div>
      </div>
    </section>
</template>

<script>
// import { mapActions, mapState } from "vuex"; 

export default {
     data(){
        return{
        }  
    },
    props: {
        conditions: Array
    },
    methods:
    {
        closeClicked: function()
        {
            this.$emit("close-request");
        },
    },
}
</script>

<style scoped>
#root-container{
  background-color: #EACCC0;
}
.close-button p
{
  width: 25px;
  position: absolute;
  right: 3rem;
  top: 1rem;
}
input[type="radio"],
input[type="checkbox"]{
  /* remove standard background appearance */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* create custom radiobutton appearance */
  display: inline-block;
  width: 15px;
  height: 15px;
  padding: 2px;
  /* background-color only for content */
  background-clip: content-box;
  border: 2px solid #962422;
  background-color: unset;
  border-radius: 50%;
}
/* appearance for checked radiobutton */
input[type="radio"]:checked,
input[type="checkbox"]:checked {
  background-color: #962422;
}
.form-content{
    padding: 3rem 2rem;
    position: relative;
    /* border-bottom: 1px dashed #962422 !important; */
}
.table-style{
  color: #962422;
}
.title-style{
  color: #962422;
  font-weight: 900;
  font-size: 25px;
}
.text-style{
  color: #962422;
  font-size: 20px;
}
.col-mobile-style{
  /* border: 1px solid white; */
  text-align: center;
}
.includes-style{
  display: contents;
}
.span-title-mobile{
  font-weight: bold;
  font-size: 20px;
}
.description-mobile{
  word-break: break-all;
  font-size: 15px;
}
.span-mobile{
  color: yellow;
  text-align: center;
}
.col-style{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
@media screen and (min-width: 826px) {
  .col-style{
    border-left: 1px dashed #962422 !important;
  }
}
@media screen and (max-width: 825px) {
  .table-style{
    flex-direction: column;
  }
}
</style>
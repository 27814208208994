import { INSPIRATIONS_SERVICE } from "@/services/inspirations.service.js";

const state = { listOutput: null, nightsOutput: null, inspirationOutput: null, dayOutput: null, conditionsOutput: null};

const actions = {
    getList({ dispatch, commit }, inputGetInspirations) {
        dispatch('alert/clear', null, { root: true });
        commit('listRequest');
        
        INSPIRATIONS_SERVICE.list(inputGetInspirations)
            .then(
                output => {
                    commit('listSuccess', output);
                },
                error => {
                    commit('listFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    getNights({ dispatch, commit }) {
        dispatch('alert/clear', null, { root: true });
        commit('listNightsRequest');
        
        INSPIRATIONS_SERVICE.listNights()
            .then(
                output => {
                    commit('listNightsSuccess', output);
                },
                error => {
                    commit('listNightsFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    getDetail({ dispatch, commit }, sku) {
        dispatch('alert/clear', null, { root: true });
        commit('detailRequest');
        
        INSPIRATIONS_SERVICE.get(sku)
            .then(
                output => {
                    commit('detailSuccess', output);
                },
                error => {
                    commit('detailFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    getDays({ dispatch, commit }, day) {
        dispatch('alert/clear', null, { root: true });
        commit('listDaysRequest');
        
        INSPIRATIONS_SERVICE.listDays(day)
            .then(
                output => {
                    commit('listDaysSuccess', output);
                },
                error => {
                    commit('listDaysFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    getConditions({ dispatch, commit }, conditionId) {
        dispatch('alert/clear', null, { root: true });
        commit('listConditionsRequest');
        
        INSPIRATIONS_SERVICE.listConditions(conditionId)
            .then(
                output => {
                    commit('listConditionsSuccess', output);
                },
                error => {
                    commit('listConditionsFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
};

const mutations = {
    /* List */
    listRequest(state) {
        state.listOutput = null;
    },
    listSuccess(state, output) {
        state.listOutput = output;
    },
    listFailure(state) {
        state.listOutput = false;
    },
    /* Nights */
    listNightsRequest(state) {
        state.nightsOutput = null;
    },
    listNightsSuccess(state, output) {
        state.nightsOutput = output;
    },
    listNightsFailure(state) {
        state.nightsOutput = false;
    },
    /* Detail */
    detailRequest(state) {
        state.inspirationOutput = null;
    },
    detailSuccess(state, output) {
        state.inspirationOutput = output;
    },
    detailFailure(state) {
        state.inspirationOutput = false;
    },
     /* Days */
     listDaysRequest(state) {
        state.dayOutput = null;
    },
    listDaysSuccess(state, output) {
        state.dayOutput = output;
    },
    listDaysFailure(state) {
        state.dayOutput = false;
    },
     /* Conditions */
     listConditionsRequest(state) {
        state.conditionsOutput = null;
    },
    listConditionsSuccess(state, output) {
        state.conditionsOutput = output;
    },
    listConditionsFailure(state) {
        state.conditionsOutput = false;
    },
};


export const inspirations = {
    namespaced: true,
    state,
    actions,
    mutations
};
import { MESSAGES_SERVICE } from "@/services/messages.service.js";

const state = { processTask: undefined };

const actions = {
    send({ dispatch, commit }, message) {
        dispatch('alert/clear', null, { root: true });
        commit('sendRequest');
        
        MESSAGES_SERVICE.send(message)
            .then(
                output => {
                    commit('sendSuccess', output);
                },
                error => {
                    commit('sendFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    enquire({ dispatch, commit },  inputMessage ) {
        dispatch('alert/clear', null, { root: true });
        commit('enquireRequest');
        
        MESSAGES_SERVICE.enquire(inputMessage)
            .then(
                output => {
                    commit('enquireSuccess', output);
                    dispatch('alert/success', output, { root: true });

                },
                error => {
                    commit('enquireFailure', error);                
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
};

const mutations = {
    /* Send */
    sendRequest(state) {
        state.processTask = null;
    },
    sendSuccess(state) {
        state.processTask = true;
    },
    sendFailure(state) {
        state.processTask = false;
    },
    /* enquire */
    enquireRequest(state) {
        state.processTask = null;
    },
    enquireSuccess(state) {
        state.processTask = true;
    },
    enquireFailure(state) {
        state.processTask = false;
    }
};


export const messages = {
    namespaced: true,
    state,
    actions,
    mutations
};
<template>
<section>
   <div class="container-fluid">
        <div class="d-flex flex-column flex-lg-row">
          <!-- Menu -->
            <div class="col-12 col-lg-2 py-4" id="item2">
                <BlogMenu @filter-category-requested="filterCategory" @filter-date-requested="filterDate" @filter-author-requested="filterAuthor"/>
            </div>
            <!-- Content -->
            <div v-if="loading" class="col-12 col-lg-8">
              <div class="m-5" v-for="(element, index) in Array(itemsPerPage).fill(0)" :key="index">
                <SkeletonLoading class="w-100" style="height: 50vh"/>
              </div>
            </div>
            <div v-else class="d-flex flex-column col-12 col-lg-8 mt-lg-3">
              <div v-for="(post, index) in postsList" :key="post.id">
                <BlogPageThumb :post="post"/>
                <hr v-if="index != postsList.length - 1"/>
              </div>
              <div v-show="!loading && canLoadMore" class="see">
                <span @click="loadMore" type="button" class="icon-seeMore"></span>
                <p class="">{{$t("seeMore")}}</p>
              </div>
              <NoResultsPlaceholder v-if="!postsList.length"></NoResultsPlaceholder>
            </div>
            
            <!-- Blank column -->
            <div class="col-2" id="item4"><Chat/></div>
        </div>
    </div>
</section>
</template>

<script>
import NoResultsPlaceholder from "@/components/NoResultsPlaceholder.vue"
import BlogPageThumb from "@/components/BlogPageThumb.vue";
import Chat from "@/components/Chat.vue";
import BlogMenu from "@/components/BlogMenu.vue";
import { mapState, mapActions } from "vuex";
import InputGetList from "@/classes/InputGetList";
import SkeletonLoading from "@/components/SkeletonLoading.vue"
import { API_HELPER } from "@/helpers/api.js";

const DEFAULT_ITEMS_PER_PAGE = 10;
const GET_PAGE = 1;
const SORTER_VALUE = 2;
const PROPERTY_NAME = "id";

export default {
    metaInfo () {
      return { 
            title: `${this.$t("vueRoutes.blogTitle")} - Wild Douro`, 
            htmlAttrs: {
                lang: this.$i18n.locale,
            },
            meta: 
            [
                {name: 'description', content: this.$t("vueRoutes.blogDescription")},
                {name: 'keywords', content: this.$t("vueRoutes.defaultKeywords")},

                // // Twitter Card
                // {name: 'twitter:card', content: "summary"},
                // {name: 'twitter:title', content: this.$t("vueRoutes.blogTitle")},
                // {name: 'twitter:description', content: this.$t("vueRoutes.blogDescription")},
                // {name: 'twitter:image', content: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, "assets/hero/hero.jpg"]) },

                // //Facebook OpenGraph
                // {property: 'og:title', content: this.$t("vueRoutes.blogTitle")},
                // {property: 'og:site_name', content: 'Wild Douro'},
                // {property: 'og:type', content: 'website'},
                // {property: 'og:image', content: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, "assets/hero/hero.jpg"]) },
                // {property: 'og:description', content: this.$t("vueRoutes.blogDescription")}
            ], 
            link: [
                {rel: 'alternate', hreflang: 'en', href: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.$t("vueRoutes.blog", 'en')])},
                {rel: 'alternate', hreflang: 'pt', href: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.$t("vueRoutes.blog", 'pt')])},
            ]
            
        } 
    }, 
  components: {
    BlogMenu,
    Chat,
    BlogPageThumb,
    SkeletonLoading,
    NoResultsPlaceholder
  },
  data() {
    return {
      input: new InputGetList(DEFAULT_ITEMS_PER_PAGE, GET_PAGE, null, SORTER_VALUE, PROPERTY_NAME),
      itemsPerPage: DEFAULT_ITEMS_PER_PAGE,
      title: null,

    };
  },
  computed: {
    ...mapState({ 
      postsListOutput: state => state.posts.listOutput,
      categoriesListOutput: state => state.categories.listOutput,
      datesListOutput: state => state.posts.datesOutput,
      authorsListOutput: state => state.members.listOutput,
    }),
    loading: function(){
      return this.postsListOutput == null || this.categoriesListOutput == null || this.datesListOutput == null || this.authorsListOutput == null;
    },
    postsList: function() {
      return this.postsListOutput && this.postsListOutput.data.data ? this.postsListOutput.data.data : [];
    },
    numPosts: function(){
      return this.postsListOutput && this.postsListOutput.data ? this.postsListOutput.data.totalRecords : 0;
    },
    canLoadMore: function()
    {
      return this.numPosts > this.itemsPerPage;
    },
  },
  methods:{ 
    ...mapActions({
      getPosts: 'posts/getList',
    }),
    invokeGetPosts(params)
    {
      this.input.pageSize = this.itemsPerPage;
      this.input.visible=true;

      if(params && params.categoryId) this.input.categoryId = params.categoryId;
      if(params && params.date) this.input.date = params.date;
      if(params && params.authorId) this.input.authorId = params.authorId;
      this.getPosts(this.input);
    },
    filterCategory(category)
    {
      this.invokeGetPosts({categoryId: category.id ? category.id : category.value});
    },
    filterDate(date)
    {
      this.invokeGetPosts({date: date});
    },
    filterAuthor(author)
    {
      this.invokeGetPosts({authorId: author.id ? author.id : author.value});
    },
    loadMore()
    {
      if(this.canLoadMore)
      {
        this.itemsPerPage += DEFAULT_ITEMS_PER_PAGE; 
        this.invokeGetPosts();
      }
    }
  },
  mounted() {
    var params = {};
    if(this.$route.params.categoryId) params.categoryId = this.$route.params.categoryId;
    if(this.$route.params.date) params.date = this.$route.params.date;
    if(this.$route.params.authorId) params.authorId = this.$route.params.authorId;
    
    this.invokeGetPosts(params);
  },
   
}
</script>

<style scoped>
>>>a{
  color: #a3161c;
  text-decoration: none;
}
a:hover{
  color: #962422;
  opacity: 0.5;
}
p{
  font-size: 16px;
}
#description{
 text-align:center;
}
.wine{
  color:#962422 ;
  font-weight: bold;
  text-decoration: none;
}
.blog-img{
  object-fit: cover;
  width:100%;
  height: 100%;
}
.img-read{
    position: relative;
    border-radius: 100%;
    max-width: 16%;
    border:2px solid white ;
    z-index: 999;
}
#span-read{
background-color: rgb(204, 204, 204);
border: 2px solid white;
border-top-right-radius: 25px;
border-bottom-right-radius: 25px;
padding: 0px 23px;
margin-left: -15px;
margin-top: 10px;
height: 37px;
}
.text-RT{
  font-size: 11px;
}
.RT{
  font-size: 14px;
  margin-top: -4px;
}

h2{
  text-align: center;
  font-weight: bold;
}
hr{
  opacity: 1 !important;
  background-color:#962422 !important;
}
.hr{
border: 1.5px solid #962422 !important;
}

#titleright{
    text-align: right;
}
@media screen and (min-width: 992px) {
  #item2{
    border-width: 10px 10px 10px 0;
    border-style: solid;
    -webkit-border-image: 
    -webkit-gradient(linear, 0 0, 100% 0, from(#F2E0D8,), to(#F8EEEB)) 1 100%;
    -webkit-border-image: 
    -webkit-linear-gradient(left, #F2E0D8, #F8EEEB) 1 100%;
    -moz-border-image:
    -moz-linear-gradient(left, #F2E0D8, #F8EEEB) 1 100%; 
    -o-border-image:
    -o-linear-gradient(left, #F2E0D8, #F8EEEB) 1 100%;
    border-image:
    linear-gradient(to left, #F2E0D8, #F8EEEB) 1 100%;    
  }
  #item4{
    border-width: 10px 0 10px 10px;
    border-style: solid;
    -webkit-border-image: 
    -webkit-gradient(linear, 100% 0, 0 0, from(#F2E0D8), to(#F8EEEB)) 1 100%;
    -webkit-border-image: 
    -webkit-linear-gradient(right, #F2E0D8, #F8EEEB) 1 100%;
    -moz-border-image:
    -moz-linear-gradient(right, #F2E0D8, #F8EEEB) 1 100%;  
    -o-border-image:
    -o-linear-gradient(right, #F2E0D8, #F8EEEB) 1 100%;
    border-image:
    linear-gradient(to right, #F2E0D8, #F8EEEB) 1 100%;
  } 
}
@media screen and (max-width: 575px) {
  /* #item2 {
    display: none !important;
  } */
  #item4 {
    display: none !important;
  }
}
@media screen and (max-width: 400px) {
#span-read{
  padding-bottom: 10px;
}
}

hr{
  opacity: 1 !important;
  background-color:#962422 !important;
  border: 1.5px solid #962422 !important;
  margin: 0 6vw 1rem 6vw;
}

/* See more */
.icon-seeMore:before {
  cursor: pointer;
  font-size: 200px;
}

.see {
  position: relative;
  display: flex;
  justify-content: center;
}
.see > p {
  position: absolute;
  bottom: 25%;
  color: #962422;
}

</style>
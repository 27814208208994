<template>
    <BasePopUp v-if="showModal" @detailsClosed="closeConfirmRequested">
        <div class="modal-body-container px-5">
        <div class="close-button cursor-pointer d-flex w-100 justify-content-end">
            <p @click="closeConfirmRequested"><img src="@/assets/close.svg" class="w-100"/></p>
        </div>
            <!-- Title -->
            <h4 class="text-center mt-2 mb-2">{{title}}</h4>                
            <!-- Content -->
            <!-- 0: Who do you like to travel with? -->
            <ImageOptionsSelector v-if="currentContent == 0" :options="travelWithOptions" v-model="answers[0]" @option-clicked="onNextClicked"></ImageOptionsSelector>
            <!-- 1: Places you want to get to know-->
            <MapOptionsSelector v-if="currentContent == 1" v-model="answers[1]"></MapOptionsSelector>
            <!-- 2: Season to travel-->
            <ImageOptionsSelector v-if="currentContent == 2" :options="seasonOptions" v-model="answers[2]" @option-clicked="onNextClicked"></ImageOptionsSelector>
            <!-- 3: Phrase you identify with-->
            <TextOptionsSelector v-if="currentContent == 3" :options="phrases" v-model="answers[3]" @option-clicked="onNextClicked"></TextOptionsSelector>
            <!-- 4: Gender-->
            <ImageOptionsSelector v-if="currentContent == 4" :options="genderOptions" v-model="answers[4]" @option-clicked="onNextClicked"></ImageOptionsSelector>
            <!-- 5: Result-->
            <div class="row flex-grow-1 my-4" v-if="currentContent == 5">
                <div :style="{'background-image': 'url(' + require('@/assets/' + profile.image) + ')'}" class="result-image d-none d-lg-block col-lg-6 col-xl-5"/>
                <div class="d-flex flex-column justify-content-between col-12 col-lg-6 col-xl-7">
                    <span class="description-align" v-html="profile.description"></span>
                    <div class="result-quiz d-flex flex-column align-content-center justify-content-center result-actions-container">
                        <button type="button" @click="closeConfirmRequested">{{$t("quiz.finish")}}</button>
                        <button type="button" @click="goToExperiences">{{$t("quiz.seeExperiences")}}</button>
                        <button type="button" @click="currentContent = 0">{{$t("quiz.doAgain")}}</button>
                    </div>
                </div>
            </div>
            <div v-if="currentContent == 5" class="d-flex my-3 navigation-container">
                <button class="text-left goBack" v-if="currentContent != 0" type="button" @click="currentContent = currentContent - 1">&#60; {{$t("quiz.goBack")}}</button>
            </div>
            <!-- Options -->
            <div v-if="currentContent < 5" class="d-flex justify-content-center my-3 navigation-container">
                <button class="col-4 text-left goBack" v-if="currentContent != 0" type="button" @click="currentContent = currentContent - 1">&#60; {{$t("quiz.goBack")}}</button>
                <span class="col-4" v-if="currentContent == 0"></span>
                <span v-if="currentContent == 1" class="col-4 text-center multipleChoise">{{$t("quiz.multipleChoice")}}</span>
                <span v-else class="col-4 text-center singleChoise">{{$t("quiz.singleChoice")}}</span>
                <button class="col-4 text-right singleChoise" type="button" @click="onNextClicked" v-bind:class="{disabled: !answers[currentContent] || !answers[currentContent].length  }">{{$t("quiz.goForward")}} &#62;</button>
                <!-- <div>{{JSON.stringify(answers[currentContent])}}</div> -->
            </div>
        </div>
    </BasePopUp>
</template>

<script>
import BasePopUp from './BasePopUp.vue';
import ImageOptionsSelector from '@/components/ImageOptionsSelector.vue';
import MapOptionsSelector from '@/components/MapOptionsSelector.vue';
import TextOptionsSelector from '@/components/TextOptionsSelector.vue';
import { mapState, mapActions } from 'vuex'

const NUMBER_OF_QUESTIONS = 4;

export default {
    extends: BasePopUp,
    components: {BasePopUp, ImageOptionsSelector, MapOptionsSelector, TextOptionsSelector},
    data: function () {
        return {
            currentContent: 0,
            answers: Array(NUMBER_OF_QUESTIONS + 1),
            //
            titles: [this.$t("quiz.title1"), this.$t("quiz.title2"), this.$t("quiz.title3"), this.$t("quiz.title4"), this.$t("quiz.title5") ],
            travelWithOptions: [{ key: 'justMe', label: this.$t("quiz.justMe"), image: 'Quiz/with-1.gif', imageStatic:'Quiz/justMe.png' },{ key: 'withPartner', label: this.$t("quiz.withPartner"), image: 'Quiz/with-2.gif' , imageStatic:'Quiz/MeAndMy.png'},{ key: 'withFriends', label: this.$t("quiz.withFriends"), image: 'Quiz/with-3.gif' , imageStatic:'Quiz/MeAndFriends.png'},{ key: 'withFamily', label: this.$t("quiz.withFamily"), image: 'Quiz/with-4.gif' , imageStatic:'Quiz/MeAndFam.png'}],
            phrases: [{key: 'adventurous', label: this.$t('quiz.active')},{key: 'evader', label: this.$t('quiz.findMyself')},{key: 'goodFork', label: this.$t('quiz.food')},{key: 'romantic', label: this.$t('quiz.surprisePartner')},{key: 'cultural', label: this.$t('quiz.cultural')},{key: 'solar', label: this.$t('quiz.solar')},{key: 'curious', label: this.$t('quiz.manual')},{key: 'weekend', label: this.$t('quiz.littleTime')},{key: 'ecotourist', label: this.$t('quiz.reducedImpact')},{key: 'family', label: this.$t('quiz.qualityTime')}],
            genderOptions: [{ key: 'female', label: this.$t("quiz.female"), image: 'Quiz/gender-2.gif', imageStatic:'Quiz/person1.png' },{ key: 'male', label: this.$t("quiz.male"),image: 'Quiz/gender-1.gif', imageStatic:'Quiz/person2.png' }],
        };
    },
    props:{
        confirmationMessage:
        {
            type: String,
            default: ''
        },
    },
    computed:
    {
        ...mapState('account', ['user']),
        userInfo: function()
        {
            return this.user ? this.user.data : null;
        },
        profile: function()
        {
            // Check this.answers, considering the gender and phrase, to show the results
            const tourismType = this.answers[3];
            const gender = this.answers[4];

            var titleResourceKey = 'quiz.' + tourismType + "Title";
            titleResourceKey = gender == 'male' ? titleResourceKey + "Male" : titleResourceKey + "Female";
            const title = this.$t(titleResourceKey);
            const description = this.$t("quiz." + tourismType + "Description");

            return { image: `Quiz/${tourismType}${gender == 'male' ? "Male" : "Female"}.svg`, title: title, description: description}
        },
        title: function()
        {
            if(this.currentContent == 5)
            {
                return this.profile.title;
            }
            else
            {
                return this.titles[this.currentContent];
            }
        },
        seasonOptions: function()
        {
            var withNum = this.answers[0] ? this.travelWithOptions.map(x=> x.key).indexOf(this.answers[0]) + 1 : 1;
            return [{ key: 'Spring', label: this.$t("quiz.spring"), image: `Quiz/season-1-with-${withNum}.gif`, imageStatic:`Quiz/season-1-with-${withNum}.png` },{ key: 'Summer', label: this.$t("quiz.summer"), image: `Quiz/season-2-with-${withNum}.gif`, imageStatic:`Quiz/season-2-with-${withNum}.png` },{ key: 'Autumn', label: this.$t("quiz.autumn"), image: `Quiz/season-3-with-${withNum}.gif`, imageStatic:`Quiz/season-3-with-${withNum}.png` },{ key: 'Winter', label: this.$t("quiz.winter"), image: `Quiz/season-4-with-${withNum}.gif`, imageStatic:`Quiz/season-4-with-${withNum}.png` }];
        } 
    },
    methods:{
        ...mapActions('account', ['submitQuiz']),
        closeConfirmRequested()
        {       
            this.$router.push('/');
            this.$emit("closeConfirmRequested");
        },
        onNextClicked()
        {
            if(this.answers[this.currentContent]) 
            {
                this.currentContent = this.currentContent + 1;
            }
        },
        goToExperiences() {
            if (this.currentContent === 5 && this.answers[3]) {
                // Extract selected tourism type and gender from quiz answers
                const tourismType = this.answers[3];

                console.log(tourismType, "tourismType")

                // Navigate to experiences page with filter based on quiz results
                this.$router.push({ 
                name: 'Experiences', 
                query: { 
                    tourismType: tourismType
                } 
                });
            } else {
                // If quiz is not completed or missing necessary answers, navigate to experiences without filters
                this.$router.push({ name: 'Experiences' });
            }
        },

    },
    mounted: function()
    {
        if(this.userInfo && this.userInfo.questions)
        {
            this.answers = this.userInfo.questions.map(x=> x.answer.includes(", ") ? x.answer.split(", ") : x.answer);
            if(this.answers.length >= 2)
            {
                if(!Array.isArray(this.answers[1]))
                {
                    this.answers[1] = [this.answers[1]];
                }
            }
            this.currentContent = this.answers.length;
        }
    },
    watch: 
    {
        currentContent: function(val)
        {
            // When reaching the last slide, submit the answers
            if(val == NUMBER_OF_QUESTIONS + 1) 
            {
                const quiz = this.answers.map((answer, index) => ({ answer: Array.isArray(answer) ? answer.join(", ") : answer, questionNumber: index + 1 }));
                this.submitQuiz(quiz); // This automatically saves the questions locally
            }
        },
    }
}
</script>

<style scoped>
h4,
span
{
    color: white;
}

.modal-body-container
{
    background-color: #962422;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;
    width: 90vw !important;
    min-height: 80vh !important;
}

.result-image
{
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-color: white;
    border: 2px solid white;
    border-radius: 25px;
}
.result-quiz{
    padding-left: 20rem;
    padding-right: 20rem;
}
.navigation-container > button
{
    background: transparent;
    border: none;
    color: white;
}

.result-actions-container
{
    gap: 16px;
}

.result-actions-container > button
{
    background: transparent;
    border: 1px solid white;
    color: white;
}
.close-button p
{
    width: 25px;
    margin-top: 1rem;
}
@media (max-width: 1700px) {
   .result-quiz{
    padding-left: 12rem;
    padding-right: 12rem;
    }
}
@media (max-width: 1400px) {
   .result-quiz{
    padding-left: 7rem;
    padding-right: 7rem;
    }
}
@media (max-width: 1050px) {
   .result-quiz{
    padding-left: 5rem;
    padding-right: 5rem;
    }
}
@media (min-width: 992px) {
   .description-align{
    padding-left: 2rem;
    }
}
@media (max-width: 715px) {
   .result-quiz{
    padding-left: 3rem;
    padding-right: 3rem;
    }
}
@media (max-width: 494px) {
    .result-quiz{
    padding-top: 2rem;
    }
}
@media (max-width: 489px) 
    {
    .singleChoise{
        width: 40%;
    }
    .multipleChoise{
        width: 50%;
    }
    .goBack{
        width: 40%;
    }
    .navigation-container span, button{
        font-size: 12px;
    }
}
@media (max-width: 400px) {
   .result-quiz{
    padding-left: 0;
    padding-right: 0;
    }
}

</style>
<template>
  <section v-if="products.length" class="hero d-flex flex-column">
    <!-- Image with sharing buttons -->
    <div class="position-relative">
    <!-- <div class="position-relative youtube-container"> -->
      <div v-if="!videoUrl">
        <img class="img-hero" :src="heroImg" alt=""/>
        <Sharing/>
      </div>
      <div v-else class="video-wrapper">
        <!-- <iframe id="video" class="position-absolute d-flex" :style="{top: 0}" width="100%" height="100%" :src="videoUrl" frameborder="0" allowfullscreen></iframe> -->
        <video autoplay muted frameborder="0" allowfullscreen class="position-absolute d-flex" :style="{top: '-15px'}" width="100%" height="100%" controls>
          <source :src="videoUrl" type="video/mp4">
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
    <div class="row w-100 mobile-section">
      <!-- Profile quiz --> 
      <div id="profile-quiz" class="profile-quiz d-flex flex-wrap col-12 col-xl-2 px-xl-5">
        <div class="col-6 col-lg-12 col-xl-6 d-flex justify-content-center" id="quiz-img">
          <a href="#">
            <img @click="onProfileClicked" :src="require('@/assets/' + quizProfileImg)" alt="" />
          </a>
        </div>  
        <div class="col-6 col-lg-12 col-xl-6 d-flex justify-content-center align-items-center" id="quiz-title">
          <a href="#">
            <h5 @click="onProfileClicked" class="text">{{ quizProfileTitle }}</h5>
          </a>
        </div>
      </div>
      <!-- Slider -->
      <div class="col-12 col-xl-9 mt-1 d-flex slider-mobile" id="slide" :style="slideOffset">
        <div class="arrow-left cursor-pointer">
          <img @click="previousSlide()" src="../assets/hero/arrow-hero-left.svg" alt="" />
        </div>
        <vueper-slides ref="highlights" :arrows="false" @slide="slideChanged" class="no-shadow w-100" :bullets="false" :bullets-outside="false" :visible-slides="products.length < 4 ? products.length : 4" :gap="1" :touchable="false" :dragging-distance="70" :slide-ratio="1" :breakpoints=" {
            1399: { visibleSlides: products.length < 3 ? products.length : 3, fixedHeight:'400px'},
            1199: { visibleSlides: products.length < 2 ? products.length : 2, fixedHeight:'400px' },
            767: { visibleSlides: products.length < 1 ? products.length : 1, fixedHeight:'400px', touchable:true },
          }">
          <vueper-slide v-for="(product, index) in products" :key="index">
            <template v-slot:content :style="{ transform: `translate3d(-${transformDynamic}%, 0px, 0px)`}">
              <div @click="productClicked(product)" class="vueperslide__content-wrapper p-3 align-items-start cursor-pointer" :style="{'background-image': 'url(' + getImageUrl(product) + ')'}" :class="{'justify-content-start': index == slide, 'justify-content-end': index != slide}">
                <!-- Tag -->
                <p v-show="index == slide" class="small-text mb-0 text-left">{{ product.tag }}</p>
                <!-- Title -->
                <p class="big-text font-weight-bold mb-0 text-left">{{product.title}}</p>
                <!-- Description -->
                <p v-show="index == slide" class="fadeout small-text mb-0 text-left flex-grow-1">{{getExcerpt(product.description)}}</p>               
                <!-- See more -->                     
                <a v-show="index == slide" class="text-left cursor-pointer" @click="productClicked(product)">{{ $t("voucher.seeMore") }}</a>
              </div>
            </template>
          </vueper-slide>
        </vueper-slides>  
        <div class="arrow-right cursor-pointer">
          <img @click="nextSlide()" src="../assets/hero/arrow-hero-right.svg" alt="" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";
import Sharing from "@/components/Sharing.vue";
import { mapState, mapActions } from "vuex";
import InputGetList from "@/classes/InputGetList";
import { API_HELPER } from "@/helpers/api.js";

const EXPERIENCE = "Experience";
const VOUCHER = "Voucher";

export default {
  name: "Hero",
  components:{
    VueperSlides, 
    VueperSlide,
    Sharing,
  },
  data: function () {
    return {
      slide: 0,
      products: [] // This is a data in order to personalize order
    };
  },
  computed:
  {
    ...mapState('products', ['listOutput']),
    ...mapState('account', ['user']),
    videoUrl: function() {
      return this.products && this.products[this.slide] ? this.products[this.slide].videoUrl : null;
    },
    heroImg: function()
    {
      var result = null;

      if(this.products[this.slide] && this.products[this.slide].files && this.products[this.slide].files.length)
      {
        var relativePath = this.products[this.slide].featuredImage ? this.products[this.slide].featuredImage : this.products[this.slide].files[0];
        result = API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, relativePath]); 
      }

      return result;
    },
    userInfo: function()
    {
      return this.user ? this.user.data : null;
    },
    quizProfileTitle: function()
    {
      var result = this.$t("heroTravellerProfile");

      if(this.userInfo && this.userInfo.questions && this.userInfo.questions.length >= 4)
      {
        const tourismType = this.userInfo.questions[3].answer;
        result = this.$t(`quiz.${tourismType}Intro`);
      }

      return result;
    },
    quizProfileImg: function()
    {
      var result = "hero/toy.png";

      if(this.userInfo && this.userInfo.questions && this.userInfo.questions.length >= 4)
      {
        const tourismType = this.userInfo.questions[3].answer;
        const gender = this.userInfo.questions[4].answer == "male" ? "Male" : "Female";

        result = `hero/${tourismType}${gender}.svg`;
      }

      return result;
    },
    slideOffset () {
      return {
        '--offset': this.slide + 1
      }
    },
  },
  methods:
  { 
    ...mapActions("products", ["getList"]),
    slideChanged: function(val)
    {
      this.slide = val.currentSlide.index;
    },
    getImageUrl(product)
    {
      var result = null;

      if(product.files && product.files.length)
      {
        var relativePath = product.files[0];
        result = API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, relativePath]); 
      }

      return result;
    },
    productClicked(product)
    {
      if(product.type == EXPERIENCE)
      {
        this.$router.push({name: "ExperienceDetail", query: {productId: product.id} }).catch(()=>{});
      }
      else if(product.type == VOUCHER)
      {
        this.$router.push({name: "VoucherDetail", query: {productId: product.id} }).catch(()=>{});
      }
    },
    getExcerpt(description)
    {
      var div = document.createElement('div');
      div.innerHTML = description.trim();

      var result = div.textContent || div.innerText || "";
      return result;
    },
    onProfileClicked()
    {
      this.$emit('profile-clicked');
    },
    nextSlide()
    {
      this.products.push(this.products.shift()); // First to last
    },
    previousSlide()
    {
      this.products.unshift(this.products.pop()); // Last to first
    }
  },
  mounted() {    
    const itemsPerPage = 10;
    const currentPage = 1;
    const searchText = '';
    const sorter = 2; // desc
    const propertyName = "id";

    var input = new InputGetList(itemsPerPage, currentPage, searchText, sorter, propertyName);

    // List specific parameters
    input.visible = true;
    input.highlighted = true;

    this.getList(input);
  },
  watch:
  {
    listOutput: function()
    {
      this.products = this.listOutput && this.listOutput.data ? this.listOutput.data : [];
    },
  }
};
</script>

<style scoped>
/* Structure & slides */
.img-hero{
  width: 100%;
  height: 69vh;
  object-fit: cover;
}
h5
{
  text-align: center;
}
.vueperslide
{
  background: linear-gradient(to top, rgba(0, 0, 0, 1), #a3161c) !important;
}
>>>.vueperslides__parallax-wrapper{
  height: 100%;
  z-index: -11;
}
.vueperslide > .vueperslide__content-wrapper
{
  background-repeat: no-repeat;
  background-size: cover;
}

.vueperslide:hover > div,
.vueperslide--active > div
{
  background-image: none !important;
}
.arrow-left, .arrow-right{
  width: 8vw;
  min-width: 50px;
  align-self: center;
  line-height: 13.0;
}

a,
.big-text,
.medium-text,
.small-text
{
  color: white;
}

.fadeout
{
  -webkit-mask-image: -webkit-gradient(linear, left center, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));
  overflow: hidden;
}

a:hover
{
  color: lightgray;
}

.big-text{
  font-size: 24px;
  word-break: break-word;
}

.medium-text
{
  font-size: 18px;
}
.small-text
{
  font-size: 16px;
}
/* Share */
/deep/#share .share-block
{
  background-color: rgba(128, 128, 128, 0.7); 
}

#share
{
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 0;
}
/* Profile quiz */
.profile-quiz{
  z-index: 2;
  margin-top: 4rem
}
.profile-quiz img
{
  margin-top: -3.5rem;
  height: 115%;
  min-height: 250px;
}
.profile-quiz a{
  text-decoration: none;
  color: black;
}
.profile-quiz a:hover{
  
  color: rgba(0, 0, 0, 0.8)
}

@media screen and (min-width: 1200px) {
  .col-xl-9 {
    width: 75%;
  }
  h5
  {
    text-align: left;
    margin-left: 8rem;
  }
  .img-hero{
    height: 69vh;
  }
  .vueperslides
  {
    margin: -69px 0;
  }

  .vueperslides,
  /deep/.vueperslides__inner,
  /deep/.vueperslides__track
  {
    height: calc(35vh - 55px); /* Fill the remaining height (considering img-hero and top menu) */
    min-height: 260px;
    max-height: 460px;
  }

  >>>.vueperslide
  {
    margin-top: 70px;
    width: 24.5% !important;
    min-height: 190px!important;
    margin-right: 0.8%!important;
    height: auto;
  }

  >>>.vueperslide--active{
    margin-top: 0;
    width: 25.5%!important;
    min-height: 260px!important;
  }
}
@media screen and (max-width: 1750px) {
  >>>.vueperslide--active{
    margin-top: 70px!important;
    min-height: 190px!important
  }
}
@media screen and (min-width: 1400px) {
  .col-xl-9 {
    width: 67.8%;
  }
}
@media screen and (max-width: 1500px) {
  .arrow-left, .arrow-right{
    align-self: unset!important;
  }
}
@media screen and (max-width: 1199px) {
    .profile-quiz
    {
      width: 100%;
    }
    .arrow-right,
    .arrow-left{
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    >>>.vueperslide--active{
      margin-top: initial!important;
      height: initial!important
    }
}
/* Share */
@media screen and (min-width: 1200px) {
  #share
  {
    right: 7vw;
  }
}
@media screen and (max-width: 576px) {
  .mobile-section{
    padding: 0!important;
    margin: 0!important;
   }
  .slider-mobile{
    margin-top: 3rem!important;
  }
}
/* Elements to occupy 100% of the width when they have this minimum height */
@media screen and (min-height: 1240px) {
  #profile-quiz{
    width: 100%;
    height: 30vh;
  }
  #quiz-img,
  #quiz-title,
  #slide{
    width: 100%;
  }
  .vueperslides
  {
    margin: 0;
    height: calc(35vh - 55px)!important;
  }
  .arrow-left,
  .arrow-right{
    align-self: center!important;
  }
  .vueperslide--active{
    margin-top: 0!important;
  }
}

/* Video logic */
.video-wrapper {position: relative; padding-bottom: 56.25%; /* 16:9 */  padding-top: 25px;}
.video-wrapper iframe {position: absolute; top: 0; left: 0; width: 100%; height: 100%;}
</style>

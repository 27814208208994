<template>
  <section>
    <div class="container">
      <!-- Skeleton addingCart -->
      <div v-if="loading" class="row d-flex justify-content-center flex-wrap mt-5 py-3">
        <SkeletonLoading class="col-12 col-md-6 skeleton-loading-size" style="height:54.4vh;width:31.3vw"/>
        <div class="d-flex flex-column col-12 col-md-6">
          <SkeletonLoading class="col-12 col-md-6 mb-1 w-100" style="height: 20vh"/>
          <SkeletonLoading class="col-12 col-md-6 mt-1 w-100" style="height: 33.7vh"/>
        </div>
      </div>
      <!-- Main content -->
      <div v-else class="main-content row d-flex flex-wrap mt-5 py-3">
          <!-- Gallery -->
          <ImageGallery :images="images" class="col-12 col-md-6 align-items-xl-end"></ImageGallery>
          <!-- Voucher info -->
          <div class="col-12 col-md-6">
            <!-- Title -->
            <div class="row">
              <h1>{{voucher.title}}</h1>
            </div>
            <!-- Subtitle -->
            <div v-if="alreadyOnCart" class="row">
              <h2>{{$t("alreadyOnCart")}} <span class="cursor-pointer" @click="goToCart">{{$t("finishYourPurchase")}}</span></h2>
            </div>
            <div class="row mt-3">
              <!-- Price -->
              <div class="col-12 col-xl-6 d-flex align-items-center">
                <p class="money">{{finalPrice.toFixed(2)}} €</p>
              </div>
              <!-- People, video, message and favorites -->
              <div class="col-12 col-xl-6 flex-wrap d-flex">
                  <div class="d-flex align-items-center" :style="{width: '70px'}">
                    <p class="people">{{voucher.people}}</p>
                    <span class="people-svg">
                      <img :src="require('@/assets/Experiences/people.svg')"/>
                    </span>                
                  </div>
                  <span class="msg-svg cursor-pointer" @click="showVideo = true">
                    <img :src="require('@/assets/vouchers/video.svg')"/>
                  </span>    
                  <span class="msg-svg cursor-pointer" @click="showEnquireForm = true">
                    <img :src="require('@/assets/Experiences/msg.svg')"/>
                  </span>
                  <span class="d-flex justify-content-center icon-fav2 cursor-pointer" @click="favoriteButtonClicked" :class="{'disabled': loading}">
                    <span class="path1"></span>
                    <span class="path2"></span>   
                    <span class="path3" v-if="isFavorite"></span>
                  </span>
              </div>
            </div>
            <!-- Description -->
            <div class="row">
              <div class="col-12" v-html="voucher.description"></div>
              <h3 v-if="voucher.include != null">{{ $t('voucher.includes')}}</h3>
              <div v-if="voucher.include != null" class="col-12" v-html="voucher.includes[$i18n.locale]"></div>
              <h3 v-if="voucher.location">{{ $t('voucher.location')}}</h3>
              <div v-if="voucher.location" class="col-12" v-html="voucher.location"></div>
              <h3 v-if="voucher.note != null">{{ $t('voucher.notes')}}</h3>
              <div v-if="voucher.note != null" class="col-12" v-html="voucher.notes[$i18n.locale]"></div>
            </div>
            <!-- Add to cart -->
            <div class="row">
              <div class="col-12">
                <button class="button1" v-bind:class="{disabled: addingCart}" @click="addCart">
                    <span v-show="!addingCart" class="text-uppercase">{{$t("addToCart")}}</span>
                    <span v-show="addingCart" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    <span v-show="addingCart" class="sr-only">{{$t("loading")}}</span>
                </button>    
              </div>
            </div>
          </div>
      </div>
    </div>
    <hr class="hr"> 
    <!-- Other vouchers -->
    <VoucherSlider v-if="!loading" :title="$t('voucher.alsoLike')" class="mb-5"></VoucherSlider>
    <!-- Voucher video -->
    <FullscreenVideo v-if="showVideo" @close-request="showVideo = false" :src="embedLink(voucher.video)"></FullscreenVideo>
    <EnquirePopup v-if="showEnquireForm" @close-request="showEnquireForm = false" :experienceTitle="voucher.title" @success-alert-requested="onEnquireSuccess"/>
  </section> 
</template>

<script>
import VoucherSlider from "@/components/VoucherSlider.vue";
import ImageGallery from "@/components/ImageGallery.vue";
import FullscreenVideo from "@/components/FullscreenVideo.vue";
import EnquirePopup from "@/components/popups/EnquirePopup.vue";
import SkeletonLoading from "@/components/SkeletonLoading.vue"
import { mapState, mapActions } from "vuex";
import router from '@/router';
import { API_HELPER } from "@/helpers/api.js";
import { UTILS_HELPER } from "@/helpers/utils.js";
import InputGetList from "@/classes/InputGetList";

export default {
 metaInfo () {
      return { 
            title: this.voucher ? `${this.voucher.title} - Wild Douro` : "Wild Douro", 
            htmlAttrs: {
                lang: this.$i18n.locale,
            },
            meta: 
            [
                {name: 'description', content: this.voucher && this.voucher.description ? UTILS_HELPER.htmlToText(this.voucher.description) : this.$t("vueRoutes.intro")},
                {name: 'keywords', content: this.voucher && this.voucher.tourismType ? this.voucher.tourismType : this.$t("vueRoutes.defaultKeywords")},

                // // Twitter Card
                // {name: 'twitter:card', content: "summary"},
                // {name: 'twitter:title', content: this.voucher ? `${this.voucher.title} - Wild Douro` : "Wild Douro"},
                // {name: 'twitter:description', content: this.voucher && this.voucher.description ? UTILS_HELPER.htmlToText(this.voucher.description) : this.$t("vueRoutes.intro")},
                // {name: 'twitter:image', content: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, "assets/hero/hero.jpg"]) },

                // //Facebook OpenGraph
                // {property: 'og:title', content: this.voucher ? `${this.voucher.title} - Wild Douro` : "Wild Douro"},
                // {property: 'og:site_name', content: 'Alcobaça'},
                // {property: 'og:type', content: 'website'},
                // {property: 'og:image', content: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, "assets/hero/hero.jpg"]) },
                // {property: 'og:description', content: this.voucher && this.voucher.description ? UTILS_HELPER.htmlToText(this.voucher.description) : this.$t("vueRoutes.intro")}
            ], 
            link: [
                {rel: 'alternate', hreflang: 'en', href: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.$t("vueRoutes.voucherDetail", 'en')])},
                {rel: 'alternate', hreflang: 'pt', href: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.$t("vueRoutes.voucherDetail", 'pt')])},
            ]
            
        } 
    },
components: {
    VoucherSlider,
    ImageGallery,
    FullscreenVideo,
    EnquirePopup,
    SkeletonLoading
  },
  name: "ToggleDiv",
    props: {
     msg: String,
   },
  data: function () {
    return {
      addingCart: false,
      showVideo: false,
      showEnquireForm: false,
      voucher : null,
      quantity : "1",
    };
  },
  computed:{
    ...mapState('account', ['user']),
    ...mapState({ 
      vouchersOutput: state => state.vouchers.listOutput,
    }),
    ...mapState('vouchers', ['voucherOutput']),
    ...mapState('cart', ['products']),
    ...mapState('cart', ['processTask']),
    ...mapState('favorites', ['listOutput']),
    ...mapState('favorites', ['addTask']),
    ...mapState('favorites', ['removeTask']),
    isFavorite: function()
    {
      // Favorites may have products (logged in) or vouchers/experiences (not logged in)
      return this.listOutput && this.listOutput.data ? this.listOutput.data.find(x=> this.user ? x.id == this.voucher.productId : x.id == this.voucher.id) : false;
    },
    loading: function()
    {
      return !this.voucher && (this.voucherOutput == null || this.addTask == null || this.removeTask == null);
    },
    voucherList: function() {
      return this.vouchersOutput && this.vouchersOutput.data ? this.vouchersOutput.data : null;
    },
    cart: function()
    {
      return this.products ? this.products.data : [];
    },
    alreadyOnCart: function()
    {
      return this.voucher && this.cart ? this.cart.filter(product => product.id === this.voucher.productId).length > 0 : false;
    },
    images: function()
    {
      var result = [];

      if(this.voucher && this.voucher.images)
      {
        result = this.voucher.images.map(x=> API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, x.url]));
      }

      return result;
    },
    company: function()
    {
      return this.user && this.user.data && this.user.data.company ? this.user.data.company : 0;
    },
    discount: function()
    {
      return this.company && this.company.discount ? this.company.discount : 0;
    },
    discountPrice: function()
    {
      return this.voucher && this.voucher.finalPrice && this.discount ? this.voucher.finalPrice * this.discount / 100 : 0;
    },
    finalPrice: function()
    {
      return this.voucher && this.voucher.finalPrice && this.company.seeDiscount ? this.voucher.finalPrice - this.discountPrice : this.voucher.finalPrice;
    },
  },
  mounted() {
    this.initializePage();
  },
  methods: {
    initializePage()
    {
      if(!this.$route.params.voucher  && !this.$route.query.productId)
      {
        router.push({ name: 'Vouchers' });
      }

      if(this.$route.params.voucher)
      {
        this.voucher = this.$route.params.voucher;
      }
      else
      {
        this.getDetail(this.$route.query.productId);
      }

      if(!this.listOutput)
      {
        this.updateItemsRequested();
      }
    },
    ...mapActions('cart', ['updateCart']),
    ...mapActions('vouchers', ['getDetail']),
    ...mapActions('favorites', ['getList']),
    ...mapActions('favorites', ['addFavorite']),
    ...mapActions('favorites', ['removeFavorite']),
    favoriteButtonClicked()
    {
      this.clicked = true;
      
      if(this.isFavorite)
      {
        const self = this;
        this.$emit("warning-alert-requested", { message: this.$t("favorites.removedWarning"), primaryCallback: () =>
        {
          self.removeFavorite(this.voucher);
        }, primaryButton: "Yes", secondaryButton: "No"});
      }
      else
      {
        this.addFavorite(this.voucher);
      }
    },
    addCart() {
      this.addingCart = true;
      const product = this.voucher;
      this.updateCart({product});
    },
    goToCart()
    {
      router.push({name : 'Cart'});
    },
    onEnquireSuccess: function()
    {
      this.$emit("success-alert-requested", {message: this.$t("enquireSuccess")});
      this.showEnquireForm = false;
    },
    // SAMEORIGIN problem - If you are getting this error for a YouTube video, rather than using the full url use the embed url.
    embedLink: function (link) {
      let genericUrl = "https://www.youtube.com/watch?v=";
      let replaceUrl = "http://www.youtube.com/embed/";

      if(genericUrl){
        return link.replace(genericUrl, replaceUrl); 
      }else{
        return link;
      }
    },
    updateItemsRequested()
    {
      const currentPage = 1;
      const searchText = '';
      const sorter = 1; // asc
      const propertyName = "id";
        
      //Make request
      var input = new InputGetList(this.itemsPerPage, currentPage, searchText, sorter, propertyName);    
      input.visible = true;
      // Get favs
      this.getList(input);    
    }

  },
  watch:
  {
    $route(to) {
      if(to.name == "VoucherDetail")
      {
        this.initializePage();
      }
    },
    voucherOutput: function(val)
    {
      if(val && val.data)
      {
        this.voucher = val.data;
      }
    },
    products: function()
    {
      this.addingCart = false;
    },
    addTask: function(val)
    {
      if(val && this.clicked)
      {
        this.$emit("success-alert-requested", {message: this.$t("favorites.added")});
        this.updateItemsRequested();

      }

      if(val != null) this.clicked = false;
    },
    removeTask: function(val)
    {
      if(val && this.clicked)
      {
        this.updateItemsRequested();
      }

      if(val != null) this.clicked = false;
    },
    processTask:
    {
      handler: function(val)
      {
        if(val != null)
        {
          this.$emit("success-alert-requested", { message: this.$t("addedToCart"), primaryButton: this.$t("goToCart"), primaryCallback: this.goToCart, secondaryButton: this.$t("close")});
        }
      },
      deep: true // Watch for changes on the properties
    }
  }
}
</script>

<style scoped>
.main-content
{
  flex-wrap: nowrap;
}

h1 {
  color: #962422;
  font-weight: bold;
  font-size: 25px;
}

h2
{
  font-style: italic;
  font-size: 14px;
}

h2 span
{
  text-decoration: underline;
}

h3
{
  font-weight: bold;
  font-size: 14px;
  margin: 1rem 0 0 0;
}

.money{
  font-size: 25px;
}

hr{
  opacity: 1 !important;
  background-color: snow;
  width: 85%;
  margin: 3rem auto 0 auto;
}
.hr{
  border: 1px dashed #962422 !important;
}
p{
  margin-bottom: 0rem !important;
}
.button1 {
  background-color: #962422;
  border: none;
  color: white !important;
  padding: 5px 100px;
  text-align: center;
  text-decoration: none !important;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  margin-top: 3rem;
}
.people{
  font-size: 25px;
  margin-right: -15px;
  margin-top: 8px;
}
.icon-fav2 {
  font-size: 60px;
  height: 70px;
  width: 70px;
}
>>>.vueperslides{
  padding-top: 0!important;
  padding-bottom: 0!important;
}
>>>.vueperslide__content-wrapper:hover .overlay {
  visibility: unset;
  background-color: unset;
  opacity: unset;
}
 >>>.vueperslide .overlay {
  visibility: unset;
  transition: unset;
  opacity: unset;
  background-color: unset;
  min-height: unset;
}
>>>.title-content,
>>>.subtitle-content,
>>>.content,
>>>.bottom img,
>>>.find-more{
  display: none;
}
@media (max-width: 767px) {
  .skeleton-loading-size{
    width: 516px!important;
    height: 516px!important;
    margin: 20px auto!important;
  }
}
@media (max-width: 576px ) {
  .button1 {
    width: 100%;
    margin: 3rem auto;
  }
  .skeleton-loading-size{
    margin: 10px auto !important;
    height: 350px !important;
    width: 93% !important;
  }
  .py-3{
    padding-top: 0!important;
    padding-bottom: 0!important;
  }
  .mt-5{
    margin-top: 1rem!important;
  }
}
</style>
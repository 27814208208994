<template>
  <section id="footer">
    <div class="footer-dark">
      <footer>
        <div class="container">
          <div class="row">
            <div class="col-6 col-lg-3 item">
              <div class="logo text-center ">
                <img
                  src="../assets/Footer/logoFooter.png"
                  alt="Logo"
                  title="Logo"
                />
              </div>
              <div class="row icons text-center">
                <IconsSocial></IconsSocial>
              </div>
              <div class="d-flex justify-content-center mb-3"><span>RNAVT nº 8383</span></div>
            </div>
            <div class="col-6 col-lg-3 item text">
              <ul>
                <li><router-link :to="{name: 'Team'}">{{ $t("footer.aboutUs") }}</router-link></li>
                <li><router-link :to="{name: 'Experiences'}">{{ $t("footer.ourproducts") }}</router-link></li>
                <!-- <li><router-link to="/#tailor-made-experience">{{ $t("footer.taylorYourJourney") }}</router-link></li> -->
                <li><a href="/#tailor-made-experience">{{ $t("footer.taylorYourJourney") }}</a></li>
                <!-- <li><router-link :to="{name: 'Vouchers'}">{{ $t("footer.vouchers") }}</router-link></li> -->
                <!-- <li><router-link to="/#profile-quiz">{{ $t("footer.travellerType") }}</router-link></li> -->
                <li><a href="/#profile-quiz">{{ $t("footer.travellerType") }}</a></li>
              </ul>
            </div>
            <div class="col-6 col-lg-3 item text">
              <ul>
                <!-- <li><a @click="sendClicked(16)">{{ $t("footer.beforeTravelling") }}</a></li> -->
                <router-link :to="{name: 'Blog'}">{{ $t("footer.blog") }}</router-link>
                <li><a @click="sendClicked('contacts')">{{ $t("footer.contacts") }}</a></li>
                <!-- <li><router-link to="/#newsletter">{{ $t("footer.newsletter") }}</router-link></li> -->
                <li><a href="/#newsletter">{{ $t("footer.newsletter") }}</a></li>
                <!-- <li><a @click="sendClicked(18)">{{ $t("footer.letsimprove") }}</a></li> -->
                <!-- <li><a @click="sendClicked(19)">{{ $t("footer.spontaneousCandidature") }}</a></li> -->
                <!-- <li><a @click="sendClicked(24)">{{ $t("footer.standardInformationSheet") }}</a></li> -->
                <router-link to="/FAQ">{{ $t("footer.q&AFAQ") }}</router-link>
              </ul>
            </div>
            <div class="col-6 col-lg-3 item text">
              <ul>
                <!-- <li><a href="https://play.google.com/store">{{ $t("footer.app") }}</a></li> -->
                <li><a @click="sendClicked('terms-and-conditions')">{{ $t("footer.termsConditions") }}</a></li>
                <!-- <li><a @click="sendClicked(20)">{{ $t("footer.privacyPolicy") }}</a></li> -->
                <!-- <li><a @click="sendClicked(22)">{{ $t("footer.cookieUsage") }}</a></li> -->
                <li><a href="https://www.livroreclamacoes.pt/Inicio/" target="_blank">{{ $t("footer.complaintsBook") }}</a></li>
                <li><a href="https://www.cniacc.pt/pt/" target="_blank">{{ $t("footer.cniacc") }}</a></li>
              </ul>
            </div>
            <div class="col item lastItem cursor-pointer">
              <img @click="sendClicked('rhaq-si-qualificacao-si-internacionalizacao')" src="../assets/Footer/footer.png" />
            </div>
          </div>
        </div>
      </footer>
    </div>
  </section>
</template>

<script>
import IconsSocial from "@/components/IconsSocial.vue";

export default {
   components: { IconsSocial },
   methods: {
    sendClicked (sku) {
      this.$router.push({name: 'Page', params: {sku: sku} });
    },
   },
};
</script>

<style scoped>
>>>#footer{
  bottom: 0 !important;
}
a {
  color: white !important;
  text-decoration: none;
  cursor: pointer;
}
p{
  color: white;
}
.footer-dark {
  padding: 25px 0;
  color: #f0f9ff;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.9), #a3161c);
}

.footer-dark ul {
  padding: 0;
  list-style: none;
  line-height: 2;
  font-size: 15px;
  margin-bottom: 0;
  text-align: left;
}

.footer-dark ul a {
  color: inherit !important;
  text-decoration: none;
  opacity: 0.9;
}
a:not(.md-button) {
    text-decoration: none!important;
}

.footer-dark ul a:hover {
  opacity: 0.8;
}

/* @media (max-width: 767px) {
  .footer-dark ul {
    margin-top: 30px;
    text-align: center;
    padding-bottom: 20px;
  }
  .footer-dark .item.lastItem img {
    width: 80% !important;
  }
} */

.footer-dark .item.text {
  margin-bottom: 36px;
}

@media (max-width: 767px) {
  .footer-dark .item.text {
    margin-bottom: 0;
  }
}

.footer-dark .item.text p {
  opacity: 0.6;
  margin-bottom: 0;
}

.footer-dark .item.lastItem {
  text-align: center;
}
.footer-dark .item.lastItem img {
  width: 50%;
}

@media (max-width: 991px) {
  .footer-dark .item.lastItem {
    text-align: center;
    margin-top: 20px;
  }
  .footer-dark .icons {
    justify-content: space-around;
    position: relative;
    right: 15px;
  }
   .footer-dark ul {
    margin-top: 30px;
    text-align: center;
    padding-bottom: 20px;
  }
  .footer-dark .item.lastItem img {
    width: 80% !important;
  }
}
@media (max-width: 575px) {
  .footer-dark .icons {
    justify-content: center;
    right: 0;
  }
}

.footer-dark .item.lastItem > a:hover {
  opacity: 0.9;
}

.footer-dark .logo img {
  width: 65%;
}

.footer-dark .icons span:hover {
  opacity: 0.8;
}
</style>

<template>
    <div class="blog-thumb container-fluid cursor-pointer" @click="thumbClicked">
      <!-- Header info (date and category) -->
      <div class="row">
        <div class="col-lg-6 col-md-12">
            <p class="header-info">{{readableDate}}</p>
        </div>
        <div class="col-lg-6 col-md-12">
            <p class="header-info text-right"> {{categories}} </p>
        </div>
      </div>
      <!-- Image and read time Read time -->
      <div class="col-12">
          <div class="position-relative p-0">
            <!-- Read time -->
            <div class="position-absolute read-div">
              <img v-if="imageAuthor" class="img-read" :src="imageAuthor" alt="Blog Image">
              <div class="d-inline position-absolute span-read">
                <div class="d-flex flex-column justify-content-center align-items-center text-center">
                    <p class="mb-0 text-RT">{{ $t("readTime") }}</p>
                    <p class="mb-0 RT" >{{readTime}}</p>
                </div>
              </div>
            </div>  
            <!-- Post image -->
            <img v-if="imageUrl" class="blog-img" :src="imageUrl">
          </div>
      </div> 
      <!-- Title -->
      <div class="row mt-5">
        <div class="col-12 title-style">
          <h2>{{post.title}}</h2>
        </div>
      </div>
      <!-- Excerpt -->
      <div class="row">
          <div class="col-12">
              <p class="description">{{excerpt}}</p>
          </div>
      </div>
    </div>
</template>

<script>
import { UTILS_HELPER } from "@/helpers/utils.js";
import { API_HELPER } from "@/helpers/api.js";

const EXCERPT_LENGTH = 200;

export default {
  props: {
    post: Object
  },
  computed: 
  {
    categories: function() {
      return this.post && this.post.categories ? this.post.categories.map(x=> x.name).join(", ") : '';
    },
    imageUrl: function()
    {
      return this.post.image ? API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.post.image]) : '';      
    },
    imageAuthor: function(){
      return this.post && this.post.author && this.post.author.photos && this.post.author.photos.length >= 3 ? API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.post.author.photos[2].url]) : null;  
    },
    excerpt: function()
    {
      var result = '';
      if(this.post && this.post.body)
      {
        var div = document.createElement('div');
        div.innerHTML = this.post.body.trim();
        
        result = div.textContent || div.innerText || "";
        if(result.length > EXCERPT_LENGTH)
        {
          result = result.substring(0, EXCERPT_LENGTH) + "...";
        }
      }

      return result;
    },
    readTime: function ()
    {
      return this.post.body ? UTILS_HELPER.readingTime(this.post.body) : "";
    },
    readableDate: function (){
      return this.post.createdAt ? UTILS_HELPER.formatDateDotted(this.post.createdAt) : "";
    }
  },
  methods:
  {
    thumbClicked: function()
    {
      this.$router.push({name: "BlogDetail", params: {post: this.post}, query: {slug: this.post.slug} });
    }
  }
}
</script>

<style scoped>
/* Structure */
.blog-thumb{
  padding: 1vw 6vw;
}

/* Anchor */
a{
  color: #a3161c;
  text-decoration: none;
}
a:hover{
  color: #962422;
  opacity: 0.5;
}
/* Texts */
p{
  font-size: 16px;
}
h2{
  text-align: center;
  font-weight: bold;
}
.header-info{
  color:#962422 ;
  font-weight: bold;
  text-decoration: none;
  word-break: break-word;
}
.description{
 text-align:center;
 word-break: break-word;
}
.title-style{
  word-break: break-word;
}
/* Image */
.blog-img{
  object-fit: cover;
  width:100%;
  height: 100%;
}
/* Read component */
.read-div{
  position: absolute;
  bottom: 2%;
  left: 1%;
}
.img-read{
  position: relative;
  border-radius: 100%;
  border:2px solid white ;
  z-index: 2;
  width: 60px;
  height: 60px;
  object-fit: cover;
}
.span-read{
  background-color: rgb(204, 204, 204);
  border: 2px solid white;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  margin-left: -15px;
  margin-top: 10px;
  height: 37px;
  width: 100px;
  bottom: 5px;
}
@media screen and (max-width: 400px) {
  .span-read{
    padding-bottom: 10px;
  }
}
.text-RT{
  font-size: 11px;
}
.RT{
  font-size: 14px;
  margin-top: -4px;
}

</style>
export default class InputGetOrders
{
  constructor(pageSize, pageNumber, orderBy, propertyName, searchText) 
  {
    this.pageSize = pageSize;
    this.pageNumber = pageNumber;
    this.orderBy = orderBy;
    this.propertyName = propertyName;
    this.searchText = searchText;
  }    
}
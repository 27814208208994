import { USER_SERVICE } from "@/services/user.service.js";
import router from '@/router/index';
import store from '@/store/index.js'
import { API_HELPER } from "@/helpers/api.js";

const user = JSON.parse(localStorage.getItem('user'));
const state = user
    ? { status: { loggedIn: true }, user ,reset: null, update: null, processConfirm: null, processQuiz: null, processNewsletter: undefined, refresh: undefined}
    : { status: {}, user: null ,reset: null, update: null, processConfirm: null, processQuiz: null, processNewsletter: undefined, refresh: undefined};

const actions = {
    login({ dispatch, commit }, { username, password }) {
        dispatch('alert/clear', null, { root: true });
        commit('loginRequest', { username });
        
        USER_SERVICE.login(username, password)
            .then(
                user => {
                    commit('loginSuccess', user);
                    
                    if(user.data && user.data.role == "Admin")
                    {
                        var page = `Login?username=${username}&token=${user.data.externalToken}`;
                        var fullUrl = encodeURI(API_HELPER.pathJoin([process.env.VUE_APP_BACK, "#", page]));
                        window.location = fullUrl;
                    }
                    else
                    {
                        var syncCart = store.state.cart.products.data && store.state.cart.products.data.length;
                        var syncFavorites = store.state.favorites.listOutput.data && store.state.favorites.listOutput.data.length;

                        if(syncCart && syncFavorites)
                        {
                            dispatch('cart/sync', dispatch('favorites/sync', router.push("/"), { root: true }), { root: true });
                        }
                        else if(syncCart)
                        {
                            dispatch('cart/sync', router.push("/"), { root: true });
                        }
                        else if(syncFavorites)
                        {
                            dispatch('favorites/sync', router.push("/"), { root: true });
                        }
                        else
                        {
                            window.location.href = "/";
                        }
                    }
                },
                error => {
                    commit('loginFailure', user);                
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    signup({ dispatch, commit }, { firstName,lastName,username,email,password,newsletter }) {
        dispatch('alert/clear', null, { root: true });
        commit('signupRequest');
        
        USER_SERVICE.signup({ firstName,lastName,username,email,password,newsletter })
            .then(
                user => {
                    commit('signupSuccess', user);
                    dispatch('alert/success', user, { root: true });

                },
                error => {
                    commit('signupFailure', user);                
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    requestLinkPassword({ dispatch, commit }, email) {
        dispatch('alert/clear', null, { root: true });
        commit('linkRequest');

        USER_SERVICE.requestLink(email)
            .then(
                user => {
                    commit('linkSuccess',user);
                    dispatch('alert/success', user, { root: true });
                },
                error => {
                    commit('linkFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    resetPassword({ dispatch, commit }, {username, password, token}) {
        dispatch('alert/clear', null, { root: true });
        commit('resetPasswordRequest');

        USER_SERVICE.resetPassword(username, password, token)
            .then(
                user => {
                    commit('resetPasswordSuccess', user);
                    dispatch('alert/success', user, { root: true });
                },
                error => {
                    commit('resetPasswordFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    updateProfile({ dispatch, commit }, profile) {
        dispatch('alert/clear', null, { root: true });
        commit('profileRequest');
        
        USER_SERVICE.updateProfile(profile)
        .then(
            output => {
                    commit('profileSuccess',output);
                    dispatch('alert/success', output, { root: true });
                }, 
                error => {
                    commit('profileFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    confirmAccount({ dispatch, commit }, {username, token}) {
        dispatch('alert/clear', null, { root: true });
        commit('confirmAccountRequest');
        USER_SERVICE.confirmAccount(username, token)
        .then(
            user => {
                commit('confirmAccountSuccess', user);
                dispatch('alert/success', user, { root: true });
            },
            error => {
                commit('confirmAccountFailure', error);
                dispatch('alert/error', error, { root: true });
            }
        );
    },
    submitQuiz({ dispatch, commit }, quiz) {
        dispatch('alert/clear', null, { root: true });
        commit('submitQuizRequest', quiz);
        USER_SERVICE.submitQuiz(quiz)
        .then(
            user => {
                commit('submitQuizSuccess', user);
                dispatch('alert/success', user, { root: true });
            },
            error => {
                commit('submitQuizFailure', error);
                dispatch('alert/error', error, { root: true });
            }
        );
    },
    submitNewsletter({ dispatch, commit }, newsletter) {
        dispatch('alert/clear', null, { root: true });
        commit('submitNewsletterRequest', newsletter);

        USER_SERVICE.submitNewsletter(newsletter)
        .then(
            output => {
                commit('submitNewsletterSuccess', output);
                dispatch('alert/success', output, { root: true });
            },
            error => {
                commit('submitNewsletterFailure', error);
                dispatch('alert/error', error, { root: true });
            }
        );
    },
    refreshToken({ dispatch, commit }) {
        dispatch('alert/clear', null, { root: true });
        commit('refreshRequest');
        
        /* Refresh the token and try again */
        USER_SERVICE.refreshToken(state.user.data.refreshToken, state.user.data.profile.email)
        .then(user =>{
            commit('refreshSuccess', user);
       })
       .catch((error) => 
       {
            commit('refreshFailure', error);
            dispatch('alert/error', error, { root: true });
            dispatch('account/logout', null, { root: true });
       });
    },
    logout({ dispatch, commit }) {
        dispatch('alert/clear', null, { root: true });
        dispatch('favorites/clear', null, { root: true });
        dispatch('cart/clear', null, { root: true });

        commit('logout');

        USER_SERVICE.logout();
    }
};

const mutations = {
    /* Login */
    loginRequest(state, user) {
        state.status = { loggingIn: true };
        state.user = user;
    },
    loginSuccess(state, user) {
        state.status = { loggedIn: true };
        state.user = user;

        // uncomment if you want to test the token with a set expiration date
        // var currentDate = new Date();
        // user.data.accessTokenExpirationDate = new Date(currentDate.getTime() + 3*60000);

        // login successful if there's a jwt token in the response
        if (user.data && user.data.refreshToken) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('user', JSON.stringify(user));
        }

    },
    loginFailure(state) {
        state.status = {};
        state.user = null;
    },
    /* Signup */
    signupRequest(state)
    {
        state.status = null;
    },
    signupSuccess(state)
    {
        state.status = {registered: true};
    },
    signupFailure(state)
    {
        state.status = false;
    },
     /* Request link */
    linkRequest(state) {
        state.reset = null;
    },
    linkSuccess(state) {
        state.reset = true;
    },
    linkFailure(state) {
        state.reset = false;
    },
     /* Reset Password */
    resetPasswordRequest(state) {
        state.reset = null;
    },
    resetPasswordSuccess(state) {
        state.reset = true;
    },
    resetPasswordFailure(state) {
        state.reset = false;
    },
    /* Profile */
    profileRequest(state) {
        state.update = null;
    },
    profileSuccess(state, output) {
        state.update = true;

        state.user.data.profile = output.data.profile;
        state.user.data.company = output.data.company;
        localStorage.setItem('user', JSON.stringify(state.user));
    },
    profileFailure(state) {
        state.update = false;
    },
    /* Confirm Account */
    confirmAccountRequest(state) {
        state.processConfirm = null;
    },
    confirmAccountSuccess(state) {
        state.processConfirm = true;
    },
    confirmAccountFailure(state) {
        state.processConfirm = false;
    },
    /* */
    /* Submit quiz */
    submitQuizRequest(state, quiz) {
        state.processQuiz = null;

        state.user.data.questions = quiz;
        localStorage.setItem('user', JSON.stringify(state.user));
    },
    submitQuizSuccess(state) {
        state.processQuiz = true;
    },
    submitQuizFailure(state) {
        state.processQuiz = false;
    },

    /* Newsletter  */
    submitNewsletterRequest(state) {
        state.processNewsletter = null;
    },
    submitNewsletterSuccess(state) {
        state.processNewsletter = true;
    },
    submitNewsletterFailure(state) {
        state.processNewsletter = false;
    },
      /* refreshToken */
      refreshRequest(state) {
        state.refresh = 'refreshing';
    },
    refreshSuccess(state, user) {
        state.user = user;
        // login successful if there's a jwt token in the response
        if (user && user.data && user.data.refreshToken) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('user', JSON.stringify(user));
        }

        state.refresh = true;
    },
    refreshFailure(state) {
        state.refresh = false;
    },
    /* */
    logout(state) {
        state.status = {};
        state.user = null;

        localStorage.removeItem('user');
        localStorage.removeItem('locale');
        
        router.go('/Login'); // Force all modules to reset state
    }
};

export const account = {
    namespaced: true,
    state,
    actions,
    mutations
};
<template>
    <div class="image-options-selector justify-content-center row">
        <div class="image-option position-relative cursor-pointer" v-for="option in options" :key="option.key" @click="onOptionClicked(option.key)" :class="{'not-selected': selected != null && selected != option.key, 'active': value == option.key, 'inactive': value != option.key}">
            <img :src="require('@/assets/' + option.imageStatic)" class="static w-100"/>
            <img :src="require('@/assets/' + option.image)" class="active w-100"/>
            <span>{{option.label}}</span>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        options:
        {
            type: Array,
            default: () => [] // { key: '', label: '', image: '' }
        },
        value: String
    },  
    data: function () {
        return {
            selected: null
        };
    },
    methods: 
    {
        onOptionClicked: function(key)
        {
            this.selected = key;
            this.$emit('input', key);
            this.$emit('option-clicked');
        }
    }
}
</script>

<style scoped>
.static {
  position:absolute;
  background: white;
  padding: 0 16px 16px 0;
  height: 100%;
  object-fit: cover;
}

.image-option
{
    background-color: white;
    padding: 0.5rem;
    width: 100%;
    height: 22%;
    overflow: hidden;
}

@media screen and (min-width: 1200px) {
    .static:hover {
        opacity:0;
    }

    .image-options-selector
    {
        gap: 4%;
    }

    .image-option
    {
        width: 22%;
        height: unset;
    }
}

@media screen and (max-width: 1199px), 
       screen and (max-height: 720px) {
    .image-options-selector
    {
        gap: 10px;
    }

    .image-option
    {
        max-height: 400px;
        width: 215px;
    }

    .image-option > img
    {
        max-height: 100%;
        object-fit: cover;
    }
}

.image-option,
.image-option > img
{
    border-radius: 7px;
}

.image-option:hover .overlay
{
    visibility: visible;
}

.image-option.not-selected
{
    opacity: 0.5;
}

.image-option > span
{
    position: absolute;
    bottom: 10%;
    width: 100%;
    left: 0;
    text-align: center;
    color: white;
    font-size: 20px;
    padding: 1rem;
}

.inactive
{
    opacity: 0.7;
    transform: scale(0.9);
}
</style>
<template>
    <BasePopUp @detailsClosed="closeConfirmRequested">
    <!-- Close button -->
      <div class="close-button p-3" @click="closeClicked">
        <p><img class="btn-size" src="@/assets/closeBlack.svg"/></p>
      </div>
        <div class="popup-addresses d-flex">
            <div v-if="details" class="modal-body">
                <h4 class="mb-4 text-left fw-bold">{{ $t("address.billAddress") }}</h4>
                <p v-if="details.firstName" class="mb-2 text-left"><span class="fw-bold">{{ $t("address.firstName") }}: </span>{{details.firstName}}</p>
                <p v-if="details.lastName" class="mb-2 text-left"><span class="fw-bold">{{ $t("address.lastName") }}: </span>{{details.lastName}}</p>
                <p v-if="details.phoneNumber" class="mb-2 text-left"><span class="fw-bold">{{ $t("address.phoneNumber") }}: </span>{{details.phoneNumber}}</p>
                <p v-if="details.email" class="mb-2 text-left"><span class="fw-bold">{{ $t("address.email") }}: </span>{{details.email}}</p>
                <p v-if="details.nationality" class="mb-2 text-left"><span class="fw-bold">{{ $t("address.nationality") }}: </span>{{details.nationality}}</p>
                <p v-if="details.vat" class="mb-2 text-left"><span class="fw-bold">{{ $t("address.nif") }}: </span>{{details.vat}}</p>
                <p v-if="details.billingAddress" class="mb-2 text-left"><span class="fw-bold">{{ $t("address.address") }}: </span>{{details.billingAddress}}</p>
                <p v-if="details.zipCode" class="mb-2 text-left"><span class="fw-bold">{{ $t("address.zip") }}: </span>{{details.zipCode}}</p>
                <p v-if="details.city" class="mb-2 text-left"><span class="fw-bold">{{ $t("address.city") }}: </span>{{details.city}}</p>
                <p v-if="details.country" class="mb-2 text-left"><span class="fw-bold">{{ $t("address.country") }}: </span>{{details.country}}</p>
                <p v-if="details.otherInfo" class="mb-2 text-left"><span class="fw-bold">{{ $t("address.otherInfo") }}: </span>{{details.otherInfo}}</p>
            </div>
        </div> 
    </BasePopUp>        
</template>

<script>
import BasePopUp from './BasePopUp.vue';

export default {
    props: {
        details: {
            type: Object
        },
    },
    components: {
        BasePopUp,
    },
    methods:{
        closeConfirmRequested()
        {       
            this.$emit("closeConfirmRequested");
        },
        closeClicked: function()
        {
            this.$emit("close-request");
        }
    }
}
</script>

<style scoped>
.close-button > p
{
  width: 25px;
  height: 25px;
  margin: 10px;
}
.close-button
{
  cursor: pointer;
  z-index: 99;
  position: absolute;
  right: 0;
}
.btn-size{
    width: 30px;
}
@media screen and (max-width: 576px) {
  .popup-addresses{
    flex-direction: column;
  }
}
</style>
<template>
  <div v-if="currentUrl" class="d-flex" id="share">
    <div class="share-block d-flex align-items-center d-none d-lg-block" id="sharetext">
      <h4 class="mt-2">{{ $t("share") }}</h4>
    </div>
    <div class="share-block cursor-pointer d-flex justify-content-center align-items-center flex-grow-1" id="facebook">
      <facebook :url="currentUrl" scale="1" ></facebook>
    </div>
    <div class="share-block cursor-pointer d-flex justify-content-center align-items-center flex-grow-1" id="twitter">
      <twitter :url="currentUrl" scale="2" ></twitter>
    </div>
    <div class="share-block cursor-pointer d-flex justify-content-center align-items-center flex-grow-1" id="linkedin">
      <linkedin :url="currentUrl" scale="2" ></linkedin>
    </div>
    <div class="share-block cursor-pointer d-flex justify-content-center align-items-center flex-grow-1" id="pinterest">
      <pinterest :url="currentUrl" scale="1" ></pinterest>
    </div>
    <div class="share-block cursor-pointer d-flex justify-content-center align-items-center flex-grow-1" id="email">
      <email :url="currentUrl" scale="2" ></email>
    </div>
  </div>
</template>

<script>
import {
  Facebook,
  Twitter,
  Linkedin,
  Pinterest,
  Email,
} from "vue-socialmedia-share";

export default {
  components: {
    Facebook,
    Twitter,
    Linkedin,
    Pinterest,
    Email,
  },
  data: function () {
    return {
      currentUrl : '',
    };
  },
  created: function(){
    this.currentUrl = window.location.href;
  }
};
</script>

<style scoped>
#sharetext
{
  width: 120px;
  max-width: 120px;
}
#share
{
  height: fit-content;
  gap: 3px;
}

/deep/.fa-icon
{
  color: white !important;
  width: 15px;
}

h4{
  color: white;
  font-size: 15px;
  text-align: center;
}

.share-block
{
  background-color: rgba(0, 0, 0, 0.8); 
  padding: 0 10px;
}
</style>
import Vue from "vue";
import Vuex from "vuex";
import { alert} from '@/store/alert.module';
import { account } from '@/store/account.module';
import { cart } from '@/store/cart.module';
import { vouchers } from '@/store/vouchers.module';
import { orders } from '@/store/orders.module';
import { categories } from '@/store/categories.module';
import { inspirations } from '@/store/inspirations.module';
import { products } from '@/store/products.module';
import { staticPages } from '@/store/staticPages.module';
import { members } from '@/store/team.module';
import { posts } from '@/store/posts.module';
import { faqs } from '@/store/faqs.module';
import { favorites } from '@/store/favorites.module';
import { messages } from '@/store/messages.module';
import { testimonials } from '@/store/testimonials.module';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    alert,
    account,
    cart,
    vouchers,
    orders,
    categories,
    inspirations,
    products,
    staticPages,
    members,
    posts,
    faqs,
    favorites,
    messages,
    testimonials
  },
});

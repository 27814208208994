<template>
  <section>
    <LogoLoading v-if="!currentPage"/>
        <div v-else>
            <div class="d-flex flex-column" id="divgeneral">
                <div class="imgtop">
                    <img :src="url + bannerImage" id="imgtop" alt="imgtop">
                </div>
                <div class="container-fluid" id="backgroundcolor">
                    <div class="container d-flex align-items-center">
                        <div class="row">
                            <!-- logo -->
                            <div class="col-12 col-lg-3" :style="{marginTop: '-185px', 'z-index': '1'}">
                                <div class="text-center">
                                    <img src="../assets/logo.svg" alt="imgtop" id="roundedlogo">
                                </div>
                            <!-- Icons Social  -->
                            <IconsSocial class="iconsSocial"></IconsSocial>
                            </div>
                            <!-- Text -->
                            <div v-for="tab in tabs" :key="tab.id" class="col-12 col-lg-9 text pt-3">
                                <p v-html="tab.content" class="text-light"></p>
                            </div>
                        </div>
                    </div>
                    <h1 class="font-bolder" id="h1title">{{ $t("team.meetTeam") }}</h1>
                    <!-- Slider Equipa -->
                    <div class="container-fluid">
                        <vueper-slides style="padding:0 60px" fixed-height="700px" class="no-shadow" :bullets="false" :visible-slides="5" slide-multiple :gap="1" :dragging-distance="70" :touchable="true" :breakpoints="{
                                1700: { visibleSlides: 3, slideMultiple: 3 },
                                1100: { visibleSlides: 2, slideMultiple: 2 },
                                780: { visibleSlides: 1, slideMultiple: 1, arrows: false, touchable:true},
                            }"
                            >
                            <!-- Custom arrows -->
                            <template v-slot:arrow-left>
                                <span class="icon-arrow-left-team"></span>
                            </template>
                            <template v-slot:arrow-right>
                                <span class="icon-arrow-right-team"></span>
                            </template>
                            <!-- Content -->
                            <vueper-slide v-for="member in membersList" :key="member.id" >
                                <template v-slot:content>
                                <TeamThumb :member="member"/>
                                </template>
                            </vueper-slide>
                        </vueper-slides>
                    </div>
                </div>
                <!-- What they say about us -->
                <WhatTheySay class="mt-2"/>
                <!-- Newsletter -->
                <Newsletter></Newsletter>
            </div>
        </div>
  </section>
</template>

<script>
import IconsSocial from "@/components/IconsSocial.vue";
import Newsletter from "@/components/Newsletter.vue"
import TeamThumb from "@/components/TeamThumb.vue";
import WhatTheySay from "@/components/WhatTheySay.vue";
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";
import { mapState, mapActions } from "vuex";
import InputGetList from "@/classes/InputGetList";
import LogoLoading from "@/components/LogoLoading.vue"
import { API_HELPER } from "@/helpers/api.js";

const TEAM_PAGE_ID = 23

export default {
    metaInfo () {
      return { 
            title: `${this.$t("vueRoutes.teamTitle")} - Wild Douro`, 
            htmlAttrs: {
                lang: this.$i18n.locale,
            },
            meta: 
            [
                {name: 'description', content: this.$t("vueRoutes.intro")},
                {name: 'keywords', content: this.$t("vueRoutes.defaultKeywords")},

                // // Twitter Card
                // {name: 'twitter:card', content: "summary"},
                // {name: 'twitter:title', content: `${this.$t("vueRoutes.teamTitle")} - Wild Douro`},
                // {name: 'twitter:description', content: this.$t("vueRoutes.intro")},
                // {name: 'twitter:image', content: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, "assets/hero/hero.jpg"]) },

                // //Facebook OpenGraph
                // {property: 'og:title', content: `${this.$t("vueRoutes.teamTitle")} - Wild Douro`},
                // {property: 'og:site_name', content: 'Wild Douro'},
                // {property: 'og:type', content: 'website'},
                // {property: 'og:image', content: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, "assets/hero/hero.jpg"]) },
                // {property: 'og:description', content: this.$t("vueRoutes.intro")}
            ], 
            link: [
                {rel: 'alternate', hreflang: 'en', href: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.$t("vueRoutes.team", 'en')])},
                {rel: 'alternate', hreflang: 'pt', href: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.$t("vueRoutes.team", 'pt')])},
            ]
            
        } 
    },
components: { IconsSocial,VueperSlides, VueperSlide, TeamThumb, Newsletter, WhatTheySay, LogoLoading },
    data: function () {
        return {
        url: process.env.VUE_APP_URL_ROOT
        };
     },
  computed: {
    ...mapState({
        membersOutput: state => state.members.listOutput,
        staticPagesOutput: state => state.staticPages.listOutput,
    }),
    membersList: function() {
      return this.membersOutput && this.membersOutput.data ? this.membersOutput.data : [];
    },
    pagesList: function() {
      return this.staticPagesOutput && this.staticPagesOutput.data ? this.staticPagesOutput.data : [];
    },
    currentPage: function()
    {
      return this.pagesList.length ? this.pagesList.find(page => page.id == TEAM_PAGE_ID) : null;
    },
    tabs: function()
    {
      return this.currentPage && this.currentPage.tabs ? this.currentPage.tabs : [];
    },
    bannerImage: function()
    {
        return this.tabs && this.tabs[0].bannerImage ? this.tabs[0].bannerImage : null; 
    },
    loading: function()
    {
      return this.staticPagesOutput == null || this.membersOutput == null;
    },
  },
    methods: {
        ...mapActions({
            getMembers: 'members/getList',
            getStaticPages: 'staticPages/getList',
        }),
     invokeGetInfo()
        {
            const itemsPerPage = 999;
            const currentPage = 1;
            const searchText = '';
            const sorter = 2; // desc
            const propertyName = "id";

            var input = new InputGetList(itemsPerPage, currentPage, searchText, sorter, propertyName);
            // List specific parameters
            input.visible= true;
            this.getMembers(input);
            this.getStaticPages(input);
        },
  },
  mounted() {
     // Get info from API
    this.invokeGetInfo();
  },
}
</script>

<style scoped>
#divgeneral{
    position: relative;
}
.imgtop{
    position: relative;
}
#imgtop{
    object-fit: cover !important;
    width: 100% !important;
    height: 30vh;
    box-shadow: 0 0.5rem 0.5rem rgba(0, 0, 0, 0.35) !important;
}
#roundedlogo{
    background-color: white;
    border-radius: 100%;
    width: 16em !important;
    height: 16em !important;
    border: 5px solid #962422;
    padding-left: 10px;
    padding-right: 10px;
    box-shadow: inset 0px 0px 10px black;
}
#backgroundcolor{
    background: linear-gradient( rgb(70, 70, 70) 5%, rgb(0, 0, 0) 50%);
}
.text{
    text-align: justify;
}
#h1title{
    color: #962422;
    text-align: center;
    margin-top: 3rem;
} 
>>>.vueperslides--fixed-height{
    margin-bottom: 4em !important;
}
[class^="icon-arrow-left-team"], [class*="icon-arrow-right-team"] {
  font-size: 100px;
}
.iconsSocial[data-v-3c137f17] {
  padding-top: 0 !important;
}
@media screen and (min-width: 992px) {
    .iconsSocial{
        flex-direction:column !important;
    }
}
@media screen and (max-width: 576px) {
    >>>.vueperslides{
        padding: 0!important;
    }
}
</style>
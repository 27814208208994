<template>
  <div v-if="searchQuery" class="my-5">
    <p class="container">{{$t("searchResults")}}: {{searchQuery}}</p>
    <!-- Go back (show when a specific search block is selected) -->
    <SearchInspirations :searchQuery="searchQuery" v-if="selectedSearch == null || selectedSearch == 'inspirations'" @see-more-requested="selectedSearch = 'inspirations'" @see-more-cancelled="selectedSearch = null"></SearchInspirations>
    <!-- <SearchVouchers :searchQuery="searchQuery" v-if="selectedSearch == null || selectedSearch == 'vouchers'" @see-more-requested="selectedSearch = 'vouchers'" @see-more-cancelled="selectedSearch = null"></SearchVouchers> -->
    <SearchBlog :searchQuery="searchQuery" v-if="selectedSearch == null || selectedSearch == 'blog'" @see-more-requested="selectedSearch = 'blog'" @see-more-cancelled="selectedSearch = null"></SearchBlog>
  </div>
</template>


<script>
import SearchInspirations from "@/components/SearchInspirations.vue"
// import SearchVouchers from "@/components/SearchVouchers.vue"
import SearchBlog from "@/components/SearchBlog.vue"
import { UTILS_HELPER } from "@/helpers/utils.js";

export default {
      metaInfo () {
      return { 
        title: this.searchQuery,
        titleTemplate: '%s - Wild Douro', 
        htmlAttrs: {
          lang: this.$i18n.locale,
        },
        meta: 
        [
          {name: 'description', content: UTILS_HELPER.htmlToText(this.$t("vueRoutes.intro"))},
          {name: 'keywords', content: this.$t("vueRoutes.defaultKeywords")},
          // // Twitter Card
          // {name: 'twitter:card', content: "summary"},
          // {name: 'twitter:title', content:  this.searchQuery},
          // {name: 'twitter:description', content: UTILS_HELPER.htmlToText(this.$t("intro"))},
          // {name: 'twitter:image', content: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, "assets/hero/hero.jpg"]) },
          // //Facebook OpenGraph
          // {property: 'og:title', content:  this.searchQuery},
          // {property: 'og:site_name', content: 'Wild Douro'},
          // {property: 'og:type', content: 'website'},
          // {property: 'og:image', content: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, "assets/hero/hero.jpg"]) },
          // {property: 'og:description', content: UTILS_HELPER.htmlToText(this.$t("intro"))},
        ], 
        link: [
          {rel: 'canonical', href: process.env.VUE_APP_URL_ROOT},
        ]
      } 
    }, 
    components: {
        SearchInspirations,
        SearchBlog,
        // SearchVouchers,
    },
    data() {
      return {
        searchQuery: '',
        selectedSearch: null
      }
    },
    methods: 
    {
      initializePage () 
      {
        if(!this.$route.query.query)
        {
            this.$router.push({ path: '/' });
        }
        else
        {
            this.searchQuery = this.$route.query.query;
        }
      },
    },
    mounted() {
      this.initializePage();
    },
    watch: {
      $route(to) {
        if (to.name == "SearchResults") {
          this.initializePage();
        }
      },
    },
}
</script>
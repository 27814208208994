import { ORDERS_SERVICE } from "@/services/orders.service.js";
import store from '@/store/index.js'

const state = {
    listOrders: {},
    order: null,
}

const actions = {

    getOrders({ dispatch, commit }, inputGetOrders) {
        dispatch('alert/clear', null, { root: true });
        commit('listRequest');
        
        ORDERS_SERVICE.getOrders(inputGetOrders)
            .then(
                output => {
                    commit('listSuccess', output);
                },
                error => {
                    commit('listFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    createOrder({ dispatch, commit }, { paymentType, info, details, apiKey }) {
        commit('addRequest');

        const loggedIn = localStorage.getItem('user');
        if(loggedIn)
        {
            ORDERS_SERVICE.createOrder(paymentType, info, details, apiKey)
            .then(
                output => {
                    commit('addSuccess', output);
                    dispatch('cart/clear', null, { root: true });
                },
                error => {
                    commit('addFailure', error);
                    dispatch('alert/error', error, { root: true }); 
                }
            );
        }
        else
        {
            ORDERS_SERVICE.createGuestOrder(store.state.cart.products.data, paymentType, info, details, apiKey)
            .then(
                output => {
                    commit('addSuccess', output);
                    dispatch('cart/clear', null, { root: true });
                },
                error => {
                    commit('addFailure', error);
                    dispatch('alert/error', error, { root: true }); 
                }
            );
        }
    },
};
    
const mutations = {
     /* List */
    listRequest() {
        //
    },
    listSuccess(state, output) {
        state.listOrders = output;
    },
    listFailure(state) {
        state.listOrders = [];
    },
    //add
    addRequest(state) {
        state.order = null;
    },
    addSuccess(state, output) {
        state.order = output;
    },
    addFailure(state) {
        state.order = false;
    },
}; 
    

export const orders = {
    namespaced: true,
    state,
    actions,
    mutations
};
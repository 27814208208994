/* Imports */
import { API_HELPER } from "@/helpers/api.js";
// import { ENVIRONMENT_HELPER } from "@/helpers/environment.js";
import { authHeader } from "@/helpers/auth-header.js";


/* Exports */
// API services that can be invoked on the modules
export const CART_SERVICE = {
    list,
    update, 
    sync
};

/* Consts*/
// Root service to build API url
const API_ROOT_SERVICE = "Carts"

/* Services */
function list()
{
    
    // Build API call.
    var url = API_HELPER.pathJoin([process.env.VUE_APP_API_ROOT, API_ROOT_SERVICE]);
    
    
    const requestOptions = {
        method: 'GET',
        headers:  { ...authHeader()},
    };

    // Process API call, validating the token beforehand.
    return API_HELPER.loggedApiCall(url, null, requestOptions);

}

function update(productId,quantity)
{
    // Build API call.
    var url = API_HELPER.pathJoin([process.env.VUE_APP_API_ROOT, API_ROOT_SERVICE]);
    
    var raw = JSON.stringify({"productId": productId, "quantity": quantity});
    
    const requestOptions = {
        method: 'POST',
        headers:  { ...authHeader(), "Content-Type":"application/json"},
        body: raw
    };

    // Process API call, validating the token beforehand.
    return API_HELPER.loggedApiCall(url, null, requestOptions);
}

function sync(products)
{
    // Build API call.
    var url = API_HELPER.pathJoin([process.env.VUE_APP_API_ROOT, API_ROOT_SERVICE, "sync"]);
    
    var raw = JSON.stringify(products);
    
    const requestOptions = {
        method: 'POST',
        headers:  { ...authHeader(), "Content-Type":"application/json"},
        body: raw
    };

    // Process API call, validating the token beforehand.
    return API_HELPER.loggedApiCall(url, null, requestOptions);
}
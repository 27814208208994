<template>
  <section>
    <!-- Background for bigger devices -->
    <img class="d-none d-md-block shape-background" :src="require('../assets/SideMenu/SideMenu1.png')"/>
    <!-- Menu -->
    <div class="navbar-container">
      <div class="navbar">
        <ul>
          <li><a href="/" id="logo-position"><img src="../assets/TopMenu/Logo/logo.svg" id="logo" alt="logo" width="200" height="170"/></a></li>
          <br>
          <li><router-link :to="{name: 'Team'}">{{ $t("sideMenu.about") }}</router-link></li>
          <li><router-link :to="{name: 'Experiences'}">{{ $t("sideMenu.products") }}</router-link></li>
          <li><a class="cursor-pointer" @click="simulateHamburgerClick">{{ $t("sideMenu.journey") }}</a></li>
          <!-- <li><router-link :to="{name: 'Vouchers'}">{{ $t("sideMenu.vouchers") }}</router-link></li> -->
          <li><router-link :to="{name: 'Blog'}">{{ $t("sideMenu.blog") }}</router-link></li>
          <li class="cursor-pointer"><a @click="sendClicked(17)">{{ $t("sideMenu.contact") }}</a></li>   
          <!-- <li><a href="https://play.google.com/store">{{ $t("sideMenu.app") }}</a></li> -->
          <li><router-link :to="{name: 'Faqs'}">{{ $t("sideMenu.faq") }}</router-link></li>
          <!-- <li class="cursor-pointer"><a @click="sendClicked(18)">{{ $t("sideMenu.improve") }}</a></li> -->
        </ul>
      </div>
      <div class="pop-up-close">
        <p class="pop-up-close-p"><img id="btn-close" class="w-100 h-100 sizebtn" src="@/assets/close.svg" @click="$emit('close')"/></p>
      </div> 
    </div>
  </section>
</template>

<script>

export default {
  data(){
    return{
      drag: false,
    }
  },
  // mounted() {
  //     document.addEventListener('mousedown', () => this.drag = false);
  //     document.addEventListener('mousemove', () => this.drag = true);
  //     document.addEventListener('mouseup', this.handleMouseUp);
  // },
  // destroyed() {
  //     document.removeEventListener('mousedown', () => this.drag = false);
  //     document.removeEventListener('mousemove', () => this.drag = true);
  //     document.removeEventListener('mouseup', this.handleMouseUp);
  // },
  methods: {
    sendClicked (id) {
      this.$router.push({name: 'Page', query: {id: id} });
    },
    handleMouseUp(event) {
      var toggle = document.getElementById("navbar-toggle");
      if(toggle.classList.contains("not-collapsed") && !this.drag)
      {
        var element = document.getElementById("sidebar-1");
        var isClickInside = element.contains(event.target);
        var isAnchor = event.target.tagName == 'a';
        var isToggle = event.target.classList.contains("sizebtn");

        if (!isToggle && (!isClickInside || (isClickInside && isAnchor))) 
        {
          document.getElementById("btn-close").click();
        }
      }

      this.drag = false;
    },
    simulateHamburgerClick()
    {
      const url = new URL('/#tailor-made-experience', window.location.origin)
      window.location.href = url.toString();
      if(this.$route.name == "Home")
      {
        this.$router.go(0)
      }
    },
  },

 };

</script>

<style scoped>
.shape-background
{
  position: absolute;
  left: 0;
  top: -2px;
  height: calc(100vh + 2px);
}

#logo-position{
  height: 115px;
}

#logo{
  object-fit: cover;
}

.navbar-container { 
  overflow: hidden;
  position: relative;
  text-align: left;
  color: white;
}

.pop-up-close-p
{
  position: absolute;
  top: 30px;
  left: 53px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  width: 25px;
}

ul {
  list-style-type: none;
  margin-top: 15vh;
  margin-left: 1vw;
  font-size: 18px;
  
}

li a {
  display: block;
  color:white !important;
  padding: 8px 16px;
  text-decoration: none !important;
}

/* Change the link color on hover */
li a:hover {
  color: rgb(155, 155, 155);
}

@media screen and (max-height: 767px), screen and (max-width: 767px) {
  .shape-background
  {
    display: none !important;
  }

  .navbar-container
  {
    background-color: rgba(0,0,0,0.6);
    width: 100vw;
    height: 100vh;
  }
}

</style>


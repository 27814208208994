<template>
<section class="blog-slider d-flex py-4">
    <div class="container-fluid" id="container-general">
        <div class="row">
            <div class="col-lg-6 col-sm-12 mobile-padding">
                <h2 id="h3title">{{ $t("team.whatThey") }}</h2>
            </div>
            <div class="col-lg-6 col-sm-12 mobile-padding">
                <!-- navigation buttons -->
                <div class="d-flex flex-column">
                    <div class="d-flex w-100 mb-2" id="btn-right">
                    <button class="btn-icon" @click="$refs.blogSlides.previous()"><span class="icon-left"></span></button>
                    <button class="btn-icon" @click="$refs.blogSlides.next()"><span class="icon-right"></span></button>
                    </div>
                </div>
            </div>
            
        </div>
        <div class="row"> 
            <!-- Slides -->
            <vueper-slides ref="blogSlides" fixed-height="600px" class="no-shadow" :bullets="false" :visible-slides="4" :arrows="false" slide-multiple :gap="1" :dragging-distance="70" :touchable="false" :lazy="true" :breakpoints="{
                    1850: { visibleSlides: 2},
                    1300: { visibleSlides: 2},
                    1000: { visibleSlides: 1 }
                    }"
                >        
                <vueper-slide v-for="testimonial in testimonialList" :key="testimonial.id">
                    <template v-slot:content>
                        <WhatTheySayThumb :testimonial="testimonial"></WhatTheySayThumb>
                    </template>
                </vueper-slide>
            </vueper-slides>
        </div>
    </div>
</section>
</template>

<script>
import WhatTheySayThumb from "@/components/WhatTheySayThumb.vue";
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";
import { mapState, mapActions } from "vuex";
import InputGetList from "@/classes/InputGetList";

export default {
  components: {
    WhatTheySayThumb,
    VueperSlides,
    VueperSlide
  },
  computed: {
    ...mapState('testimonials', ['listOutput']),
    testimonialList: function() {
      return this.listOutput && this.listOutput.data ? this.listOutput.data : [];
    },
  },
  methods: {
    ...mapActions("testimonials", ["getList"]),
  },
  mounted() {
    const itemsPerPage = 10;
    const currentPage = 1;
    const searchText = '';
    const sorter = 2; // desc
    const propertyName = "id";

    var input = new InputGetList(itemsPerPage, currentPage, searchText, sorter, propertyName);
    input.visible= true;
    this.getList(input);
  },

}   
</script>

<style scoped>
.blog-slider
{
  overflow: hidden;
}
section,
.title-container
{
  background-color: #EACCC0 !important;
}

#btn-right{
    justify-content: flex-end;
}
#h3title{
     color: #962422;
    font-weight: bold;
}
#container-general{
    padding-left: 5rem;
    padding-right: 5rem;
}

/* Sneak peak of next post */
.vueperslides {height: min-content; } 

[class^="icon-left"], [class*="icon-right"] {
  font-size: 100px;
  line-height: 65px;
}
.btn-icon{
  border: none;
  width: 100px !important;
  height: 50px !important;
  margin: 0 2px;
  cursor: pointer !important;
  background-color: white;
}
@media (max-width: 576px ) {
  #container-general{
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .mobile-padding{
    padding: 10px 0;
  }
  >>>.vueperslides{
    height: 800px!important;
  }
}
</style>
import { VOUCHERS_SERVICE } from "@/services/vouchers.service.js";

const state = { listOutput: null, voucherOutput: null };

const actions = {
    getList({ dispatch, commit }, inputGetVouchers) {
        dispatch('alert/clear', null, { root: true });
        commit('listRequest');
        
        VOUCHERS_SERVICE.list(inputGetVouchers)
            .then(
                output => {
                    commit('listSuccess', output);
                },
                error => {
                    commit('listFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    getDetail({ dispatch, commit }, productId) {
        dispatch('alert/clear', null, { root: true });
        commit('detailRequest');
        
        VOUCHERS_SERVICE.get(productId)
            .then(
                output => {
                    commit('detailSuccess', output);
                },
                error => {
                    commit('detailFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
};

const mutations = {
    /* List */
    listRequest(state) {
        state.listOutput = null;
    },
    listSuccess(state, output) {
        state.listOutput = output;
    },
    listFailure(state) {
        state.listOutput = [];
    },
    /* Detail */
    detailRequest(state) {
        state.voucherOutput = null;
    },
    detailSuccess(state, output) {
        state.voucherOutput = output;
    },
    detailFailure(state) {
        state.voucherOutput = false;
    },
};

export const vouchers = {
    namespaced: true,
    state,
    actions,
    mutations
};
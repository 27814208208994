/* Imports */
import { API_HELPER } from "@/helpers/api.js";
// import { ENVIRONMENT_HELPER } from "@/helpers/environment.js";
import { authHeader } from "@/helpers/auth-header.js";


/* Exports */
// API services that can be invoked on the modules
export const ORDERS_SERVICE = {
    getOrders,
    createOrder,
    createGuestOrder
};

/* Consts*/
// Root service to build API url
const API_ROOT_SERVICE = "Orders"

/* Services */
function getOrders(inputGetOrders)
{
    
    // Build API call.
    var url = API_HELPER.pathJoin([process.env.VUE_APP_API_ROOT, API_ROOT_SERVICE]);

    const requestOptions = {
        method: 'GET',
        headers:  { ...authHeader()},
    };

    // Process API call, validating the token beforehand.
    return API_HELPER.loggedApiCall(url, inputGetOrders, requestOptions);

}

function createOrder(paymentType,info,details, apiKey)
{
    // Build API call.
    var url = API_HELPER.pathJoin([process.env.VUE_APP_API_ROOT, API_ROOT_SERVICE]);
    
    var raw = JSON.stringify({"paymentType": paymentType, "info": info, "details": details, "apiKey": apiKey});
    
    const requestOptions = {
        method: 'POST',
        headers:  { ...authHeader(), "Content-Type":"application/json"},
        body: raw
    };

    // Process API call, validating the token beforehand.
    return API_HELPER.loggedApiCall(url, null, requestOptions);
}

function createGuestOrder(products,paymentType,info,details,apiKey)
{
    // Build API call.
    var url = API_HELPER.pathJoin([process.env.VUE_APP_API_ROOT, API_ROOT_SERVICE, "anonymous"]);
    
    var productsDTO = products.map(function(product) {
        return {
          id: product.id,
          quantity: product.quantity
        };
      });

    var raw = JSON.stringify({"products": productsDTO, "paymentType": paymentType, "info": info, "details": details, "apiKey": apiKey});
    
    const requestOptions = {
        method: 'POST',
        headers:  { ...authHeader(), "Content-Type":"application/json"},
        body: raw
    };

    // Process API call, validating the token beforehand.
    return API_HELPER.apiCall(url, null, requestOptions);
}
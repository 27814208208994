<template>
  <div class="container-card" id="tours-by-season">
    <div class="text-center title">{{ $t("toursBySeason") }}</div>
    <div class="row m-0 p-0">
      <div class="card" v-for="(season, i) in seasons" :key="i" @click="seasonClicked(season)">
        <img :src="season.image" alt="" />
        <div :class="'overlay-'+(i+1)">
          <div class="d-flex align-items-center h-100">
            <img class="icon" :src="season.icon"/>
          </div>
          <p>{{season.title}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default 
{
  data: function () {
    return {
      seasons: [
        { title: this.$t('spring').toUpperCase(), image: require("../assets/DiscoverBySeason/spring.jpg"), icon: require("../assets/DiscoverBySeason/logo_spring.png"), key: "Spring"},
        { title: this.$t('summer').toUpperCase(), image: require("../assets/DiscoverBySeason/summer.jpg"), icon: require("../assets/DiscoverBySeason/logo_summer.png"), key: "Summer"},
        { title: this.$t('autumn').toUpperCase(), image: require("../assets/DiscoverBySeason/autumn.jpg"), icon: require("../assets/DiscoverBySeason/logo_autumn.png"), key: "Autumn"},
        { title: this.$t('winter').toUpperCase(), image: require("../assets/DiscoverBySeason/winter.jpg"), icon: require("../assets/DiscoverBySeason/logo_winter.png"), key: "Winter"},
      ]
    };
  },
  methods:
  {
    seasonClicked: function(season)
    {
      this.$router.push({name: "Experiences", params: {season: {value: season.key}} });
    }
  }
};
</script>

<style scoped>
.container-card {
  width: 85%;
  margin: 50px auto;
}
.title {
  padding: 20px 0;
  color: #962422;
  font-weight: bold;
  font-size: 25px;
}
.row {
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: space-around;
  flex-flow: wrap;
}
.card {
  width: 24%;
  margin: 10px auto;
  transition: 0.3s;
  border: none;
  position: relative;
  cursor: pointer;
  padding: 0;
}
.card span,
p {
  position: absolute;
}
.card p {
  font-size: 28px;
  font-weight: bold;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  top: 70%;
  text-shadow: 0px 0px 10px #000000;
  color: white;
}
.icon {
  width: 130px;
  margin: 0 auto;
}
.overlay-1 {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  transition: 1s ease;
  background-color: hsla(90, 50%, 40%, 0);
}
.overlay-1 * {
  opacity: 1;
}
.overlay-2 {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  transition: 1s ease;
  background-color: hsla(55, 90%, 51%, 0);
}
.overlay-2 * {
  opacity: 1;
}
.overlay-3 {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  transition: 1s ease;
  background-color: hsla(31, 62%, 45%, 0);
}
.overlay-3 * {
  opacity: 1;
}
.overlay-4 {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  transition: 1s ease;
  background-color: hsla(186, 15%, 42%, 0);
}
.overlay-4 * {
  opacity: 1;
}
.icon-winter:before {
  content: "\e910";
  color: #fff;
  font-size: 320px;
}
@media screen and (max-width: 1600px) {
  .card {
    width: 32%;
  }
}
@media screen and (max-width: 1200px) {
  .card {
    width: 49%;
    margin: 5px auto;
  }
}
@media screen and (min-width: 991px) 
{
  .card:hover .overlay-1 {
    background-color: hsla(90, 50%, 40%, 0.4);
  }
  .card:hover .overlay-2 {
    background-color: hsla(55, 90%, 51%, 0.3);
  }
  .card:hover .overlay-3 {
    background-color: hsla(31, 62%, 45%, 0.4);
  }
  .card:hover .overlay-4 {
    background-color: hsla(186, 15%, 42%, 0.4);
  }
}
@media screen and (max-width: 768px) {
  .card {
    width: 100%;
  }
  .container-card{
    width: unset;
    padding: 0 83.5px;
  }
}
@media screen and (max-width: 576px) {
  .container-card{
    width: 95%;
    padding: 0;
  }
}
</style>

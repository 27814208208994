<template>
<section>
  <LogoLoading v-if="!currentPage"/>
  <div v-else>
    <div v-if="bannerImage != null" class="d-flex justify-content-center">
      <img :src="bannerImage" class="img-fluid" alt="">
    </div>
    <div class="img-background">
      <b-tabs content-class="mt-3 d-flex justify-content-center" v-model="currentTab">
        <b-tab v-for="tab in tabs" :key="tab.id" :title="tab.title">
          <div v-html="tab.content" class="container mt-5 w-75"></div> 
          <div v-if="footerImage != null" class="container my-5 w-75">
            <img :src="footerImage" class="img-fluid" alt="">
          </div> 
        </b-tab>
      </b-tabs>
    </div>
  </div>
</section>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import InputGetList from "@/classes/InputGetList";
import router from '@/router';
import LogoLoading from "@/components/LogoLoading.vue"
import { UTILS_HELPER } from "@/helpers/utils.js";
import { API_HELPER } from "@/helpers/api.js";

export default {
   metaInfo () {
    var result = 
    { 
      title: this.currentPage ? `${this.currentPage.title} - Wild Douro` : "Wild Douro", 
      htmlAttrs: {
        lang: this.$i18n.locale,
      },
      meta: 
      [
        {name: 'description', content: this.currentPage && this.currentPage.tabs && this.currentPage.tabs.length ? UTILS_HELPER.htmlToText(this.currentPage.tabs[0].content) : this.$t("intro")},
        {name: 'keywords', content: this.$t("vueRoutes.defaultKeywords")},
        // // Twitter Card
        // {name: 'twitter:card', content: "summary"},
        // {name: 'twitter:title', content: this.currentPage ? `${this.currentPage.title} - Wild Douro` : "Wild Douro"},
        // {name: 'twitter:description', content: this.currentPage && this.currentPage.tabs && this.currentPage.tabs.length ? UTILS_HELPER.htmlToText(this.currentPage.tabs[0].content) : this.$t("intro")},
        // {name: 'twitter:image', content: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, "assets/hero/hero.jpg"]) },
        // //Facebook OpenGraph
        // {property: 'og:title', content: this.currentPage ? `${this.currentPage.title} - Wild Douro` : "Wild Douro"},
        // {property: 'og:site_name', content: 'Wild Douro'},
        // {property: 'og:type', content: 'website'},
        // {property: 'og:image', content: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, "assets/hero/hero.jpg"]) },
        // {property: 'og:description', content: this.currentPage && this.currentPage.tabs && this.currentPage.tabs.length ? UTILS_HELPER.htmlToText(this.currentPage.tabs[0].content) : this.$t("intro")},
      ], 
    };

    if(this.$route.params.sku)
    {
      result.link = 
      [
        {rel: 'alternate', hreflang: 'en', href: `${API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.$t("vueRoutes.staticPage", 'en')])}`},
        {rel: 'alternate', hreflang: 'pt', href: `${API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.$t("vueRoutes.staticPage", 'pt')])}`},
      ];
    }

    return result; 
  }, 
  components: {
    LogoLoading
  },
  data: function () {
    return {
      currentTab: 0,
      // currentPage: null,
    };
  },
  computed:
  {
    ...mapState('staticPages', ['listOutput']),
    pagesList: function() {
      return this.listOutput && this.listOutput.data ? this.listOutput.data : [];
    },
    currentPageSku: function()
    {
      return this.pagesList.length ? this.pagesList.find(page => page.sku == this.$route.params.sku) : null;
    },
    currentPage: function()
    {
      return this.pagesList.length ? this.pagesList.find(page => page.sku == this.$route.params.sku) : null;
    },
    tabs: function()
    {
      return this.currentPage && this.currentPage.tabs ? this.currentPage.tabs : [];
    },
    bannerImage: function()
    {
      return this.tabs[this.currentTab].bannerImage ? API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.tabs[this.currentTab].bannerImage]) : null;
    },
    footerImage: function()
    {
      return this.tabs[this.currentTab].footerImage ? API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.tabs[this.currentTab].footerImage]) : null;
    },
  },
  methods:
  {
    ...mapActions('staticPages', ['getList']),
    initializePage()
    {
      if(!this.$route.params.sku)
      {
        router.push({ path: '/' });
      }
      
        const itemsPerPage = 999;
        const currentPage = 1;
        const searchText = '';
        const sorter = 1; // desc
        const propertyName = "id";

        var input = new InputGetList(itemsPerPage, currentPage, searchText, sorter, propertyName);
        
        // List specific parameters
        input.visible= true;
        this.getList(input);
      
    }
  },
  mounted() 
  {
    this.initializePage();
    // this.currentPage= this.pagesList.find(page => page.id == this.$route.query.id)
  },
  watch: {
    $route(to) {
      if(to.name)
      {
        this.initializePage();
      }
    },
    // pagesList: function()
    // {
    //   this.currentPage= this.pagesList.find(page => page.id == this.$route.query.id)
    // },
  }
}
</script>

<style scoped>
>>>a{
  color:#495057;
}
>>>.nav {
  background-color: #B3B3B3;
  line-height: 50px;
  display: flex;
  justify-content: space-evenly;
}
>>>.nav-tabs {
  border: none;
}

>>>.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link{
  border: none;
  background-color: transparent;
  color: #000;
  font-weight: bold;
}

>>>.nav-tabs .nav-link:hover, .nav-tabs {
  border:none;
  color: #000;
}
>>>.nav-tabs .nav-link {
  margin-bottom: -1px;
  background: 0 0;
  border: none;
  border-top-left-radius: none;
  border-top-right-radius: none;
}
.img-background{
  background-image: url("../assets/Faq/faq-background.png");
  background-repeat: no-repeat;
  min-height: 100vh;
}
.img-fluid{
  width: 100%;
}
>>>.ql-align-center{
  text-align: center!important;
}

/* Content */
/* p 
{
  display: flex;
} */

@media (min-width: 992px) 
{
  >>> p.ql-align-center
  {
    display: flex;
    gap: 1%;
  }

  >>>p > strong
  {
    width: 49.5%;
    text-align: right;
  }

  >>>p > strong + span
  {
    width: 49.5%;
    text-align: left;
  }
}

@media (max-width: 991px) 
{
  >>> p.ql-align-center
  {
    display: flex;
    flex-direction: column;
  }
}
</style>
/* Imports */
import { API_HELPER } from "@/helpers/api.js";
import { authHeader } from "@/helpers/auth-header.js";

/* Exports */
// API services that can be invoked on the modules
export const INSPIRATIONS_SERVICE = {
    list,
    listNights,
    get,
    listDays,
    listConditions
};

/* Consts*/
// Root service to build API url
const API_ROOT_SERVICE = "Experiences";

/* Services */
function list(queryParams)
{
    // Build API call.
    var url = API_HELPER.pathJoin([process.env.VUE_APP_API_ROOT, API_ROOT_SERVICE]);    
    
    // let filter = ['seasons', 'locations', 'nights', 'category']
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader() }
    }; 
    
    // Process API call, validating the token beforehand.
    return API_HELPER.apiCall(url, queryParams, requestOptions);
}

function listNights()
{
    // Build API call.
    var url = API_HELPER.pathJoin([process.env.VUE_APP_API_ROOT, API_ROOT_SERVICE, 'nights']);    
    
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader() }
    }; 
    
    // Process API call, validating the token beforehand.
    return API_HELPER.apiCall(url, null, requestOptions);
}

function get(sku)
{
    // Build API call.
    var url = API_HELPER.pathJoin([process.env.VUE_APP_API_ROOT, API_ROOT_SERVICE, "SKU", sku]);    
    
    const requestOptions = {
        method: 'GET'
    }; 
    
    // Process API call, validating the token beforehand.
    return API_HELPER.apiCall(url, null, requestOptions);
}

function listDays(id)
{
    // Build API call.
    var url = API_HELPER.pathJoin([process.env.VUE_APP_API_ROOT, API_ROOT_SERVICE, 'days', id]);    
    
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader() }
    }; 
    
    // Process API call, validating the token beforehand.
    return API_HELPER.apiCall(url, null, requestOptions);
}
function listConditions(id)
{
    // Build API call.
    var url = API_HELPER.pathJoin([process.env.VUE_APP_API_ROOT, API_ROOT_SERVICE, 'conditions', id]);    
    
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader() }
    }; 
    
    // Process API call, validating the token beforehand.
    return API_HELPER.apiCall(url, null, requestOptions);
}
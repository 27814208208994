<template>
    <section v-if="!loading">
        <!-- small devices -->
        <div class="d-flex flex-column align-items-center d-lg-none"> 
            <div class="row">
                <div class="col-12">
                    <h1>{{$t("blog")}}</h1>
                </div>
            </div>
            <BlogSelect class="cursor-pointer mt-1 mb-1" :options="categoryOptions" :placeholder=" $t('blogMenu.categorie')" v-model="selectCategory"></BlogSelect>
            <BlogSelect class="cursor-pointer mt-1 mb-1" :options="datesOptions" :placeholder=" $t('blogMenu.date')" v-model="selectDate"></BlogSelect>
            <BlogSelect class="cursor-pointer mt-1 mb-1" :options="authorsOptions" :placeholder=" $t('blogMenu.author')" v-model="selectAuthor"></BlogSelect>       
        </div>
        <!-- large devices -->
        <div class="d-none d-lg-block">
            <div class="row">
                <div class="col-12">
                    <h1>{{$t("blog")}}</h1>
                </div>
            </div>
            <div v-for="(firstLevel, i) in blogMenus" :key="i">
                <div class="row">
                    <div class="col-12 p-0">
                        <button href="/" class="btn d-flex align-items-center" @click="selectedFirstLevel =  selectedFirstLevel === firstLevel ? null : firstLevel">
                            <span class="font-weight-bold">{{firstLevel.title}}</span>
                            <span v-bind:class="{ 'rotated': selectedFirstLevel == firstLevel }" class="font-weight-bold icon-slot_arrow_right"></span>
                        </button>
                        <div v-show="selectedFirstLevel == firstLevel" class="row menu-block">
                            <div v-for="(secondLevel, i) in firstLevel.options" :key="i">
                                <p class="cursor-pointer word-break" v-bind:class="{'selected': selectedSecondLevel == secondLevel}" @click="optionClicked(firstLevel, secondLevel)">{{secondLevel.name ? secondLevel.name : secondLevel}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { mapState, mapActions } from "vuex";
import InputGetList from "@/classes/InputGetList";
import BlogSelect from "@/components/BlogSelect.vue"

export default {
    components: {
        BlogSelect
    },
    data: function () {
        return {
            selectedFirstLevel: null,
            selectedSecondLevel: null,
            selectCategory: null,
            selectDate: null,
            selectAuthor: null
        }
    },
    computed:
    {    
        ...mapState({ 
            categoriesListOutput: state => state.categories.listOutput,
            datesListOutput: state => state.posts.datesOutput,
            authorsListOutput: state => state.members.listOutput,
        }),
        loading: function(){
            return this.categoriesListOutput == null || this.datesListOutput == null || this.authorsListOutput == null;
        },
        categoriesList: function() {
            return this.categoriesListOutput && this.categoriesListOutput.data ? this.categoriesListOutput.data : [];
        },
        datesList: function()
        {
            return this.datesListOutput && this.datesListOutput.data ? this.datesListOutput.data : [];
        },
        authorsList: function() {
            return this.authorsListOutput && this.authorsListOutput.data ? this.authorsListOutput.data : [];
        },
        categoryOptions: function() {
            var result = [];

            if(this.categoriesList && this.categoriesList.length)
            {          
                this.categoriesList.forEach(function(category){
                    result.push({value: category, name: category.name});
                });
            }
            return result;
        },
        datesOptions: function() {
            var result = [];

            if(this.datesList && this.datesList.length)
            {
                this.datesList.forEach(function(date){
                    result.push({value: date, name: date});
                });
            }
            return result;
        },
        authorsOptions: function() {
            var result = [];

            if(this.authorsList && this.authorsList.length)
            {    
                this.authorsList.forEach(function(author){
                    result.push({value: author.id, name: author.name});
                });
            }
            return result;
        },
        blogMenus: function()
        {
            var result =         
            [
                { 
                    type: "category",
                    title: this.$t("blogMenu.categorie"), 
                    options: this.categoriesList
                },
                { 
                    type: "date",
                    title: this.$t("blogMenu.date"), 
                    options: this.datesList
                },  
                { 
                    type: "author",
                    title: this.$t("blogMenu.author"), 
                    options: this.authorsList
                },  
            ];

            return result;
        },
        
    },
    mounted: function()
    {
        // Initialize data with route params
        if(this.$route.params.categoryId)
        {
            this.selectedFirstLevel = this.blogMenus.find(menu => menu.type == "category");
            this.selectedSecondLevel = this.selectedFirstLevel.options.find(category => category.id == this.$route.params.categoryId);
        } 
        else if(this.$route.params.date)
        {
            this.selectedFirstLevel = this.blogMenus.find(menu => menu.type == "date");
            this.selectedSecondLevel = this.selectedFirstLevel.options.find(date => date == this.$route.params.date);
        } 
        else if(this.$route.params.authorId)
        {
            this.selectedFirstLevel = this.blogMenus.find(menu => menu.type == "author");
            this.selectedSecondLevel = this.selectedFirstLevel.options.find(author => author.id == this.$route.params.authorId);
        } 

        // Get info from API
        this.invokeGetCategories();

        if(!this.authorsList.length)
        {
            this.invokeGetAuthors();
        }

        if(!this.datesList.length)
        {
            this.getDates();
        }
    },
    methods:
    {
        ...mapActions({
            getCategories: 'categories/getList',
            getDates: 'posts/getDates',
            getAuthors: 'members/getList'
        }),
        invokeGetCategories()
        {
            const itemsPerPage = 999;
            const currentPage = 1;
            const searchText = '';
            const sorter = 2; // desc
            const propertyName = "Name";

            var input = new InputGetList(itemsPerPage, currentPage, searchText, sorter, propertyName);
            // List specific parameters
            input.visible = true;
            input.type = "Post";

            this.getCategories(input);
        },
        invokeGetAuthors()
        {
            const itemsPerPage = 999;
            const currentPage = 1;
            const searchText = '';
            const sorter = 2; // desc
            const propertyName = "Name";

            var input = new InputGetList(itemsPerPage, currentPage, searchText, sorter, propertyName);
            // List specific parameters
            input.visible=true;

            this.getAuthors(input);
        },
        optionClicked: function(firstLevel, secondLevel)
        {
            this.selectedSecondLevel = secondLevel;

            switch(firstLevel.type)
            {
                case "category":
                {
                    this.$emit("filter-category-requested", secondLevel);
                    break;
                }
                case "date":
                {
                    this.$emit("filter-date-requested", secondLevel);
                    break;
                }
                case "author":
                {
                    this.$emit("filter-author-requested", secondLevel);
                    break;
                }
            }
        },
    },
    watch: {
        selectCategory(val)
        {
            if(val && val.value)
            {
                this.$emit("filter-category-requested", val.value)
            }
        },
        selectDate(val)
        {
            if(val && val.value)
            {
                this.$emit("filter-date-requested", val.value);
            }
        },
        selectAuthor(val)
        {
            if(val)
            {
                this.$emit("filter-author-requested", val)
            }
        }
    },
}
</script>

<style scoped>
/* Structure */
.menu-block
{
    margin: 0 0.2rem;
}
.word-break{
    word-break: break-word;
}
/* Text elements */
a,
button
{
    color: #962422;
    text-decoration: none;
}
button
{
    border-style: outset !important;
}
a:hover,
button:hover
{
    color: #962422;
    opacity: 0.5;
}
h1{
    font-weight: bold;
    color: #962422;
    font-size: 50px;
}
@media screen and (max-width: 576px) {
  h1 {
    font-size: 40px;
  }
}
p.selected
{
    text-decoration: underline;
    /* margin: 0.5rem 0!important; */
}

/* Others */
.icon-slot_arrow_right{
    font-size: 29px;
    margin-left: 0.5rem;
}

.rotated {
  transform: rotate(90deg); /* Equal to rotateZ(45deg) */
}
</style>
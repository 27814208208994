<template>
    <div class="no-results d-flex flex-column justify-content-center align-items-center p-5 w-100">
        <img class="no-results-img my-2" src="../assets/logo.svg"/>
        <span class="my-2">{{$t("errorPage.text")}}</span>
        <button class="btn" @click="goHome">{{$t("errorPage.homePage")}}</button>
    </div>
</template>

<script>
import { API_HELPER } from "@/helpers/api.js";

export default {
        metaInfo () {
      return { 
            title: `${this.$t("vueRoutes.errorPageTitle")} - Wild Douro`, 
            htmlAttrs: {
                lang: this.$i18n.locale,
            },
            meta: 
            [
                {name: 'description', content: this.$t("vueRoutes.errorPageDescription")},
            ],
            link: [
                {rel: 'alternate', hreflang: 'en', href: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.$t("vueRoutes.errorPage", 'en')])},
                {rel: 'alternate', hreflang: 'pt', href: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.$t("vueRoutes.errorPage", 'pt')])},
            ]
        } 
    },
    methods: 
    {
        goHome: function()
        {
            this.$router.push("/");
        }
    }
}
</script>

<style scoped>
.no-results
{
    min-height: calc(100vh - 100px);
}

.no-results-img
{
    width: 30%;
    min-width: 200px;
}

.btn {
  background: #962422;
  color: white;
  padding: 5px 100px;
  font-size: 20px;
}
.btn:hover {
  color: white;
}
</style>
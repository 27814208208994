<template>
  <section>
        <div class="root d-flex justify-content-center align-items-center">
            <div class="action position-relative">
            </div>
        </div>
  </section>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { API_HELPER } from "@/helpers/api.js";

export default {
       metaInfo () {
      return { 
            title: `${this.$t("vueRoutes.confirmAccountTitle")} - Wild Douro`, 
            htmlAttrs: {
                lang: this.$i18n.locale,
            },
            link: [
                {rel: 'alternate', hreflang: 'en', href: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.$t("vueRoutes.confirmAccount", 'en')])},
                {rel: 'alternate', hreflang: 'pt', href: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.$t("vueRoutes.confirmAccount", 'pt')])},
            ]
        } 
    },
     data: function () {
        return {
            username:"",
            token:""
        }
     }, 
    created: function (){
        this.username = this.$route.query.username;
        this.token = this.$route.query.token;
        this.confirmationAccount();
    },
     methods: {
        ...mapActions('account', ['confirmAccount']),
        ...mapActions({
            clearAlert: 'alert/clear' 
        }),
        confirmationAccount() {
            this.clearAlert();
            this.submitted = true;
            this.loading = true;
            
            const { username, token } = this;
            this.confirmAccount({  username, token })  
        },
    },
      computed: 
    {
        ...mapState('account', ['processConfirm'])
    },
    watch:
    {
        processConfirm: function(val)
        {
            const self = this;

            if(val != null)
            {
                this.loading = false;

                if(val)
                {
                    this.$emit("success-alert-requested", { message: this.$t("confirmAcc"), primaryCallback: () => { self.$router.push("/Login"); }});
                }
                else
                {
                    this.$emit("error-alert-requested", { message: this.$t("errorAcc"), primaryCallback: () => { self.$router.push("/"); }});
                }
            }         
        },
    }
     
}
</script>

<style scoped>
.root
{
    background-color: #a3161c;
    height: 100%;
}

.action
{
    width: 25vw;
    min-width: 500px;
    min-height: 500px;
}

.action-selectors,
form
{
    z-index: 999;
}

.action-button
{
    width: 50px;
}

.action-text
{
    color: white;
}

.action-background
{
    background-repeat: no-repeat;
    background-size: cover;
    top: 0;
    left: 0;
    right:0;
    bottom: 0;
}

form label,
form span
{
    color: white;
}

.form-group
{
    margin-top: 0.5rem;
}

.action-info
{
    font-size: 12px;
}

.submit-button
{
    background-color: #962422;
    border: 4px solid white;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
}

#login-form .submit-button,
#recover-form .submit-button
{
    margin: 2rem 0;
}
@media (max-width: 500px) {
  .root{
      height: unset ;
  }
  .action{
      min-width: unset;
      min-height: unset;
      width: unset;
  }
  .action-background{
      background: none !important;
  }
  .form{
      margin-top: 0;
  }
}
</style>
/* Imports */
import { API_HELPER } from "@/helpers/api.js";
import { authHeader } from "@/helpers/auth-header.js";

/* Exports */
// API services that can be invoked on the modules
export const FAVORITES_SERVICE = {
    list,
    add,
    remove
};

/* Consts*/
// Root service to build API url
const API_ROOT_SERVICE = "Favorites";

/* Services */
function list(queryParams)
{
    // Build API call.
    var url = API_HELPER.pathJoin([process.env.VUE_APP_API_ROOT, API_ROOT_SERVICE]);    

    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader() }
    }; 

    // Process API call, validating the token beforehand.
    return API_HELPER.loggedApiCall(url, queryParams, requestOptions);
}

function add(ids) {
    
    // Build API call.
    var url = API_HELPER.pathJoin([process.env.VUE_APP_API_ROOT, API_ROOT_SERVICE]);  

    // Body must be the same as the api
    var object = {"productsId": ids};

    var raw = JSON.stringify(object);
    const requestOptions = {
        method: 'POST',
        headers: {...authHeader(), "Content-Type":"application/json" },
        body: raw,
    };

    // Process API call, validating the token beforehand.
    return API_HELPER.loggedApiCall(url, null, requestOptions);    
}

function remove(id) {
    // Build API call.
    var url = API_HELPER.pathJoin([process.env.VUE_APP_API_ROOT, API_ROOT_SERVICE, id]); 

    const requestOptions = {
        method: 'DELETE',
        headers: {...authHeader() }
    };

    // Process API call, validating the token beforehand.
    return API_HELPER.loggedApiCall(url, null, requestOptions);
}
<template>
  <div class="container-fluid">
    <!-- Filters -->
    <div :class="{'disabled': loading}" class="d-flex flex-wrap justify-content-between align-items-center py-5 px-6">
      <div class="col-12 col-md-4" id="category">
        <UniSelect v-show="categoryOptions.length" :options="categoryOptions" v-model="selectedCategory"></UniSelect>
      </div>
      <div class="row col-md-6 align-items-center m-0" id="filters">
        <div class="col-12 col-md-3 p-l-1 text-end" id="text-position">
          <span class="primary-color w-100">{{ $t("experience.filter") }}</span>
        </div>
        <!-- commented because experiences now will work like services that the company provide -->
        <!-- <div class="col-12 col-sm-4 col-md-3 p-l-1 h-30 multi-select">
          <MultiSelect class="cursor-pointer" :options="nightsOptions" :placeholder=" $t('experience.night')" v-model="selectedNights"></MultiSelect>
        </div> -->
        <div class="col-12 col-sm-4 col-md-3 p-l-1 h-30 multi-select">
           <MultiSelect class="cursor-pointer" :options="locationOptions" :placeholder=" $t('experience.location')" v-model="selectedLocations"></MultiSelect>
        </div>
        <div class="col-12 col-sm-4 col-md-3 p-l-1 h-30 multi-select">
          <MultiSelect class="cursor-pointer" :options="seasonOptions" :placeholder=" $t('experience.seasons')" v-model="selectedSeasons"></MultiSelect>
        </div>
      </div>
      <!-- Filters information -->
      <!-- commented because experiences now will work like services that the company provide -->
      <!-- <div id="filter-infos" class="col-12 d-flex mt-2 px-2" v-if="hasDefaultFilters && filtersChanged !== true">
        <span class="fst-italic text-underlined cursor-pointer" v-if="filtersChanged" @click="saveFilters">{{$t("experience.saveFilters")}}</span>
        <span class="fst-italic" v-else>{{$t("experience.defaultFilters")}}</span>
      </div> -->
    </div>
    <!-- Loading -->
    <div v-if="loading" class="inspiration-container d-flex flex-wrap mt-2 px-6">
      <SkeletonLoading v-for="(block, index) in (new Array(itemsPerPage))" :key="index"></SkeletonLoading>
    </div>
    <!-- Content -->
    <div v-else class="inspiration-container d-flex flex-wrap mt-2 px-6">
      <InspirationThumb v-for="inspiration in inspirations" :key="inspiration.id" :inspiration="inspiration" @warning-alert="deleteItem(inspiration)" @success-alert-requested="onSuccessAlert"></InspirationThumb>
      <NoResultsPlaceholder v-if="!inspirations.length"></NoResultsPlaceholder>
    </div>
    <div v-show="!loading && canLoadMore" class="see">
      <span @click="loadMore" type="button" class="icon-seeMore"></span>
      <p class="">{{ $t("voucher.seeMore") }}</p>
    </div>
  </div>
</template>


<script>
import MultiSelect from "@/components/MultiSelect.vue"
import UniSelect from "@/components/UniSelect.vue"
import InspirationThumb from "@/components/InspirationThumb.vue"
import { mapState, mapActions } from "vuex";
import InputGetList from "@/classes/InputGetList";
import SkeletonLoading from "@/components/SkeletonLoading.vue"
import NoResultsPlaceholder from "@/components/NoResultsPlaceholder.vue"
import store from '@/store/index.js'
import { API_HELPER } from "@/helpers/api.js";

const DEFAULT_ITEMS_PER_PAGE = 12;
const TOURISM_TYPE_ID = -1; // Used to differentiate from categories

export default {
  metaInfo () {
      return { 
            title: `${this.$t("vueRoutes.inspirationsTitle")} - Wild Douro`, 
            htmlAttrs: {
                lang: this.$i18n.locale,
            },
            meta: 
            [
                {name: 'description', content: this.$t("vueRoutes.intro")},
                {name: 'keywords', content: this.$t("vueRoutes.defaultKeywords")},

                // // Twitter Card
                // {name: 'twitter:card', content: "summary"},
                // {name: 'twitter:title', content: `${this.$t("vueRoutes.inspirationsTitle")} - Wild Douro`},
                // {name: 'twitter:description', content: this.$t("vueRoutes.intro")},
                // {name: 'twitter:image', content: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, "assets/hero/hero.jpg"]) },

                // //Facebook OpenGraph
                // {property: 'og:title', content: `${this.$t("vueRoutes.inspirationsTitle")} - Wild Douro`},
                // {property: 'og:site_name', content: 'Wild Douro'},
                // {property: 'og:type', content: 'website'},
                // {property: 'og:image', content: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, "assets/hero/hero.jpg"]) },
                // {property: 'og:description', content: this.$t("vueRoutes.intro")}
            ], 
            link: [
                {rel: 'alternate', hreflang: 'en', href: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.$t("vueRoutes.inspirations", 'en')])},
                {rel: 'alternate', hreflang: 'pt', href: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.$t("vueRoutes.inspirations", 'pt')])},
            ]
            
        } 
    },
  components: {
    MultiSelect,
    UniSelect,
    InspirationThumb,
    SkeletonLoading,
    NoResultsPlaceholder
  },
  data() {
    return {
      itemsPerPage: DEFAULT_ITEMS_PER_PAGE,
      selectedCategory: undefined, // This is set from the params after loading categories; note that this can be a category or tourism type
      selectedNights: [],
      selectedLocations: [],
      selectedSeasons:  [], 
      // -------------------------------------
      // commented because experiences now will work like services that the company provide
      // selectedLocations: store.state.account.user && store.state.account.user.data && store.state.account.user.data.questions && store.state.account.user.data.questions.length >= 2 ? store.state.account.user.data.questions[1].answer.split(', ').map(x=> ({value: x})) : [], // store.state.user is used so that the info is accessible on data
      // selectedSeasons:  this.$route.params.season ? [this.$route.params.season] : (store.state.account.user && store.state.account.user.data && store.state.account.user.data.questions && store.state.account.user.data.questions.length >= 3 ? [{value: store.state.account.user.data.questions[2].answer}] : []), // store.state.user is used so that the info is accessible on data
      // -------------------------------------
      seasonOptions: [{name: this.$t("spring"), value: "Spring"}, {name: this.$t("summer"), value: "Summer"}, {name: this.$t("autumn"), value: "Autumn"}, {name: this.$t("winter"), value: "Winter"}],
      locationOptions: [{name: this.$t("north"), value: "North"}, {name: this.$t("center"), value: "Center"}, {name: this.$t("lisbon"), value: "Lisbon"}, {name: this.$t("alentejo"), value: "Alentejo"}, {name: this.$t("algarve"), value: "Algarve"}, {name: this.$t("azores"), value: "Azore"}, {name: this.$t("madeira"), value: "Madeira"}],
      filtersChanged: false
    };
  },
  methods: {
    ...mapActions({
      getInspirationsList: 'inspirations/getList',
      getCategoriesList: 'categories/getList'
    }),
    ...mapActions("inspirations", ["getNights"]),
    ...mapActions('favorites', ['removeFavorite']),
    ...mapActions('account', ['submitQuiz']),
    updateItemsRequested()
    {
      // GET list of inspirations
      const currentPage = 1;
      const searchText = '';
      const sorter = 2; // desc
      const propertyName = "id";
      
      //Make request
      var input = new InputGetList(this.itemsPerPage, currentPage, searchText, sorter, propertyName);
      
      // List specific parameters
      input.visible= true;

      if(this.selectedCategory)
      {       
        // commented because experiences now will work like services that the company provide
        if(this.selectedCategory == TOURISM_TYPE_ID)
        {
          console.log("caiu no if", this.selectedCategory)
          input.tourismType = this.tourismType;
          input.tourismTypeMode = "ExcludeOther";
        }
        else
        {
          input.category = this.selectedCategory;
        }
      }
      
      if(this.selectedSeasons.length){
        input.seasons = this.selectedSeasons.map(season => season.value);
      }

      if(this.selectedNights.length){
        input.nights = this.selectedNights.map(night => night.value);
      }

      if(this.selectedLocations.length){
        input.locations = this.selectedLocations.map(location => location.value);
      }

      this.getInspirationsList(input); 
    },
    loadMore()
    {
      if(this.canLoadMore)
      {
        this.itemsPerPage += DEFAULT_ITEMS_PER_PAGE; 
      }
    },
    onSuccessAlert: function(val)
    {
      if(val == 'add')
      {
        this.$emit('success-alert-requested', {message: this.$t("favorites.added")});
      }
    },
    deleteItem(item)
    {
      const self = this;
      this.$emit("warning-alert-requested", { message: this.$t("favorites.removedWarning"), primaryCallback: () =>
      {
        self.removeFavorite(item);
      }, primaryButton: "Yes", secondaryButton: "No"});
    },
    saveFilters()
    {
      this.filtersChanged = true;

      const quiz = store.state.account.user && store.state.account.user.data && store.state.account.user.data.questions && store.state.account.user.data.questions.length >= 3 ? store.state.account.user.data.questions : null;

      if(quiz)
      {
        quiz[1].answer = this.selectedLocations ? this.selectedLocations.map(x=> x.value).join(", ") : null;
        quiz[2].answer = this.selectedSeasons && this.selectedSeasons.length ? this.selectedSeasons[0].value : null;

        this.submitQuiz(quiz);
      }
    }
  },
  computed: {
    ...mapState('account', ['user']),
    userInfo: function()
    {
      return this.user ? this.user.data : null;
    },
    hasDefaultFilters: function()
    {
      return this.userInfo && this.userInfo.questions && this.userInfo.questions.length;
    },
    tourismType: function()
    {
      return this.userInfo && this.userInfo.questions && this.userInfo.questions.length >= 3 ? this.userInfo.questions[3].answer : null;
    },
    ...mapState({ 
      inspirationsOutput: state => state.inspirations.listOutput,
      categoriesOutput: state => state.categories.listOutput
    }),
    ...mapState('inspirations', ['nightsOutput']),
    loading: function(){
      return this.inspirationsOutput == null || this.categoriesOutput == null || this.nightsOutput == null;
    },
    inspirations: function() {
      return this.inspirationsOutput && this.inspirationsOutput.data ? this.inspirationsOutput.data : null;
    },
    numInspirations: function(){
      return this.inspirationsOutput && this.inspirationsOutput.totalRecords ? this.inspirationsOutput.totalRecords : 0;
    },
    canLoadMore: function()
    {
      return this.numInspirations > this.itemsPerPage;
    },
    //
    nights: function() {
      return this.nightsOutput && this.nightsOutput.data ? this.nightsOutput.data : [];
    },
    nightsOptions: function() {
      var result = [];

      if(this.nights && this.nights.length)
      {
        var self = this;
        this.nights.forEach(function(night){
          var name = self.$tc("dayNightCount", night, {nights: night-1, days: night});
          result.push({value: night, name: name});
        });
      }
      return result;
    },
    // 
    categories: function()
    {
      return this.categoriesOutput && this.categoriesOutput.data ? this.categoriesOutput.data : null;
    },
    categoryOptions: function() {
      var result = [];
      if(this.categories && this.categories.length)
      {
        // commented because experiences now will work like services that the company provide
        // Tourism type
        if(this.$route.query.tourismType)
        {
          result.push({value: TOURISM_TYPE_ID, name: this.$t(`quiz.${this.tourismType}Profile`) })
        }
        else
        {
          result.push({value: null, name: this.$t("chooseACategory") })
        }

        // Inspiration categories
        this.categories.forEach(function(category){
          if(category.type == "Experience"){
            result.push({value: category.id, name: category.name});
          }
        });
      }
      return result;
    },
  },
  mounted () 
  {
    // GET list of nights
    this.getNights();

    // GET list of categories
    const itemsPerPage = 999;
    const currentPage = 1;
    const searchText = '';
    const sorter = 1; // asc
    const propertyName = "Name";
    var input = new InputGetList(itemsPerPage, currentPage, searchText, sorter, propertyName);
    input.visible = true;
    this.getCategoriesList(input);

    // List of inspirations is obtained after getting the categories
  },
  watch:
  {
    itemsPerPage()
    {
      this.updateItemsRequested();
    },
    selectedSeasons() 
    {
      this.filtersChanged = 'pending';
      this.updateItemsRequested();
    },
    selectedNights() 
    {
      this.updateItemsRequested();
    },
    selectedLocations() 
    {
      this.filtersChanged = 'pending';
      this.updateItemsRequested();
    },
    selectedCategory()
    {
        this.updateItemsRequested();
    },
    categories(val)
    {
      if(val && val.length)
      {
        this.selectedCategory = this.$route.query.tourismType ? this.$route.params.tourismType : null;
      }
    }
  }
};
</script>

<style scoped>
.icon-seeMore:before {
  cursor: pointer;
  font-size: 200px;
}
.see {
  position: relative;
  display: flex;
  justify-content: center;
}
.see > p {
  position: absolute;
  bottom: 25%;
  color: #962422;
}

.skeleton-box
{
  min-height: 450px;
}

.fst-italic 
{
  font-size: 14px;
}
/* Size of inspiration elements: these are not done using bootstrap to have correct alignments, using gap */
.inspiration-container /* Four columns */
{
  gap: 2.3%;
}
.inspiration-container > div,
.inspiration-container > span
{
  /* width: 400px; */
  width: 23.27%;
  margin-bottom: 2%;
}
#filters{
  justify-content: flex-end;
}
@media (max-width: 1550px) 
{
  #category{
    width: 30%;
  }
  #filters{
    width: 70%;
  }
  .inspiration-container
  {
    gap: 5%;
  }

  .inspiration-container > div,
  .inspiration-container > span
  {
    width: 30%;
    margin-bottom: 5%;
  }
}
@media (max-width: 1199px) 
{
  #category{
    width: 100%;
    padding: 10px 0;
  }
  #filters{
    width: 100%;
    padding: 10px 0;
    justify-content: flex-start;
  }
  #text-position{
    text-align: start!important;
  }
}
@media (min-width: 993px) 
{
  #filter-infos
  {
    justify-content: flex-end;
    margin-left: 1rem;
  }
}
@media (max-width: 1020px)
{
  .inspiration-container 
  {
    justify-content: center!important;
  }
  .inspiration-container
  {
    gap: 5%;
  }
  .inspiration-container > div,
  .inspiration-container > span
  {
    width: 45%;
    margin-bottom: 5%;
  }
}
@media (max-width: 840px)
{
  .p-l-1{
    padding: 0 10px!important;
  }
  .px-6{
    padding-right: 0!important;
    padding-left: 0!important;
  }
}
@media (max-width: 767px) /* Two columns */
{
  #text-position{
    margin-bottom: 10px!important;
  }
  .p-l-1{
    margin: 10px auto;
  }
}
@media (max-width: 576px) /* one column */
{
  .inspiration-container > div,
  .inspiration-container > span
  {
    width: 100%;
    margin-bottom: 5%;
  }
  #text-position{
    padding: 0px!important;
  }
  .multi-select{
    padding: 0px!important;
  }
  .py-5{
    padding-top: 0!important;
    padding-bottom: 0!important;
  }
}

.disabled
{
  opacity: 0.9;
}
</style>

/* Imports */
import { API_HELPER } from "@/helpers/api.js";
import { authHeader } from "@/helpers/auth-header.js";

/* Exports */
// API services that can be invoked on the modules
export const MESSAGES_SERVICE = {
    send,
    enquire
};

/* Consts*/
// Root service to build API url
const API_ROOT_SERVICE = "Messages";

/* Services */
function send(message) {
    
    // Build API call.
    var url = API_HELPER.pathJoin([process.env.VUE_APP_API_ROOT, API_ROOT_SERVICE]);  

    var raw = JSON.stringify(message);
    
    const requestOptions = {
        method: 'POST',
        headers: {...authHeader(), "Content-Type":"application/json" },
        body: raw,
    };

    // Process API call
    return API_HELPER.apiCall(url, null, requestOptions);    
}

function enquire(content) {
   
    var raw = JSON.stringify(content);
    const requestOptions = {
        method: 'POST',
        headers: {...authHeader(), "Content-Type":"application/json" },
        body: raw,
    };
    
    // Build API call.
    var url = API_HELPER.pathJoin([process.env.VUE_APP_API_ROOT, API_ROOT_SERVICE, "experience"]); // TO DO

    // Process API call
    return API_HELPER.apiCall(url, null, requestOptions);    
}
<template>
    <div class="questions">
        <hr v-if="separatorBorder && items.length"/>
        <div class="d-flex flex-column" v-for="(item, index) in items" :key="index">
            <div class="d-flex justify-content-between cursor-pointer" @click="selected == index ? selected = -1 : selected = index">
                <span class="py-1 font-bolder">{{item.question}}</span>
                <span class="open-indicator d-flex justify-content-center">
                  <span v-bind:class="{'rotated': selected == index}">
                    <img src="@/assets/Experiences/uni_selection_arrow.svg" alt="">
                  </span>
                </span>
            </div>
            <span class="py-1" v-show="selected == index" v-html="item.answer"></span>
            <hr v-if="separatorBorder || index != items.length - 1"/>
        </div>
    </div>
</template>

<script>
export default {
    props: {
      items: Array, // ARRAY of objects {question: "", answer: ""}
      separatorBorder:
      {
        type: Boolean,
        default: true
      }
    },
    data: function () {
    return {
      selected: -1
    };
  },
}
</script>

<style scoped>
hr {
  width: 100%;
  height: 2px !important;
  border-top: 2px dotted #962422;
  background-color: transparent;  margin: 0;
}

.open-indicator > .icon
{
  font-size: 20px;
}

.bi-caret-down {
  color: white;
  font-weight: bold;
}

.rotated {
  transform: rotate(180deg); /* Equal to rotateZ(45deg) */
}

@media screen and (max-width: 410px) {
  .questions p {
    padding: 10px 38px;
    font-size: 11px;
  }
}

</style>
<template>
  <section>
    <div class="container">
        <SpinnerLoading v-if="loading"/>
        <div class="row justify-content-center mt-5">
            <!-- Orders -->
            <div class="col-sm-12 col-md-8 col-lg-8 align-self-center">
                <!-- check if you have orders -->
                <div class="row m-0 align-content-center" v-if="orders.length == []">
                    <h2 class="d-flex justify-content-center">{{$t("account.noOrders")}}</h2>
                    <router-link class="w-auto my-0 mx-auto" :to="{name: 'Shop'}"><button class="btn btn-primary border-0 mt-5 mb-5">{{$t("account.goToShop")}}</button></router-link>
                </div>
                <!-- table with orders -->
                <div v-else class="table-wrapper">
                    <div class="table-title">
                        <div class="row">
                            <div class="col-12">
                                <h2><b>{{$t("account.orderDetails")}}</b></h2>
                            </div>
                        </div>
                    </div>
                    <div>
                        <table class="table table-striped table-hover">
                            <thead>
                                <tr>
                                <th class="w-50" scope="col">{{$t("account.ref")}}</th>
                                    <th class="w-50" scope="col">{{$t("account.status")}}</th>
                                </tr>
                            </thead>
                            <tbody v-for="(order, index) in orders" :key="index">
                                <tr>
                                    <td scope="row">00{{order.id}}</td>
                                    <td>{{order.status === 'PendingPayment' ? $t('account.pendingPayment') : order.status === 'Paid' ? $t('account.paid') : $t('account.cancelled') }}</td>
                                    <td @click="activeShow == index ? activeShow = -1 : activeShow = index">
                                        <a class="view" title="View Details" data-toggle="tooltip">
                                            <b-icon :icon="activeShow == index ? 'arrow-down' : 'arrow-right'" class="cursor-pointer"></b-icon>
                                        </a>
                                    </td>
                                </tr>
                                <tr v-if="activeShow == index">
                                    <td colspan="1" class="vertical-address-bar">
                                        <a class="cursor-pointer" @click="showAddressesPopUpForm = true">{{$t("account.address")}}</a>
                                    </td>
                                    <td colspan="4">
                                        <div class="d-flex justify-content-between border-bottom" v-for="(productDetail, index) in order.products" :key="index">
                                            <div class="py-3">
                                                <p class="m-0"><strong>{{productDetail.title}}</strong></p>
                                                <p class="m-0">{{productDetail.finalPrice}}€</p>
                                            </div>
                                            <div class="py-3">
                                                <p class="m-0"><a class="cursor-pointer" @click="productClicked(productDetail)">{{$t("account.product")}}</a></p>
                                            </div>
                                        <AddressesDetailPopUp v-if="showAddressesPopUpForm" @closeConfirmRequested="showAddressesPopUpForm = false" @close-request="showAddressesPopUpForm = false" :details="order.details" />
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div v-if="rows >= 5" class="col-12 d-flex pagination">
                        <Pagination :rows="rows" :itemsPerPage="itemsPerPage" :loading="loading" v-model="currentPage"></Pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </section>
</template>

<script>
import InputGetOrders from "@/classes/InputGetOrders";
import {  mapState, mapActions } from "vuex";
import Pagination from "@/components/Pagination.vue";
import AddressesDetailPopUp from "@/components/popups/AddressesDetailPopUp.vue";
import SpinnerLoading from "@/components/SpinnerLoading.vue";

const EXPERIENCE = "Experience";
const VOUCHER = "Voucher";

export default {
    components: {
        Pagination,
        AddressesDetailPopUp,
        SpinnerLoading
    },
     data() {
        return {
            currentPage: 1,
            activeShow: -1,
            submitted: false,
            loading: false,
            showAddressesPopUpForm: false,
            itemsPerPage: localStorage.itemsPerPage ?? 5
        }
    },
    mounted: function()
    {
        // GET list of orders
        this.updateItemsRequested(1, this.itemsPerPage);
    },
    computed: {
        ...mapState({
            ordersOutput: state => state.orders.listOrders,
        }),
        ordersInfo: function(){
            return this.ordersOutput ? this.ordersOutput : [];
        },
        orders: function()
        {
            var result = [];

            if(this.ordersInfo && this.ordersInfo.data)
            {
                result = this.ordersInfo.data;
            }

            return result;
        },
        rows: function()
        {
            return this.ordersOutput ? this.ordersOutput.totalRecords : 1;
        },
    },
    methods: {
        ...mapActions({
            getOrdersList: 'orders/getOrders',
        }),
         updateItemsRequested()
        {
            this.loading = true;

            // GET list of orders
            const orderBy = 2;
            const propertyName = "id";
            const searchText = '';

            //Make request
            var input = new InputGetOrders(this.itemsPerPage, this.currentPage, orderBy, propertyName, searchText);
            input.statuses = null; // Get all

            this.getOrdersList(input);
        },
        productClicked(orderProducts)
        {
        if(orderProducts.type == EXPERIENCE)
        {
            this.$router.push({name: "ExperienceDetail", query: {productId: orderProducts.id} }).catch(()=>{});
        }
        else if(orderProducts.type == VOUCHER)
        {
            this.$router.push({name: "VoucherDetail", query: {productId: orderProducts.id} }).catch(()=>{});
        }
        },
    },
    watch: {
        currentPage(){
            this.updateItemsRequested(1, this.itemsPerPage)    
        },
        ordersOutput(){
            this.loading = false; 
            this.activeShow = -1;
        },
    }
}
</script>

<style scoped>
.verticalBar {
  border-left: 4px solid #962422;
  height: 400px;
}
.tab-btn{
    cursor: pointer;
    opacity: 0.3;
    font-size: 1.2rem;
}
.tab-btn.active{
    color: #962422;
    opacity: 1;
}
a{
  color: #962422 !important;
}
/* table */
.table > :not(:first-child) {
    border-color: white;
}
.table-title {
    color: #fff;
    background: #962422;		
    padding: 15px 25px;
    border-radius: 3px 3px 0 0;
}
.table-title h2 {
    margin: 5px auto;
    font-size: 1.5rem;
}
table.table tr th, table.table tr td {
    border-color: white;
    padding: 12px 15px;
    vertical-align: middle;
}
table.table-striped.table-hover tbody tr:hover {
    background: #f5f5f5;
}	
table.table td a {
    display: inline-block;
}
table.table td a.view {        
    width: 30px;
    height: 30px;
    border: 2px solid;
    border-radius: 30px;
    text-align: center;
}
.border-bottom{
    border-bottom:  2px solid #962422!important;
}
>>>.pagination {
    justify-content: center;
}
</style>
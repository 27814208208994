<template>
  <div id="home">
    <LogoLoading v-if="loading"/>
    <div v-show="!loading"> <!-- v-show instead of v-if so that the requests are made -->
      <div class="mb-5">
        <Hero @profile-clicked="onProfileClicked"/>
        <!-- Temporary -->
        <AlertBanner v-if="showCookieBanner" :identifier="COOKIE_IDENTIFIER" :icon="require('../assets/Banners/cookie.svg')" :title='$t("alertBanner.titleOne")' :messageHtml='$t("alertBanner.messageOne")'></AlertBanner>
        <AlertBanner v-if="userInfoQuestions == 0" :icon="require('../assets/Quiz/weekendMale.svg')" :title='$t("alertBanner.quiz")' :messageHtml='$t("alertBanner.quizDesc")' :isQuizBanner="true"></AlertBanner>
        <!-- <AlertBanner v-if="showCovidBanner" :identifier="COVID_IDENTIFIER" :icon="require('../assets/Banners/covid.svg')" :title='$t("alertBanner.titleTwo")' :messageHtml='$t("alertBanner.messageTwo")'></AlertBanner> -->
      </div>
      <!-- -->
      <!-- <InspirationCategories class="mt-5 mb-5"/> -->
      <TailorMadeExperience class="mt-5 mb-5" @success-alert-requested="onTailorSuccess" @error-alert-requested="onTailorMadeError" :startOpened="startTailorOpened"/>
      <ToursBySeason class="mt-5 mb-5"/>
      <!-- <VoucherSlider v-show="voucherList.length" :title="$t('giftExperiencesTitle')" class="mt-5 mb-5"/> -->
      <BlogSlider class="mt-5"/>
      <Newsletter @success-alert-requested="onNewsletterSuccess" :startOpened="startNewsletterOpened"/>
    </div>
    <QuizPopUp @closeConfirmRequested="fillProfile = false" v-model="fillProfile"></QuizPopUp>
  </div>
</template>

<script>
const STORAGEPREFIX = "alertbanner-";
const COOKIE_IDENTIFIER = 'cookie';
const COVID_IDENTIFIER = 'covid';

// @ is an alias to /src
import Hero from "@/components/Hero.vue";
// import InspirationCategories from "@/components/InspirationCategories.vue";
// import VoucherSlider from "@/components/VoucherSlider.vue";
import ToursBySeason from "@/components/ToursBySeason.vue";
import BlogSlider from "@/components/BlogSlider.vue"
import Newsletter from "@/components/Newsletter.vue"
import AlertBanner from "@/components/AlertBanner.vue"
import TailorMadeExperience from "@/components/TailorMadeExperience.vue"
import LogoLoading from "@/components/LogoLoading.vue"
import QuizPopUp from "@/components/popups/QuizPopUp.vue"
import { mapState } from 'vuex';
import { UTILS_HELPER } from "@/helpers/utils.js";

export default {
    metaInfo () {
    return { 
      title: 'Wild Douro',
      htmlAttrs: {
          lang: this.$i18n.locale,
      },
      meta: 
      [
        {name: 'description', content: UTILS_HELPER.htmlToText(this.$t("vueRoutes.intro"))},
        {name: 'keywords', content: this.$t("vueRoutes.defaultKeywords")},
        // // Twitter Card
        // {name: 'twitter:card', content: "summary"},
        // {name: 'twitter:title', content: 'Wild Douro'},
        // {name: 'twitter:description', content: UTILS_HELPER.htmlToText(this.$t("intro"))},
        // {name: 'twitter:image', content: API_HELPER.pathJoin([process.env.VUE_APP_URL, "assets/hero/hero.jpg"]) },
        // //Facebook OpenGraph
        // {property: 'og:title', content: 'Wild Douro'},
        // {property: 'og:site_name', content: 'Wild Douro'},
        // {property: 'og:type', content: 'website'},
        // {property: 'og:image', content: API_HELPER.pathJoin([process.env.VUE_APP_URL, "assets/hero/hero.jpg"]) },
        // {property: 'og:description', content: UTILS_HELPER.htmlToText(this.$t("intro"))}
      ], 
      link: [
        {rel: 'alternate', hreflang: 'en', href: process.env.VUE_APP_URL_ROOT},
        {rel: 'alternate', hreflang: 'pt', href: process.env.VUE_APP_URL_ROOT},
      ]
    } 
  }, 
  name: "Home",
  components: {
    Hero,
    // InspirationCategories,
    // VoucherSlider,
    ToursBySeason,
    BlogSlider,
    Newsletter,
    AlertBanner,
    TailorMadeExperience,
    LogoLoading,
    QuizPopUp
  },
  data: function () {
    return {
      startTailorOpened: false,
      startNewsletterOpened:false,
      statQuizOpened: false,
      fillProfile: false,
      //
      COOKIE_IDENTIFIER: COOKIE_IDENTIFIER,
      COVID_IDENTIFIER: COVID_IDENTIFIER,
    };
  },
  computed:
  {
    ...mapState('account', ['user']),
    userInfo: function()
    {
      return this.user && this.user.data ? this.user.data : null;
    },
    userInfoQuestions: function()
    {
      return this.user && this.user.data && this.user.data.questions ? this.user.data.questions : null;
    },
    ...mapState({ 
      productsOutput: state => state.products.listOutput,
      postsOutput: state => state.posts.listOutput,
    }),
    loading: function()
    {
      return this.productsOutput == null || this.postsOutput == null;
    },
    showCookieBanner: function()
    {
      var storageIdentifier = STORAGEPREFIX + COOKIE_IDENTIFIER;
      return !localStorage.getItem(storageIdentifier);
    },
    showCovidBanner: function()
    {
      var storageIdentifier = STORAGEPREFIX + COVID_IDENTIFIER;
      return !localStorage.getItem(storageIdentifier) && !this.showCookieBanner;
    }
  },
  methods: {
    onTailorSuccess: function()
    {
      this.$emit("success-alert-requested", { message:this.$t("tailorMade.info")});
    },
    onTailorMadeError: function()
    {
      this.$emit('error-alert-requested', this.$t("tailorMade.error"));
    },
    sendClicked (id) {
      this.$router.push({name: 'Page', query: {id: id} });
    },
    onProfileClicked: function()
    {
      if(!this.userInfo)
      {
        this.$router.push({name: "Login" });
      }
      else
      {
        if(this.userInfo.questions && this.userInfo.questions.length >= 4)
        {
          const tourismType = this.userInfo.questions[3].answer;
          this.$router.push({name: "Inspirations", params: {tourismType: tourismType }});
        }
        else
        {
          this.fillProfile = true;
        }
      }
    },
    onNewsletterSuccess: function()
    {
      this.$emit("success-alert-requested", { message:this.$t("newsletter.submitMsg")});
    },
    processHash: function()
    {
        var elemnt;

        if(this.startTailorOpened)
        {
          elemnt = document.getElementById("tailor-made-experience");
        }
        else if(this.startNewsletterOpened){
          elemnt = document.getElementById("newsletter");
        }
        else if(this.startQuizOpened){
          elemnt = document.getElementById("profile-quiz");
        }
        
        // Execute after all images have loaded (done because we had problems in some browsers)
        if(elemnt) 
        {
          Promise.all(Array.from(document.images).filter(img => !img.complete).map(img => new Promise(resolve => { img.onload = img.onerror = resolve; }))).then(() => {
            elemnt.scrollIntoView();
          });
        }
    }
  },
  mounted: function()
  {
    this.startTailorOpened = this.$router.currentRoute.hash && this.$router.currentRoute.hash == "#tailor-made-experience" ? true : false;
    this.startNewsletterOpened = this.$router.currentRoute.hash && this.$router.currentRoute.hash == "#newsletter" ? true : false;
    this.startQuizOpened = this.$router.currentRoute.hash && this.$router.currentRoute.hash == "#profile-quiz" ? true : false;
    this.fillProfile = this.startQuizOpened;
  },
  watch: 
  {
    $route(to) 
    {
      if(to.name == "Home")
      {
        this.startTailorOpened = this.$router.currentRoute.hash && this.$router.currentRoute.hash == "#tailor-made-experience" ? true : false;
        this.startNewsletterOpened = this.$router.currentRoute.hash && this.$router.currentRoute.hash == "#newsletter" ? true : false;
        this.startQuizOpened = this.$router.currentRoute.hash && this.$router.currentRoute.hash == "#profile-quiz" ? true : false;
        this.fillProfile = this.startQuizOpened;
      }
    },
    loading: function(val)
    {
      if(!val)
      {
        this.processHash();
      }
    },
    startTailorOpened: function() { this.processHash(); },
    startNewsletterOpened: function() { this.processHash(); },
    startQuizOpened: function() { this.processHash(); }
  }
}
</script>
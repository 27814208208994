import Vue from "vue";
import VueRouter from "vue-router";

import Home from "../views/Home.vue";
import BlogDetail from "../views/BlogDetail.vue";
import Inspirations from "../views/Inspirations.vue";
// import Vouchers from "../views/Vouchers.vue";
import VoucherDetail from "../views/VoucherDetail.vue";
// import Cart from "../views/Cart.vue";
import InspirationDetail from "../views/InspirationDetail.vue";
import Login from "../views/Login.vue";
import Logout from "../views/Logout.vue";
import Team from "../views/Team.vue";
import Page from "../views/StaticPage.vue";
import Blog from "../views/Blog.vue";
import ErrorPage from "../views/ErrorPage.vue";
import ProfilePage from "../views/ProfilePage.vue";
import Faqs from "../views/Faqs.vue";
import SearchResults from "../views/SearchResults.vue";
import Favorites from "../views/Favorites.vue";
import ConfirmAccount from "../views/ConfirmAccount.vue";
import Orders from "../views/Orders.vue";
import i18n from '@/helpers/i18n';

import AppHeader from "../layout/AppHeader.vue";
import AppFooter from "../layout/AppFooter.vue";

import Page404 from "../views/Page404.vue";

import { CONSTANTS } from '@/helpers/consts';

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    components: {
      header: AppHeader,
      default: Home,
      footer: AppFooter
    }
  },
  {
    path: i18n.t('vueRoutes.blog'),
    alias: CONSTANTS.supportedLanguages.map(locale => i18n.t('vueRoutes.blog', locale.key) ),
    name: 'Blog',
    components: {
      header: AppHeader,
      default: Blog,
      footer: AppFooter
    }
  },
  {
    path: `${i18n.t('vueRoutes.blogPost')}`,
    alias: CONSTANTS.supportedLanguages.map(locale => `${i18n.t('vueRoutes.blogPost', locale.key)}` ),
    name: 'BlogDetail',
    components: {
      header: AppHeader,
      default: BlogDetail,
      footer: AppFooter
    }
  },
  // {
  //   path: i18n.t('vueRoutes.vouchers'),
  //   alias: CONSTANTS.supportedLanguages.map(locale => i18n.t('vueRoutes.vouchers', locale.key) ),
  //   name: 'Vouchers',
  //   components: {
  //     header: AppHeader,
  //     default: Vouchers,
  //     footer: AppFooter
  //   }
  // },
  {
    path: i18n.t('vueRoutes.voucherDetail'),
    alias: CONSTANTS.supportedLanguages.map(locale => i18n.t('vueRoutes.voucherDetail', locale.key) ),
    name: 'VoucherDetail',
    components: {
      header: AppHeader,
      default: VoucherDetail,
      footer: AppFooter
    }
  },
  {
    path: i18n.t('vueRoutes.inspirations'),
    alias: CONSTANTS.supportedLanguages.map(locale => i18n.t('vueRoutes.inspirations', locale.key) ),
    name: 'Experiences',
    components: {
      header: AppHeader,
      default: Inspirations,
      footer: AppFooter
    }
  },
  {
    path: `${i18n.t('vueRoutes.inspirationDetail')}/:sku`,
    alias: CONSTANTS.supportedLanguages.map(locale => `${i18n.t('vueRoutes.inspirationDetail', locale.key)}/:sku` ),
    name: 'ExperienceDetail',
    components: {
      header: AppHeader,
      default: InspirationDetail,
      footer: AppFooter
    }
  },
  {
    path: i18n.t('vueRoutes.login'),
    alias: CONSTANTS.supportedLanguages.map(locale => i18n.t('vueRoutes.login', locale.key) ),
    name: 'Login',
    components: {
      header: AppHeader,
      default: Login
    }
  },
  // {
  //   path: i18n.t('vueRoutes.cart'),
  //   alias: CONSTANTS.supportedLanguages.map(locale => i18n.t('vueRoutes.cart', locale.key) ),
  //   name: 'Cart',
  //   components: {
  //     header: AppHeader,
  //     default: Cart,
  //     footer: AppFooter
  //   }
  // },
  {
    path: i18n.t('vueRoutes.team'),
    alias: CONSTANTS.supportedLanguages.map(locale => i18n.t('vueRoutes.team', locale.key) ),
    name: 'Team',
    components: {
      header: AppHeader,
      default: Team,
      footer: AppFooter
  }
},
  {
    path: `${i18n.t('vueRoutes.staticPage')}/:sku`,
    alias: CONSTANTS.supportedLanguages.map(locale => `${i18n.t('vueRoutes.staticPage', locale.key)}/:sku` ),
    name: 'Page',
    components: {
      header: AppHeader,
      default: Page,
      footer: AppFooter

  }
  },
  {
    path: i18n.t('vueRoutes.errorPage'),
    alias: CONSTANTS.supportedLanguages.map(locale => i18n.t('vueRoutes.errorPage', locale.key) ),
    name: 'ErrorPage',
    components: {
      header: AppHeader,
      default: ErrorPage,
      footer: AppFooter
    }
  },
  {
    path: i18n.t('vueRoutes.profile'),
    alias: CONSTANTS.supportedLanguages.map(locale => i18n.t('vueRoutes.profile', locale.key) ),
    name: 'ProfilePage',
    components: {
      header: AppHeader,
      default: ProfilePage,
      footer: AppFooter
    }
  },
  {
    path: i18n.t('vueRoutes.faq'),
    alias: CONSTANTS.supportedLanguages.map(locale => i18n.t('vueRoutes.faq', locale.key) ),
    name: 'Faqs',
    components: {
      header: AppHeader,
      default: Faqs,
      footer: AppFooter
    }
  },
  {
    path: i18n.t('vueRoutes.favorites'),
    alias: CONSTANTS.supportedLanguages.map(locale => i18n.t('vueRoutes.favorites', locale.key) ),
    name: 'Favorites',
    components: {
      header: AppHeader,
      default: Favorites,
      footer: AppFooter
    }
  },
  {
    path: i18n.t('vueRoutes.searchResults'),
    alias: CONSTANTS.supportedLanguages.map(locale => i18n.t('vueRoutes.searchResults', locale.key) ),
    name: 'SearchResults',
    components: {
      header: AppHeader,
      default: SearchResults,
      footer: AppFooter
    }
  },
  {
    path: i18n.t('vueRoutes.confirmAccount'),
    alias: CONSTANTS.supportedLanguages.map(locale => i18n.t('vueRoutes.confirmAccount', locale.key) ),
    name: 'ConfirmAccount',
    components: {
      header: AppHeader,
      default: ConfirmAccount,
      footer: AppFooter
    }
  },
  {
    path: i18n.t('vueRoutes.consultOrders'),
    alias: CONSTANTS.supportedLanguages.map(locale => i18n.t('vueRoutes.consultOrders', locale.key) ),
    name: 'Orders',
    components: {
      header: AppHeader,
      default: Orders,
      footer: AppFooter
    }
  },
  {
    path: '*',
    name: '404',
    components: {
      header: AppHeader,
      default: Page404,
      footer: AppFooter
    }
  },    
  {
    path: '/logout',
    name: 'Logout',
    component: Logout
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;

<template>
    <div class="card-what-they-say shadow d-flex flex-column cursor-pointer" @click="clicked">
        <div class="d-flex flex-column p-3 flex-grow-1">
            <div class="row">
                <div class="col-3 p-0 text-center">
                    <img class="author-thumb" :src="imageUrl" alt="Card image cap">
                </div>
                <div class="col-9 align-self-center" id="Card-Title">
                    <h6 class="card-title font-bolder mb-0">{{testimonial.title}}</h6>
                    <p class=" flex-grow-1 m-0">{{testimonial.subtitle}}</p> 
                </div>
            </div>
            <div class="row d-flex justify-content-between mt-2"> 
                <div class="col-2 p-0 text-center mt-2" >
                    <img class="aspas" src="../assets/BlogSlider/aspas.png" alt="Card image cap">
                </div>  
                <div class="col-10">
                    <div class="mt-2 text-left" :style="{wordBreak: 'break-word'}">
                        <p>{{testimonial.content}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { API_HELPER } from "@/helpers/api.js";
export default {
  props: {
    testimonial: Object,
  },
    methods: 
    {
        clicked: function()
        {
            this.$router.push("Team");
        }
    },
    computed:{
        imageUrl: function()
        {
        return this.testimonial && this.testimonial.photo ? API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.testimonial.photo]) : "";  
        },
   }
}
</script>

<style scoped>
.author-thumb
{
    max-width: 70px;
    border-radius: 100%;
}
.aspas
{
    max-width: 30px;
}
@media screen and (max-width: 455px) {
  #Card-Title {
    text-align: center;
  }
}
@media screen and (max-width: 340px) {
  #Card-Title {
    text-align: center;
  }
}
.card-what-they-say{
    background-color: white;
    z-index: 2;
    min-height: 100vh ;
}
</style>
<template>
    <!-- Based on Vue-Multiselect -->
    <multiselect ref="multiselect" v-model="selectedItems" :options="options" :multiple="true" :close-on-select="false" :clear-on-select="false" :placeholder="placeholder" label="name" track-by="value" :showLabels="false" :searchable="false" :max-height="999999" @open="opened">
        <!-- Add APPLY button -->
        <template slot="afterList">
            <div class="cursor-pointer">
                <button class="apply-button w-100 p-2" :class="{'disabled': selectedItems == value}" @click="applyClicked">{{$t("multiSelection.apply")}}</button>
            </div>
        </template>
        <!-- Add radio buttons -->
        <template slot="option" slot-scope="props">
            <div class="option__desc d-flex align-items-center cursor-pointer" @click="previewClicked">
                <input type="radio" class="option__title cursor-pointer" :checked="selectedValues.includes(props.option.value)"/>
                <span>{{ props.option.name }}</span>
            </div>
        </template>
        <!-- Change preview -->
        <template slot="tag" slot-scope="props">
            <span class="cursor-pointer" v-if="!selectedItems.length || selectedItems[0].value == props.option.value">{{placeholder}}</span>
            <span class="cursor-pointer" v-else></span>
        </template>
    </multiselect>
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
    components: {
        Multiselect
    },
    props: {
        value: Array,
        options: Array,
        placeholder: String
    },
    data () {
        return {
            selectedItems: [], // Temporary storage until APPLY
        }
    },
    computed:
    {
        selectedValues()
        {
            return this.selectedItems.map(item => item.value);
        }
    },
    methods:
    {
        applyClicked()
        {
            this.$refs.multiselect.toggle();
            this.$emit('input', this.selectedItems);
        },
        previewClicked()
        {
            // document.getElementsByClassName(".multiselect__select")[0].click();
        },
        opened()
        {
            // The user may have closed the dropdown without applying
            this.selectedItems = this.value;
        }
    }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.apply-button
{
    background-color: #962422;
    border: none;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
}
input[type='radio']
{
    margin-right: 0.5rem;
}
input[type="radio"] {
  /* remove standard background appearance */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* create custom radiobutton appearance */
  display: inline-block;
  width: 15px;
  height: 15px;
  padding: 2px;
  /* background-color only for content */
  background-clip: content-box;
  border: 2px solid #962422;
  background-color: white;
  border-radius: 50%;
}
/* appearance for checked radiobutton */
input[type="radio"]:checked {
  background-color: #962422;
}
/deep/.multiselect__tags {
    min-height: 30px!important;
    border-radius: 0px!important;
    height: 30px!important;
    padding: 3px 0 3px 10px!important;
}
/deep/.multiselect__content
{
    padding: 6px 12px;
}
/deep/.multiselect__option
{
    line-height: 36px;
    padding: unset;
    min-height: unset;
}
/deep/.multiselect__option--highlight
{
    background: none;
    color: unset;
}

/deep/.multiselect__option--selected
{
    background: unset;
    font-weight: normal;
}

/deep/.multiselect__tags
{
    border: 1px solid #a3161c;
}
/deep/.multiselect__select
{
    height: 30px!important;
}
/deep/.multiselect__select:before
{
    color: #a3161c;
    border-color: #a3161c transparent;
}

/deep/.multiselect__placeholder
{
    display: block !important;
    margin-bottom: 0px!important;
    padding-top: 0px!important;
}
/deep/.multiselect__placeholder,
/deep/.multiselect__tags-wrap
{
    color: #a3161c;
}

/deep/.multiselect__content-wrapper
{
    width: max-content;
    min-width: 100%;
}
</style>
<template>
  <div class="container-fluid" id="vouchers">
    <!-- Title -->
    <div v-if="title" class="text-center title">{{title}}</div>
    <!-- Slides -->
    <vueper-slides style="padding: 20px 73.5px" fixed-height="400px" class="no-shadow" :bullets="false" :visible-slides="4" slide-multiple :gap="1" :dragging-distance="70" :touchable="false" :breakpoints="{
        1903: { visibleSlides: 3, slideMultiple: 3 },
        1455: { visibleSlides: 2, slideMultiple: 2 },
        800: { visibleSlides: 1, slideMultiple: 1, touchable:true },
      }"
    >
      <!-- Custom arrows -->
      <template v-slot:arrow-left>
        <span class="icon-slot_arrow_left"></span>
      </template>
      <template v-slot:arrow-right>
        <span class="icon-slot_arrow_right"></span>
      </template>
      <!-- Content -->
      <vueper-slide v-for="voucher in voucherList" :key="voucher.id">
        <template v-slot:content>
          <VoucherThumb :voucher="voucher"/>
        </template>
      </vueper-slide>
    </vueper-slides>
  </div>
</template>

<script>
import VoucherThumb from "@/components/VoucherThumb.vue";
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";
import { mapState, mapActions } from "vuex";
import InputGetList from "@/classes/InputGetList";

export default {
  components: { VueperSlides, VueperSlide, VoucherThumb },
  props: {
    title: String
  },
    computed: {
    ...mapState('vouchers', ['listOutput']),
    voucherList: function() {
      return this.listOutput && this.listOutput.data ? this.listOutput.data : [];
    },
  },
  methods: {
    ...mapActions("vouchers", ["getList"]),
  },
  mounted() {
    if(!this.voucherList.length)
    {
      const itemsPerPage = 10;
      const currentPage = 1;
      const searchText = '';
      const sorter = 2; // desc
      const propertyName = "id";

      var input = new InputGetList(itemsPerPage, currentPage, searchText, sorter, propertyName);
      input.visible= true;
      this.getList(input);
    }
  },
};
</script>

<style scoped>
.icon-slot_arrow_right{
  margin-right: -20px;
}
.icon-slot_arrow_left{
  margin-left: -20px;
}
.icon-slot_arrow_left:before,
.icon-slot_arrow_right:before {
  font-size: 90px;
}

.title {
  padding: 20px 0;
  color: #962422;
  font-weight: bold;
  font-size: 25px;
}
 >>>.vueperslide .overlay {
  visibility: hidden;
  transition: 1s ease;
  opacity: 0;
  background-color: hsla(1, 63%, 36%, 0);
  min-height: 400px;
}
>>>.vueperslide__content-wrapper{
  object-fit: cover!important;
  /* width: auto!important; */
  max-width: 420px!important;
}
@media (max-width: 1025px){
  >>>.vueperslide__content-wrapper .logo-fixed{
    bottom: 1.7%;
  }
  >>>.bottom .logo{
    bottom: 1.7%;
  }
  >>>.vueperslide__content-wrapper{
    width: 300px!important;
  }
  >>>.vueperslides{
    height: 300px!important;
  }
  >>>.vueperslide .overlay {
    min-height: 300px;
  }
}
@media (max-width: 576px){
  >>>.vueperslides{
    padding: 0!important;
  }
}
</style>

<template>
  <div class="container my-5">
    <a v-if="itemsPerPage != DEFAULT_ITEMS_PER_PAGE" to="#" class="btn d-flex align-items-center p-0 mb-2" @click="itemsPerPage = DEFAULT_ITEMS_PER_PAGE"><span class="font-weight-bold icon-slot_arrow_left"></span><span class="font-weight-bold primary-color">RETROCEDER</span></a>
    <!-- Header and initial load more -->
    <div class="d-flex justify-content-between">
        <h1 class="flex-grow-1">Blog</h1>
        <div v-if="!loading && canLoadMore && itemsPerPage == DEFAULT_ITEMS_PER_PAGE" class="see d-flex align-items-center cursor-pointer" @click="loadMore">
            <img class="see-more-icon" src="@/assets/see-more.svg"/>
            <span class="flex-grow-1">{{$t("seeMore")}}</span>
        </div>
    </div>
    <!-- Loading -->
    <div v-if="loading" class="blog-container d-flex flex-wrap mt-2">
      <SkeletonLoading v-for="(block, index) in (new Array(itemsPerPage))" :key="index"></SkeletonLoading>
    </div>
    <!-- Content -->
    <div v-else class="blog-container d-flex flex-wrap mt-2">
      <BlogThumb class="blog-thumb" v-for="post in posts" :key="post.id" :post="post"></BlogThumb>
      <NoResultsPlaceholder v-if="!posts.length"></NoResultsPlaceholder>
    </div>
    <!-- Load more -->
    <div v-if="!loading && canLoadMore && itemsPerPage > DEFAULT_ITEMS_PER_PAGE" class="see">
      <span @click="loadMore" type="button" class="icon-seeMore"></span>
      <p class="">{{$t("seeMore")}}</p>
    </div>
  </div>
</template>


<script>
import BlogThumb from "@/components/BlogThumb.vue"
import { mapState, mapActions } from "vuex";
import InputGetList from "@/classes/InputGetList";
import SkeletonLoading from "@/components/SkeletonLoading.vue"
import NoResultsPlaceholder from "@/components/NoResultsPlaceholder.vue"

const DEFAULT_ITEMS_PER_PAGE = 4;

export default {
    components: {
        BlogThumb,
        SkeletonLoading,
        NoResultsPlaceholder
    },
    props:
    {
        searchQuery: String
    },
    data() {
      return {
        initialized: false,
        itemsPerPage: DEFAULT_ITEMS_PER_PAGE,
        //
        DEFAULT_ITEMS_PER_PAGE: DEFAULT_ITEMS_PER_PAGE
      }
    },
    methods: {
      ...mapActions({
          getPostsList: 'posts/getList',
      }),
      updateItemsRequested()
      {
          // GET list of posts
          const currentPage = 1;
          const sorter = 2; // desc
          const propertyName = "id";
          
          //Make request
          var input = new InputGetList(this.itemsPerPage, currentPage, this.searchQuery, sorter, propertyName);
          
          // List specific parameters
          input.visible= true;

          this.getPostsList(input); 
      },
      loadMore()
      {
        if(this.canLoadMore)
        {
            this.itemsPerPage += DEFAULT_ITEMS_PER_PAGE; 
        }
      }
    },
  computed: {
    ...mapState({ 
      postsOutput: state => state.posts.listOutput,
    }),
    loading: function(){
      return this.postsOutput == null;
    },
    posts: function() {
      return this.postsOutput && this.postsOutput.data && this.postsOutput.data.data ? this.postsOutput.data.data : [];
    },
    numBlogs: function(){
      return this.postsOutput && this.postsOutput.data && this.postsOutput.data.totalRecords ? this.postsOutput.data.totalRecords : 0;
    },
    canLoadMore: function()
    {
      return this.numBlogs > this.itemsPerPage;
    },
  },
  mounted () 
  {
    // GET list of posts
    this.updateItemsRequested();
  },
  watch:
  {
    itemsPerPage()
    {
      this.updateItemsRequested();

      // 

      if(this.itemsPerPage == DEFAULT_ITEMS_PER_PAGE)
      {
        this.$emit("see-more-cancelled");
      }
      else
      {
        this.$emit("see-more-requested");
      }
    },
    searchQuery: function()
    {
      this.updateItemsRequested();
    }
  }
}
</script>

<style scoped>
.see {
  position: relative;
  gap: 16px;
}
.see > p {
  position: absolute;
  bottom: 25%;
  color: #962422;
}
.icon-seeMore:before {
  cursor: pointer;
  font-size: 200px;
}
.see-more-icon
{
    height: 50%;
}

>>>.overlay {
    visibility: hidden;
transition: 1s ease;
background-color: hsla(1, 63%, 36%, 0);
}


/* Size of blog elements: these are not done using bootstrap to have correct alignments, using gap */
.blog-container /* Four columns */
{
  gap: 4%;
}

.blog-container > .blog-thumb,
.blog-container > .skeleton-box
{
  width: 22%;
  max-width: 22%;
  margin-bottom: 4%;
  min-height: 300px;
}

@media (max-width: 1199px) 
{
  .blog-container
  {
    gap: 5%;
  }

  .blog-container > .blog-thumb,
  .blog-container > .skeleton-box
  {
    width: 30%;
    max-width: 30%;
    margin-bottom: 5%;
  }
}

@media (max-width: 767px) /* Two columns */
{
  .blog-container
  {
    gap: 10%;
  }

  .blog-container > .blog-thumb,
  .blog-container > .skeleton-box
  {
    width: 45%;
    max-width: 45%;
    margin-bottom: 10%;
  }
}

</style>
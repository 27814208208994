<template>
<section v-if="postsList && postsList.length" class="blog-slider d-flex py-4">
  <!-- Title and navigation buttons -->
  <div class="title-container d-flex flex-column justify-content-center">
    <h1 class="px-4 blog-title text-center" :style="{fontSize: '3.5rem'}">{{$t("blog")}}</h1>
    <div class="d-flex w-100" id="buttons">
      <button class="btn-icon" @click="$refs.blogSlides.previous()"><span class="icon-left"></span></button>
      <button class="btn-icon" @click="$refs.blogSlides.next()"><span class="icon-right"></span></button>
    </div>
  </div>
  <!-- Slides -->
  <vueper-slides ref="blogSlides" class="slider no-shadow flex-grow-1 mb-0" :bullets="false" :arrows="false" :gap="1" :touchable="false" :visibleSlides="5" :disableArrowsOnEdges="true" :lazy="true" fixed-height="500px" :slideMultiple="true" :breakpoints="{
      1420: { visibleSlides: 3, gap: 2, fixedHeight: 450 },
      100: { visibleSlides: 2, gap: 5, fixedHeight: 400 },
      700: { visibleSlides: 1, fixedHeight: 400 },
    }"
  >        
    <vueper-slide v-for="post in postsList" :key="post.id">
        <template v-slot:content>
          <BlogThumb :post="post"></BlogThumb>
        </template>
    </vueper-slide>
  </vueper-slides>
</section>
</template>

<script>
import BlogThumb from "@/components/BlogThumb.vue";
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";
import { mapState, mapActions } from "vuex";
import InputGetList from "@/classes/InputGetList";

export default {
  components: {
    BlogThumb,
    VueperSlides,
    VueperSlide
  },
   computed: {
    ...mapState('posts', ['listOutput']),
    postsList: function() {
      return this.listOutput && this.listOutput.data.data ? this.listOutput.data.data : [];
    },
  },
    methods: {
    ...mapActions("posts", ["getList"]),
  },
  mounted() {
    const itemsPerPage = 10;
    const currentPage = 1;
    const searchText = '';
    const sorter = 2; // desc
    const propertyName = "id";

    var input = new InputGetList(itemsPerPage, currentPage, searchText, sorter, propertyName);
     // List specific parameters
    input.visible= true;
    this.getList(input);
  },
}   
</script>

<style scoped>
.blog-slider
{
  overflow: hidden;
}
section,
.title-container
{
  background-color: #EACCC0 !important;
}

.title-container
{
  z-index: 2;
  margin-right: 5rem;
}

.title-container h1,
.title-container button
{
  background-color: white;
  color: #962422;
}
.blog-title{
  font-weight: 900;
}

/* Sneak peak of next post */
.vueperslides {padding-right: 5em; height: min-content; } 
.vueperslide:not(.vueperslide--visible)
{
  opacity: 0.2;
}

/deep/.vueperslides__parallax-wrapper,
/deep/.vueperslides__track 
{
  overflow: visible !important;
}
[class^="icon-left"], [class*="icon-right"] {
    font-size: 100px;
    line-height: 65px;
}
.btn-icon{
  border: none;
  width: 98px !important;
  height: 50px !important;
  margin: 0 2px;
  cursor: pointer !important;
}
@media screen and (min-width: 991px) 
{
  .card[data-v-00c03554]:hover{
    transform: scale(1.01);
  }
}
@media screen and (max-width: 550px) {
  .blog-slider{
    flex-direction: column;
  }
  .title-container{
    padding-bottom: 1rem;
  }
  h1{
    margin: 0 2px!important;
  }
  #buttons{
    padding: 0;
    margin: 10px auto;
  }
}
</style>
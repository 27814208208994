import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from '@/helpers/i18n';
import { API_HELPER } from "@/helpers/api.js";

// JQuery
import 'jquery/src/jquery.js'

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

Vue.component('vue-phone-number-input', VuePhoneNumberInput);

// Vuelidate
import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)

//Crisp chat
import CrispChat from '@dansmaculotte/vue-crisp-chat'
Vue.use(CrispChat, {
  websiteId: process.env.VUE_APP_CRISP_ID
})
// Stripe
import { StripePlugin } from '@vue-stripe/vue-stripe';

// Vue Meta
import VueMeta from 'vue-meta'
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
})

const options = {
  pk: process.env.VUE_APP_STRIPE_PK,
  stripeAccount: process.env.STRIPE_ACCOUNT,
  apiVersion: process.env.STRIPE_API_VERSION,
  locale: i18n.locale,
  fonts: [
    {
      src: "https://fonts.googleapis.com/css?family=Ruda",
      family: 'Ruda',
      style: 'normal',
    },
  ],
};
Vue.use(StripePlugin, options);

Vue.config.productionTip = false;

// Update cart on refresh
window.addEventListener("load", onWindowLoad);
function onWindowLoad()
{
  store.dispatch('cart/getList', null, { root: true });
}

// Scroll to top on route change
router.beforeEach((to, from, next) => {
  console.log(to);
  console.log(from);

  // cancel pending requests and reset controller
  API_HELPER.abortController.abort();
  API_HELPER.abortController = new AbortController();
  
  // Scroll to top between each route change
  window.scrollTo(0, 0);

  // Block logged in only pages pages
  const loggedIn = localStorage.getItem('user');
  const privatePages = ['/profile'];
  const authRequired = privatePages.includes(to.path);

  if (authRequired && !loggedIn) {
    next('/Login');
  }
  
  next();
})

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");

<template>
    <div class="text-options-selector justify-content-center row">
        <div class="text-option position-relative cursor-pointer" v-for="option in options" :key="option.key" @click="onOptionClicked(option.key)" :class="{'not-selected': selected != null && selected != option.key}">
            {{option.label}}
            <div class="overlay"></div>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        options:
        {
            type: Array,
            default: () => [] // { key: '', label: '' }
        },
        value: String
    },  
    data: function () {
        return {
            selected: this.value ?? null
        };
    },
    methods: 
    {
        onOptionClicked: function(key)
        {
            this.selected = key;
            this.$emit('input', key);
            this.$emit('option-clicked');
        },

    }
}
</script>

<style scoped>
.text-option
{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
    border: 3px solid white;
    border-radius: 12px;
    text-align: center;
    color: white;
    font-size: 20px;
    padding: 1rem;
    height: 100px;
    width: 100%;
    margin: 0.5rem;

    background: linear-gradient(to top, rgb(26, 25, 25), #a3161c);
}

@media screen and (min-width: 1200px) {
    .text-option
    {
        margin: 1%;
        width: 48%;
    }
}

.text-option.not-selected
{
    opacity: 0.5;
}

.overlay
{
    background-color: white;
    opacity: 0.2;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    visibility: hidden;
}

.text-option:hover .overlay
{
    visibility: visible;
}

</style>
<template>
    <section>
        <form action="" @submit.prevent="handleMessageSubmit">
            <div class="form-popup container-fluid">
                <div v-bind:class="{'form-content': isNotProfileCompleted, 'form-content-logged': !isNotProfileCompleted, 'validationHeight': isNotProfileCompleted && submitted && this.$v.$invalid}">
                    <div class="row">
                        <div class="col-lg-11 col-md-11 col-sm-11 col-10 mt-3">
                            <h2 class="text-light d-flex justify-content-center">{{$t("enquirePopup.title")}}</h2>
                        </div>
                        <div class="col-lg-1 col-md-1 col-sm-1 col-2 mt-2">
                            <div class="close-button cursor-pointer d-flex w-100">
                                <p @click="closeClicked"><img src="@/assets/close.svg" class="w-100"/></p>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-2" v-if="isNotProfileCompleted">
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <label class="text-light" for="inputName">{{$t("profile.name")}}</label>
                            <input class="form-control" id="inputName" v-model="firstName" :class="{ 'is-invalid': submitted && hasError('FirstName') }"/>
                            <div v-if="submitted && hasError('FirstName')" class="invalid-feedback">
                                <span v-if="!$v.firstName.required">{{$t("profile.valName")}}</span>
                                <span v-if="errors.FirstName">{{errors.FirstName}}</span>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <label class="text-light" for="inputLastName">{{$t("profile.lastName")}}</label>
                            <input class="form-control" id="inputLastName" v-model="lastName" :class="{ 'is-invalid': submitted && hasError('LastName') }"/>
                            <div v-if="submitted && hasError('LastName')" class="invalid-feedback">
                                <span v-if="!$v.lastName.required">{{$t("profile.valLastName")}}</span>
                                <span v-if="errors.LastName">{{errors.LastName}}</span>
                            </div>
                        </div>
                    </div>

                    <div class="row mt-2" v-if="isNotProfileCompleted">
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <label class="text-light" for="inputContactNumber">{{$t("profile.contactNumber")}}</label>
                            <VuePhoneNumberInput @update="phoneNumberCode = $event.countryCode" :default-country-code="phoneNumberCode" id="inputContactNumber" v-model="phoneNumber"/>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <label class="text-light" for="inputEmail">{{$t("profile.email")}}</label>
                            <input class="form-control" id="inputEmail" v-model="email" :class="{ 'is-invalid': submitted && hasError('Email') }"/>
                            <div v-if="submitted && hasError('Email')" class="invalid-feedback">
                                <span v-if="!$v.email.required">{{$t("profile.valEmail")}}</span>
                                <span v-if="errors.Email">{{errors.Email}}</span>
                            </div>
                        </div>
                    </div>

                    <div class="row mt-2" v-if="isNotProfileCompleted">
                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                            <label class="text-light" for="inputGender">{{$t("profile.gender")}}</label>
                            <select class="form-control" id="inputGender" v-model="gender">
                                <option value='Male'>{{$t("profile.male")}}</option>
                                <option value='Female'>{{$t("profile.female")}}</option>
                                <option value='NotSure'>{{$t("profile.notSure")}}</option>
                            </select>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                            <label class="text-light" for="inputBirthDate">{{$t("profile.birthDate")}}</label>
                            <input type="date" class="form-control" id="inputBirthDate" v-model="birthDate"/>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                            <label class="text-light" for="inputCountry">{{$t("enquirePopup.country")}}</label>
                            <input class="form-control" id="inputCountry" v-model="country"/>
                        </div>
                    </div>

                    <div class="row mt-2">
                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                            <label class="text-light" for="inputPretendedDate">{{$t("enquirePopup.pDate")}}</label>
                            <input type="date" class="form-control" id="inputPretendedDate" v-model="pretendedDate" :min="tomorrow" :class="{ 'is-invalid': submitted && hasError('PretendedDate') }"/>
                            <div v-if="submitted && hasError('PretendedDate')" class="invalid-feedback">
                                <span v-if="!$v.pretendedDate.required">{{$t("profile.valPretendedDate")}}</span>
                                <span v-if="errors.PretendedDate">{{errors.PretendedDate}}</span>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                            <label class="text-light" for="inputDepartureDate">{{$t("enquirePopup.dDate")}}</label>
                            <input type="date" class="form-control" id="inputDepartureDate" v-model="departureDate" :min="tomorrow" :class="{ 'is-invalid': submitted && hasError('DepartureDate') }"/>
                            <div v-if="submitted && hasError('DepartureDate')" class="invalid-feedback">
                                <span v-if="!$v.departureDate.required">{{$t("profile.valDepartureDate")}}</span>
                                <span v-if="errors.DepartureDate">{{errors.DepartureDate}}</span>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                            <label class="text-light" for="inputNumberPeople">{{$t("enquirePopup.persons")}}</label>
                            <input type="number" class="form-control" id="inputNumberPeople" v-model="people" :class="{ 'is-invalid': submitted && hasError('People') }"/>
                            <div v-if="submitted && hasError('People')" class="invalid-feedback">
                                <span v-if="!$v.people.required">{{$t("profile.valPeople")}}</span>
                                <span v-if="errors.People">{{errors.People}}</span>
                            </div>
                        </div>
                    </div>

                    <div class="row mt-2">
                        <div class="form-group">
                            <label class="text-light" for="conserns">{{$t("enquirePopup.concerns")}}</label>
                            <textarea type="text" rows="5" id="conserns" name="message" class="form-control form-rounded" v-model="content"></textarea>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="d-flex align-items-center">
                            <input type="checkbox" v-model="terms">
                            <label id="textradiobtn" v-html='$t("terms")'></label>
                        </div>
                    </div>

                    <div class="row pb-5">
                        <center>
                            <div class="form-group w-10">
                                <button class="button1" v-bind:class="{disabled: !terms}">
                                    <span v-show="!loading">{{$t("enquirePopup.submit")}}</span>
                                    <span v-show="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    <span v-show="loading" class="sr-only">{{$t("loading")}}</span>
                                </button>
                            </div>
                        </center>
                    </div>

                </div>  
            </div>
        </form>
    </section>
</template>

<script>
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import { mapActions, mapState } from "vuex";
import { required, email, requiredIf } from "vuelidate/lib/validators";
import { UTILS_HELPER } from "@/helpers/utils.js";  

export default {
     data(){
        return{
            submitted: false,
            loading: false,
            firstName:"",
            lastName:"",
            phoneNumberCode: "",
            phoneNumber:"",
            email:"",
            gender:null,
            birthDate:null,
            country:"",
            pretendedDate:"",
            departureDate:"",
            people:"",
            content:"",
            tomorrow: null,
            terms: null
        }  
    },
    validations: {
      firstName: { required: requiredIf(function() { return this.isNotProfileCompleted; }) },
      lastName: { required: requiredIf(function() { return this.isNotProfileCompleted; }) },
      email: { email, required: requiredIf(function() { return this.isNotProfileCompleted; })  },
      pretendedDate: { required },
      departureDate: { required },
      people: { required },
    },
    components: {
        VuePhoneNumberInput
    },
    props: {
        experienceTitle:String
    },
    mounted: function (){
        var date = new Date();
        date.setDate(date.getDate() + 1);
        this.tomorrow = date.toISOString().substr(0, 10);

        // this.birthDate = new Date(this.birthDate).toISOString().substr(0, 10),    
        // this.pretendedDate = new Date(this.pretendedDate).toISOString().substr(0, 10),    
        // this.departureDate = new Date(this.departureDate).toISOString().substr(0, 10)
    },
    methods:
    {
        ...mapActions('messages', ['enquire']),
        ...mapActions({
            clearAlert: 'alert/clear' 
        }),
        closeClicked: function()
        {
            this.$emit("close-request");
        },
        handleMessageSubmit() {
            this.clearAlert();
            this.submitted = true;

            //validation date

             if(this.pretendedDate > this.departureDate)
            {
                return alert(this.$t("InvData"))
            }

            // stop here if form is invalid
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }
            this.loading = true;
            
            const { firstName, lastName, phoneNumber, phoneNumberCode, email, gender, birthDate, pretendedDate, departureDate, people, country, content, experienceTitle, terms} = this;
            const loggedIn = !this.isNotProfileCompleted;
            this.enquire({firstName, lastName, phoneNumber, phoneNumberCode, email, gender, birthDate, pretendedDate, departureDate, people, country, content, loggedIn, experienceTitle, terms});
        },
        hasError(fieldName)
        {
            return this.$v[UTILS_HELPER.convertToCamelCase(fieldName)].$error || Object.prototype.hasOwnProperty.call(this.errors, fieldName);
        },
    },
    computed:
    {
        ...mapState('account', ['user']),
        ...mapState('messages', ['processTask']),
        ...mapState({ 
            alertType: state => state.alert.type,
            alertOutput: state => state.alert.output
        }),
        userInfo: function()
        {
            return this.user && this.user.data ? this.user.data : null;
        },
        errors: function()
        {
            return this.alertOutput && this.alertOutput.Errors ? this.alertOutput.Errors : {};   
        },
        isNotProfileCompleted: function()
        {
            return !this.userInfo || !this.userInfo.profile || !this.userInfo.profile.phoneNumber;
        }
    },
    watch:
    {
        pretendedDate: function(oldVal) 
        {
            if(new Date(this.pretendedDate) > new Date(this.departureDate))
            {
                alert(this.$t("ErrDate"));
                this.pretendedDate = oldVal;
            }
        },
        departureDate: function(oldVal) 
        {
            if(new Date(this.pretendedDate) > new Date(this.departureDate))
            {
                alert(this.$t("ErrDate"));
                this.departureDate = oldVal;
            }
        },        
        action: function()
        {
            this.submitted = false;
        },
        processTask: function(val)
        {
            if(val)
            {
                this.$emit("success-alert-requested");
            }

            if(val != null)
            {
                this.loading = false;
            }
        }
    }
}
</script>

<style scoped>
.invalid-feedback {
    color: white;
}
.close-button p
{
    width: 25px;
    margin-top: 1rem;
}
.form-popup{
  position:fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);  
  z-index: 999;
  margin: 0;
  max-width: unset;
  padding-left: 30vw;
  padding-right: 30vw;
  padding-top: 12vh;
  padding-bottom: 5vh;
  gap: 16px;
}
.form-content{
    background-color: #962422;
    padding: 0 2rem;
    height: 45em;
}
.form-content-logged{
    background-color: #962422;
    padding: 0 2rem;
    height: 35em;
}
.validationHeight{
    height: 52em!important;
}
#textradiobtn{
  color: white;
  margin-left: 0.5rem;
}
>>>input:focus, textarea:focus{
  border-color: rgba(150, 36, 34, 1);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(150, 36, 34, 0.075);
  outline: 0 none;
}
>>>#terms{
    color: rgb(229, 211, 17) !important;
    text-decoration: none;
}
.button1 {
  background-color: #d48a89;
  border: none;
  color: white !important;
  padding: 5px 150px;
  text-align: center;
  text-decoration: none !important;
  display: inline-block;
  font-size: 24px;
  margin: 4px 2px;
  cursor: pointer;
  margin-top: 3rem;
  border: 2px solid white;
}
@media screen and (max-width: 460px) {
  .button1 {
      padding: 5px 50px;
      font-size: 16px;
      
  }
}
@media screen and (max-width: 415px) {
  .button1 {
      margin-top: 0.5rem;
  }
}
@media screen and (max-width: 1455px) {
  .form-popup {
    padding-left: 20vw;
    padding-right: 20vw;
  }
}
@media screen and (max-width: 992px) {
  .form-popup {
    padding-left: 10vw;
    padding-right: 10vw;
  }
}
@media screen and (max-width: 728px) {
  .form-popup {
    padding-left: 5vw;
    padding-right: 5vw;
  }
}
@media screen and (max-width: 669px) {
  .form-popup {
    padding-left: 1vw;
    padding-right: 1vw;
  }
}
@media screen and (max-width: 575px) {
  .form-popup {
    padding-top: 9vh;
    position: fixed;
    overflow-y: scroll;
    }
    .form-content{
        height: 70em;
    }
    .validationHeight{
        height: 85em!important;
    }
    .form-content-logged{
        height: 46em;
    }
}
</style>
<template>
  <div>
    <!-- Loading -->
    <div v-if="loading" class="d-flex flex-column container-fluid mb-2">
      <SkeletonLoading class="col-12 mb-1" style="height: 20vh"/>
      <div class="d-flex col-12 mt-1 mb-1 justify-content-between" style="height: 10vh">
        <SkeletonLoading class="col-3"/>
        <SkeletonLoading class="col-4"/>
        <SkeletonLoading class="col-3"/>
      </div>
      <div class="d-flex col-12 mt-1 justify-content-between" style="height: calc(72vh - 130px)">
        <SkeletonLoading class="col-5"/>
        <SkeletonLoading class="col-5"/>
      </div>
    </div>
    <!-- Main content -->
    <div v-else>
      <!-- Category image -->
      <div class="banner-image">
        <img :src="bannerFile" alt="" />
      </div>
      <!-- Header section (category name, location and sharing) -->
      <div v-if="!isApp" class="bar-section d-flex px-5 py-2 py-lg-0">
        <Sharing class="d-none d-xl-flex mt-4 hidden flex-grow-1"/>
        <div class="d-flex align-items-center justify-content-center content">
          <span>{{ $t("topMenu.ourExperiences") }} > {{categoryNames}} > {{locationNames}}</span>
        </div>
        <div class="d-flex justify-content-end align-items-center flex-grow-1 w-25" id="share-width">
          <Sharing class="mt-2 mt-lg-4"/>
        </div>
      </div>
      <!-- Information -->
      <div class="container-fluid">
        <!-- Row with icon information -->
        <div v-if="!isApp" class="row flex-column align-items-center flex-lg-row" id="icon-info" :style="{padding: '10px 35px'}">
          <!-- Seasons -->
          <div class="col-12 col-lg-4 d-flex justify-content-center justify-content-lg-start seasons flex-wrap">
            <img v-if="inspiration.seasons.includes('Spring')" src="@/assets/Experiences/spring.svg" :style="{marginLeft: '-25px'}" alt="">
            <img v-if="inspiration.seasons.includes('Summer')" src="@/assets/Experiences/summer.svg" alt="" :style="{marginLeft: '-25px'}">
            <img v-if="inspiration.seasons.includes('Autumn')" src="@/assets/Experiences/autumn.svg" alt="" :style="{marginLeft: '-25px'}">
            <img v-if="inspiration.seasons.includes('Winter')"  src="@/assets/Experiences/winter.svg" alt="" :style="{marginLeft: '-25px'}">
          </div>
          <!-- Price and days -->
          <div class="col-12 col-lg-4 d-flex justify-content-around flex-column flex-xl-row flex-wrap" id="price-days">
            <div v-if="inspiration.nights < 999" class="d-flex px-5 align-self-center">
              <span>{{$t("days")}}</span>
              <span class="down">{{inspiration.nights + 1}} / {{inspiration.nights}}</span>
              <span class="down2">{{$t("nights")}}</span>
            </div>
            <div v-if="inspiration.allowAddToCart" class="d-flex px-5 align-self-center">
              <span>{{$t("since")}}</span>
              <span class="down">€{{finalPrice.toFixed(2)}}</span>
              <span class="down2">/{{$t("pax")}}</span>
            </div>
          </div>
          <!-- Extra info (number of people, pdf, send message, favorite) -->
          <div class="col-12 col-lg-4 d-flex justify-content-center justify-content-lg-end experience-header align-items-center">
            <div v-if="inspiration.people < 999">
              <div class="d-flex align-items-center" :style="{marginRight: '-10px'}">
                <span v-if="peopleTypeIcon" class="people-type">
                  <img :src="require(`@/assets/Experiences/${peopleTypeIcon}`)"/>
                </span>
                <span class="people">{{inspiration.people}}</span>
              </div>
            </div>
              <span v-if="inspiration.people < 999" class="people-svg">
                <img :src="require('@/assets/Experiences/people.svg')"/>
              </span>
            <a v-if="pdfFilePath" :href="pdfFilePath" target="_blank">
              <img src="@/assets/Experiences/pdf.svg" alt="" />
            </a>
            <span class="msg-svg cursor-pointer"  @click="showEnquireForm = true">
              <img :src="require('@/assets/Experiences/msg.svg')"/>
            </span>
            <span class="icon-fav2 cursor-pointer" @click="favoriteButtonClicked" :class="{'disabled': loading}" :style="{marginLeft: '13px'}">
              <span class="path1"></span>
              <span class="path2"></span>   
              <span class="path3" v-if="isFavorite"></span>
            </span>
          </div>
        </div>
        <!-- large sizes Images and description -->
        <div class="row d-none d-xl-flex flex-wrap images-description">
          <div class="col-xl-4">
            <div v-if="firstMedia" class="card border-0">
              <img class="cursor-pointer" :src="firstMedia" alt="" loading="lazy" @click="showVideo1 = true"/>
              <FullscreenVideo v-if="(showVideo1) && (inspiration.mediaFiles[0].videoUrl)" @close-request="showVideo1 = false" :src="embedLink(inspiration.mediaFiles[0].videoUrl)"/>
            </div>
            <div v-if="thirdMedia" class="card border-0" :style="{marginTop: '25px'}">
              <img class="cursor-pointer" :src="thirdMedia" alt="" loading="lazy" @click="showVideo3 = true"/>
              <FullscreenVideo v-if="(showVideo3) && (inspiration.mediaFiles[2].videoUrl)" @close-request="showVideo3 = false" :src="embedLink(inspiration.mediaFiles[2].videoUrl)"/>
            </div>
          </div>
          <div class="col-xl-4">
            <div class="card-tall">
              <div id="card-height" class="content card-height">
                <!-- <p id="highlight-height" class="text-center text-warning">{{$t("highlights")}}</p> -->
                <div id="arrow" class="icon-style-arrow" @click="descriptionArrowClicked">
                  <b-icon class="icon-style cursor-pointer" icon="arrow-down"></b-icon>
                </div>
                <h3 id="insp-title-height" class="text-center text-light"> {{inspiration.title}}</h3>
                <div v-if="alreadyOnCart" class="text-center subtitle">{{$t("alreadyOnCart")}} <span class="cursor-pointer" @click="goToCart">{{$t("finishYourPurchase")}}</span></div>
                <div id="card-tall-text"><p v-html="inspiration.description" class="text-center text text-light mt-3"/></div>
              </div>
            </div>
          </div>
          <div class="col-xl-4">
            <div v-if="secondMedia" class="card border-0">
              <img class="cursor-pointer" :src="secondMedia" alt="" loading="lazy" @click="showVideo2 = true"/>
              <FullscreenVideo v-if="(showVideo2) && (inspiration.mediaFiles[1].videoUrl)" @close-request="showVideo2 = false" :src="embedLink(inspiration.mediaFiles[1].videoUrl)"/>
            </div>
            <div v-if="fourthMedia" class="card border-0" :style="{marginTop: '25px'}">
              <img class="cursor-pointer" :src="fourthMedia" alt="" loading="lazy" @click="showVideo4 = true"/>
              <FullscreenVideo v-if="(showVideo4) && (inspiration.mediaFiles[3].videoUrl)" @close-request="showVideo4 = false" :src="embedLink(inspiration.mediaFiles[3].videoUrl)"/>
            </div>
          </div>
        </div>
        <!-- small sizes Images and description -->
        <div class="row d-flex d-xl-none images-description">
          <div class="col-12">
            <div class="card-tall">
              <div class="content">
                <p class="text-center text-warning">{{$t("highlights")}}</p>
                <h3 class="text-center text-light mt-3"> {{inspiration.title}}</h3>
                <div v-if="alreadyOnCart" class="text-center subtitle">{{$t("alreadyOnCart")}} <span class="cursor-pointer" @click="goToCart">{{$t("finishYourPurchase")}}</span></div>
                <div id="card-tall-text"><p v-html="inspiration.description" class="text-center text text-light mt-3"/></div>
              </div>
            </div>
          </div>
          <div class="row p-0 m-0">
            <div class="col-12 col-sm-6 mt-4">
              <div v-if="firstMedia" class="card border-0">
                <img :src="firstMedia" alt="" loading="lazy" @click="showVideo1 = true"/>
                <FullscreenVideo v-if="(showVideo1) && (inspiration.mediaFiles[0].videoUrl)" @close-request="showVideo1 = false" :src="embedLink(inspiration.mediaFiles[0].videoUrl)"/>
              </div>
            </div>
            <div class="col-12 col-sm-6 mt-4">
              <div v-if="secondMedia" class="card border-0">
                <img :src="secondMedia" alt="" loading="lazy" @click="showVideo2 = true"/>
                <FullscreenVideo v-if="(showVideo2) && (inspiration.mediaFiles[1].videoUrl)" @close-request="showVideo2 = false" :src="embedLink(inspiration.mediaFiles[1].videoUrl)"/>
              </div>
            </div>
            <div class="col-12 col-sm-6 mt-4">
              <div v-if="thirdMedia" class="card border-0">
                <img :src="thirdMedia" alt="" loading="lazy" @click="showVideo3 = true"/>
                <FullscreenVideo v-if="(showVideo3) && (inspiration.mediaFiles[2].videoUrl)" @close-request="showVideo3 = false" :src="embedLink(inspiration.mediaFiles[2].videoUrl)"/>
              </div>
            </div>
            <div class="col-12 col-sm-6 mt-4">
              <div v-if="fourthMedia" class="card border-0">
                <img :src="fourthMedia" alt="" loading="lazy" @click="showVideo4 = true"/>
                <FullscreenVideo v-if="(showVideo4) && (inspiration.mediaFiles[3].videoUrl)" @close-request="showVideo4 = false" :src="embedLink(inspiration.mediaFiles[3].videoUrl)"/>
              </div>
            </div>
          </div>
        </div>
        <div class="buttons-align">
          <!-- Booking Conditions -->
          <div class="col button-left" v-if="conditions.length != 0">
          <button class="btn" @click="showBookingConditions = !showBookingConditions">
              <span v-show="!addingCart" class="text-uppercase">{{$t("bookingConditions")}}</span>
              <span v-show="addingCart" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              <span v-show="addingCart" class="sr-only">{{$t("loading")}}</span>
            </button>    
          </div>
          <!-- Add to cart -->
          <div class="col button-right" :class="[(conditions.length != 0 ? 'justify-content-start' : 'justify-content-center')]" v-if="!isApp && inspiration.allowAddToCart">
            <button class="btn" v-bind:class="{disabled: addingCart}" @click="addCart">
              <span v-show="!addingCart" class="text-uppercase">{{$t("addToCart")}}</span>
              <span v-show="addingCart" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              <span v-show="addingCart" class="sr-only">{{$t("loading")}}</span>
            </button>    
          </div>
          <!-- Enquire -->
          <div class="col button-right" :class="[(conditions.length != 0 ? 'justify-content-start' : 'justify-content-center')]" v-else-if="!isApp">
            <button class="btn" v-bind:class="{disabled: addingCart}" @click="showEnquireForm = true">
              <span v-show="!addingCart" class="text-uppercase">{{$t("enquire")}}</span>
              <span v-show="addingCart" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              <span v-show="addingCart" class="sr-only">{{$t("loading")}}</span>
            </button>    
          </div>
        </div>
          <BookingConditions class="mb-5" v-if="showBookingConditions" :conditions="conditions" @close-request="showBookingConditions = false"/>
      </div>
      <div v-if="this.days && this.days.length">
        <!-- Custom arrows -->
        <div class="d-flex">
          <div class="arrow-left">
            <span @click="previousSlide()" class="icon-left"></span>
          </div>
          <!-- <div class="d-none d-lg-flex bg-1"/> -->
          <!-- Details per day -->
          <vueper-slides ref="days" :arrows="false" @slide="slideChanged" class="no-shadow flex-grow-1" :bullets="false" :bullets-outside="false" fixed-height="60px" :visible-slides="days.length < 10 ? days.length : 10" :dragging-distance="70" :breakpoints="{
              1399: { visibleSlides: days.length < 5 ? days.length : 5},
              1199: { visibleSlides: days.length < 4 ? days.length : 4},
              991: { visibleSlides: days.length < 3 ? days.length : 3},
              767: { visibleSlides: days.length < 2 ? days.length : 1},
            }">
            <!-- Day slides -->
            <vueper-slide @click.native="clickSlide(index)" :style="'background-color: #e6e6e6;'" v-for="(day, index) in days" :key="index">
              <template v-slot:content>
                <div @click="selectedDay == index && selectedDay != index  ? selectedDay = -1 : selectedDay = index" class="vueperslide__content-wrapper d-flex flex-column justify-content-center" :class="{'vueperslide--active': selectedDay == index}">
                  <span class="color">{{'Day ' + (index + 1)}}</span>
                  <span class="day-title" v-html="day.title"></span>
                </div>
              </template>
            </vueper-slide>
          </vueper-slides>
          <!-- <div class="d-none d-lg-flex bg-2"/> -->
          <div class="arrow-right">
              <span @click="nextSlide()" class="icon-right"></span>
          </div>  
        </div>
        <!-- Day information -->
        <div v-for="(day, index) in days" :key="index" v-show="selectedDay == index">
          <div class="points-interest d-none d-xl-flex ">
            <!-- Map -->
            <div class="map">
              <div class="shape-border position-relative">
                <iframe width="100%" height="100%" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" 
                  :src="getMapEmbedUrl(day)" title="Main content" name="contents"></iframe>
                <a v-if="isApp" class="position-absolute cursor-pointer" style="width:100%;height:100%;top:0;left:0" :href="getMapUrl(day)"></a>
              </div>
            </div>
            <!-- Image -->
            <div class="image-2">
              <img class="img-fluid" :src="getImageUrl(day.photo)" alt="" loading="lazy"/>
            </div>
            <!-- Details -->
            <div class="details">
              <div class="shape-border">
                <div class="d-flex flex-column w-100 details-height" @scroll.passive="onDayInfoScroll">
                  <p class="text-warning text-end fw-bold day-height" :style="{margin: '28px 30px 18px 30px'}">{{$t("inspirationDetail.day") + (index + 1)}}</p>
                  <h2 class="text-light text-end title-height" :style="{margin: '0 30px 25px 30px', paddingLeft: '30px', overflowWrap: 'anywhere'}" v-html="day.titles[$i18n.locale]"></h2>
                  <div class="description" v-html="day.descriptions[$i18n.locale]"></div>
                </div>
                <div class="icon-day-info">
                  <b-icon class="icon-style cursor-pointer" icon="arrow-down" @click="detailArrowClicked"></b-icon>
                </div>
              </div>
            </div>
          </div>
          <!-- Map/Image/Details for small devices -->
          <div class="d-flex d-xl-none flex-column">
            <div class="map-mobile position-relative" :style="{height: '40vh'}">
              <iframe width="100%" height="100%" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" 
                :src="getMapEmbedUrl(day)" title="Main content" name="contents">
              </iframe>
              <div v-if="isApp" class="position-absolute cursor-pointer" style="width:100%;height:100%;top:0;left:0" :href="getMapUrl(day)"></div>
            </div>
            <div class="image-mobile">
              <img class="img-fluid w-100" :style="{objectFit: 'cover'}" :src="getImageUrl(day.photo)" alt="" />
            </div>
            <div class="description-mobile primary-background p-3 mb-5">
              <h3 class="text-warning text-center py-3">{{'Day ' + (index + 1)}}</h3>
              <h1 class="text-light text-center py-3" v-html="day.titles[$i18n.locale]"></h1>
              <p class="text-light m-0 py-3" v-html="day.descriptions[$i18n.locale]"></p>
            </div>
            <div class="button mb-5" v-if="isApp">
              <a class="btn" :href="getMapUrl(day)">
                <span class="text-uppercase">{{$t("takeMeThere")}}</span>
              </a>    
            </div>
          </div>
        </div>
      </div>
      <!-- FAQ -->
      <div v-if="inspiration.faqs.length" class="container my-5">
        <h3 class="d-flex justify-content-center mb-5 font-bolder">
          {{$t("inspirationDetail.answerQuestions")}}
        </h3>
        <QuestionsAnswers class="mt-5" :items="inspiration.faqs"></QuestionsAnswers>
      </div>
    </div>
    <EnquirePopup v-if="showEnquireForm" @close-request="showEnquireForm = false" :experienceTitle="inspiration.title" @success-alert-requested="onEnquireSuccess"/>
  </div>
</template>

<script>
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";
import QuestionsAnswers from "@/components/QuestionsAnswers.vue";
import SkeletonLoading from "@/components/SkeletonLoading.vue"
import router from '@/router';
import { mapActions, mapState } from "vuex";
import { API_HELPER } from "@/helpers/api.js";
import Sharing from "@/components/Sharing.vue";
import EnquirePopup from "@/components/popups/EnquirePopup.vue";
import BookingConditions from "@/components/popups/BookingConditions.vue";
import { UTILS_HELPER } from "@/helpers/utils.js";
import InputGetList from "@/classes/InputGetList";
import FullscreenVideo from '@/components/FullscreenVideo.vue';

export default {
  metaInfo () {
      return { 
            title: this.inspiration ? `${this.inspiration.title} - Wilddouro` : "Wilddouro", 
            htmlAttrs: {
                lang: this.$i18n.locale,
            },
            meta: 
            [
                {name: 'description', content: this.inspiration && this.inspiration.description ? UTILS_HELPER.htmlToText(this.inspiration.description) : this.$t("vueRoutes.intro")},
                {name: 'keywords', content: this.inspiration && this.inspiration.categories && this.inspiration.categories.length ? this.inspiration.categories.map(category=> category.name).join(", ") : this.$t("vueRoutes.defaultKeywords")},

                // // Twitter Card
                // {name: 'twitter:card', content: "summary"},
                // {name: 'twitter:title', content: this.inspiration ? `${this.inspiration.title} - Wilddouro` : "Wilddouro"},
                // {name: 'twitter:description', content: this.inspiration && this.inspiration.categories && this.inspiration.categories.length ? this.inspiration.categories.map(category=> category.name).join(", ") : this.$t("vueRoutes.defaultKeywords")},
                // {name: 'twitter:image', content: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, "assets/hero/hero.jpg"]) },

                // //Facebook OpenGraph
                // {property: 'og:title', content: this.inspiration ? `${this.inspiration.title} - Wilddouro` : "Wilddouro"},
                // {property: 'og:site_name', content: 'Wild Douro'},
                // {property: 'og:type', content: 'website'},
                // {property: 'og:image', content: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, "assets/hero/hero.jpg"]) },
                // {property: 'og:description', content: this.inspiration && this.inspiration.categories && this.inspiration.categories.length ? this.inspiration.categories.map(category=> category.name).join(", ") : this.$t("vueRoutes.defaultKeywords")}
            ], 
            link: [
                {rel: 'alternate', hreflang: 'en', href: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.$t("vueRoutes.inspirationDetail", 'en')])},
                {rel: 'alternate', hreflang: 'pt', href: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.$t("vueRoutes.inspirationDetail", 'pt')])},
            ]
            
        } 
    },
  components: { VueperSlides, VueperSlide, QuestionsAnswers, Sharing, SkeletonLoading, EnquirePopup, BookingConditions, FullscreenVideo },
  data: function () {
    return {
      inspiration: null,
      selectedDay: 0,
      addingCart: false,
      showEnquireForm: false,
      showBookingConditions: false,
      slide: 0,
      showVideo1: false,
      showVideo2: false,
      showVideo3: false,
      showVideo4: false,
    };
  },
  mounted() 
  {
    if(!this.$route.params.sku)
    {
      router.push({ name: 'Experiences' });
    }
    else
    {
      if(this.$route.params.sku)
      {
        this.inspiration = this.getDetail(this.$route.params.sku);
        this.getDays(this.$route.params.id);
        this.getConditions(this.$route.params.id);
      }

      // Get favorites
      if(!this.listOutput)
      {
        this.updateItemsRequested();
      }
    }
  },
  methods: {
    ...mapActions('cart', ['updateCart']),
    ...mapActions('inspirations', ['getDetail']),
    ...mapActions('inspirations', ['getDays']),
    ...mapActions('inspirations', ['getConditions']),
    ...mapActions('favorites', ['getList']),
    ...mapActions('favorites', ['addFavorite']),
    ...mapActions('favorites', ['removeFavorite']),
    favoriteButtonClicked()
    {
      this.clicked = true;
      
      if(this.isFavorite)
      {
        const self = this;
        this.$emit("warning-alert-requested", { message: this.$t("favorites.removedWarning"), primaryCallback: () =>
        {
          self.removeFavorite(this.inspiration);
        }, primaryButton: "Yes", secondaryButton: "No"});
      }
      else
      {
        this.addFavorite(this.inspiration);
      }
    },
    addCart() {
      this.addingCart = true;
      const product = this.inspiration;
      this.updateCart({product});
    },
    getImageUrl(relativePath)
    {
      return API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, relativePath]); 
    },
    getMapUrl(day)
    {
      return `https://maps.google.com/maps?q=${day.latitude},${day.longitude}&hl=es;z=14`;
    },
    getMapEmbedUrl(day)
    {
      return this.getMapUrl(day) + '&output=embed';
    },
    goToCart()
    {
      router.push({ name: 'Cart'});
    },
    onEnquireSuccess: function()
    {
      this.$emit("success-alert-requested", {message: this.$t("enquireSuccess")});
      this.showEnquireForm = false;
    },
    slideChanged: function(val)
    {
      this.slide = val.currentSlide.index;
      this.selectedDay = val.currentSlide.index;
    },
    hiddenArrow()
    {
      // Description
      if(this.inspirationOutput && document.getElementById('card-height') && document.getElementById('card-tall-text'))
      {
        let cardHeight = document.getElementById('card-height').clientHeight;
        let cardTextHeight = document.getElementById('card-tall-text').clientHeight;

        if(cardTextHeight > cardHeight){
          document.getElementById("arrow").style.display = "inline"; 
        } 
      } 

      // Day
      if(this.inspirationOutput && document.getElementById('card-height') && document.getElementById('card-tall-text'))
      {
        let cardHeight = document.getElementById('card-height').clientHeight;
        let cardTextHeight = document.getElementById('card-tall-text').clientHeight;

        if(cardTextHeight > cardHeight){
          document.getElementById("arrow").style.display = "inline"; 
        } 
      } 
    },
    nextSlide()
    {
      this.$refs.days.next();
      var self = this;
      this.$nextTick(() => {
        self.validateScrollIndicator();
      });
    },
    previousSlide()
    {
      this.$refs.days.previous();
      var self = this;
      this.$nextTick(() => {
        self.validateScrollIndicator();
      });
    },
    clickSlide(index)
    {
      this.$refs.days.goToSlide(index);

      var self = this;
      this.$nextTick(() => {
        self.validateScrollIndicator();
      });
    },
    updateItemsRequested()
    {
      const currentPage = 1;
      const searchText = '';
      const sorter = 1; // asc
      const propertyName = "id";
        
      //Make request
      var input = new InputGetList(this.itemsPerPage, currentPage, searchText, sorter, propertyName);    
      input.visible = true;
      // Get favs
      this.getList(input);    
    },
    validateScrollIndicator()
    {
        let details = document.getElementsByClassName('details-height')[this.slide]?.clientHeight;
        let description = document.getElementsByClassName('description')[this.slide]?.clientHeight;

        // div(details-height) height only with the description
        let result = null;
        let dayHeight = document.getElementsByClassName('day-height')[this.slide]?.clientHeight;
        let titleHeight = document.getElementsByClassName('title-height')[this.slide]?.clientHeight;
        result = dayHeight + titleHeight;
        
        if(description > details - result){
          document.getElementsByClassName("icon-day-info")[this.slide].style.display = "flex"; 
        }
    },
    onDayInfoScroll(e)
    {
      var element = e.srcElement;
      var scrollTop = element.scrollTop;
      if(scrollTop == 0)
      {
        var self = this;
        this.$nextTick(() => {
          self.validateScrollIndicator();
        });
      }
      else
      {
        document.getElementsByClassName("icon-day-info")[this.slide].style.display = "none"; 
      }
    },
    descriptionArrowClicked()
    {
      document.getElementById("card-height").scroll(0, 60);
    },
    detailArrowClicked()
    {
      document.getElementsByClassName("details-height")[0].scroll(0, 60);
    },
    // SAMEORIGIN problem - If you are getting this error for a YouTube video, rather than using the full url use the embed url.
    embedLink: function (link) {
      let genericUrl = "https://www.youtube.com/watch?v=";
      let replaceUrl = "http://www.youtube.com/embed/";

      if(genericUrl){
        return link.replace(genericUrl, replaceUrl); 
      }else{
        return link;
      }
    },
  },
  computed:{
    ...mapState('account', ['user']),
    ...mapState('inspirations', ['inspirationOutput']),
    ...mapState('inspirations', ['dayOutput']),
    ...mapState('inspirations', ['conditionsOutput']),
    ...mapState('cart', ['products']),
    ...mapState('cart', ['processTask']),
    ...mapState('favorites', ['listOutput']),
    ...mapState('favorites', ['addTask']),
    ...mapState('favorites', ['removeTask']),
    isApp: function()
    {
      return this.$route.query.mode == "mobileapp";
    },
    days: function()
    {
      return this.dayOutput && this.dayOutput.data ? this.dayOutput.data : [];
    },
    conditions: function()
    {
      return this.conditionsOutput && this.conditionsOutput.data ? this.conditionsOutput.data : [];
    },
    isFavorite: function()
    {
      // Favorites may have products (logged in) or vouchers/experiences (not logged in)
      return this.listOutput && this.listOutput.data ? this.listOutput.data.find(x=> this.user ? x.id == this.inspiration.productId : x.id == this.inspiration.id) : false;
    },
    loading: function()
    {
       return this.inspirationOutput == null;
    },
    pdfFilePath: function()
    {
      return this.inspiration.pdfFile ? API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.inspiration.pdfFile]) : null;
    },
    firstMedia: function()
    {
      return this.inspiration.mediaFiles.length >= 1 ? this.getImageUrl(this.inspiration.mediaFiles[0].url) : null;
    },
    secondMedia: function()
    {
      return this.inspiration.mediaFiles.length >= 2 ? this.getImageUrl(this.inspiration.mediaFiles[1].url) : null;
    },
    thirdMedia: function()
    {
      return this.inspiration.mediaFiles.length >= 3 ? this.getImageUrl(this.inspiration.mediaFiles[2].url) : null;
    },
    fourthMedia: function()
    {
      return this.inspiration.mediaFiles.length >= 4 ? this.getImageUrl(this.inspiration.mediaFiles[3].url) : null;
    },
    cart: function()
    {
      return this.products ? this.products.data : [];
    },
    alreadyOnCart: function()
    {
      return this.inspiration && this.cart ? this.cart.filter(product => product.id === this.inspiration.productId).length > 0 : false;
    },
    peopleTypeIcon: function()
    {
      var result = ''; // Exactly

      if(this.inspiration)
      {
        switch(this.inspiration.peopleType)
        {
          case 'UpTo': 
          {
            result = "less_than_equal.svg";
            break;
          }
          case 'AtLeast': 
          {
            result = "greater_than_equal.svg";
            break;
          }
        }
      }
      return result;
    },
    categoryNames: function()
    {
      var result = '';

      if(this.inspiration)
      {
        var names = this.inspiration.categories.map(x=> x.name);
        result = names.join(", ");
      }

      return result;
    },
    bannerImage: function()
    {
      var result = '';

      if(this.inspiration && this.inspiration.categories && this.inspiration.categories.length) 
      {
        var relativePath = this.inspiration.categories[0].photo;
        result = API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, relativePath]); 
      }

      return result;
    },
    locationNames: function()
    {
      const self = this;
      return this.inspiration && this.inspiration.locations && this.inspiration.locations.length ? this.inspiration.locations.map(x=> self.$i18n.t(UTILS_HELPER.convertToCamelCase(x))).join(", ") : "";
    },
    company: function()
    {
      return this.user && this.user.data && this.user.data.company ? this.user.data.company : 0;
    },
    discount: function()
    {
      return this.company && this.company.discount ? this.company.discount : 0;
    },
    discountPrice: function()
    {
      return this.inspiration && this.inspiration.finalPrice && this.discount ? this.inspiration.finalPrice * this.discount / 100 : 0;
    },
    finalPrice: function()
    {
      return this.inspiration && this.inspiration.finalPrice && this.company.seeDiscount ? this.inspiration.finalPrice - this.discountPrice : this.inspiration.finalPrice;
    },
    bannerFile: function()
    {
      return this.inspiration && this.inspiration.bannerImageFile ? API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.inspiration.bannerImageFile]) : null
    }
  },
  watch:{
    inspirationOutput: function(val)
    {  
      if(val && val.data)
      {
        this.inspiration = val.data;
        this.getDays(this.inspiration.id);   
        this.getConditions(this.inspiration.id);   
      }
    },
    inspiration: function()
    {
      setTimeout(() => {
        this.hiddenArrow(); // Run code after update on day
        this.clickSlide(this.slide); // The slide was not selected by default 
      }, 500);
    },
    products: function()
    {
      this.addingCart = false;
    },
     addTask: function(val)
    {
      if(val && this.clicked)
      {
        this.$emit("success-alert-requested", {message: this.$t("favorites.added")});
        this.updateItemsRequested();
      }

      if(val != null) this.clicked = false;
    },
    removeTask: function(val)
    {
      if(val && this.clicked)
      {
        this.updateItemsRequested();
      }

      if(val != null) this.clicked = false;
    },
    processTask:
    {
      handler: function(val)
      {
        if(val != null)
        {
          this.$emit("success-alert-requested", { message: this.$t("addedToCart"), primaryButton: this.$t("goToCart"), primaryCallback: this.goToCart, secondaryButton: this.$t("close")});
        }
      },
      deep: true // Watch for changes on the properties
    },
    days: function()
    {
      let self = this;
      this.$nextTick(() => {
        self.validateScrollIndicator();
      });
    }
  }
};
</script>

<style scoped>
.subtitle
{
  font-style: italic;
  font-size: 14px;
  color: white;
}
.subtitle span
{
  text-decoration: underline;
}
.banner-image img {
  width: 100%;
  height: 100%;
  max-height: 150px;
  object-fit: cover;
}
.card img{
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.bar-section {
  background-color: #b3b3b3;
}
.bar-section > #share
{
  width: 25%;
}
/* Share */
/deep/#share .share-block
{
  background-color: rgba(128, 128, 128, 1); 
}
.down {
  font-size: 27px;
}
.down2 {
  margin: 10px 0px 0 1px;
}
.icon-fav2 {
  font-size: 60px;
  height: 70px;
}
.path1:before {
  content: "\e938";
  color: rgb(0, 0, 0);
}
.path2:before {
  content: "\e939";
  margin-left: -0.70703125em;
  color: rgb(255, 255, 255);
}
.path3:before {
  content: "\e93a";
  margin-left: -0.70703125em;
  color: rgb(230, 166, 167);
}
.experience-header .people-type img {
  width: 40px;
}
.people{
  font-size: 25px;
  margin-left: -6px;
}
.images-description{
  padding: 0 35px;
}
.card-tall {
  background-color: #962422;
  height: 100%;
}
.icon-style-arrow{
  position: absolute;
  right: 5%;
  top: 0;
  display: none;
}
.icon-day-info{
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  justify-content: center;
  align-items: center;
  padding: 7rem 1rem 1rem 7rem;
  background: linear-gradient(to top, rgba(255,255,255, 1) -70%, rgba(255,255,255, 0) 90% );
  display: none;
}
.card-tall .text {
  width: 90%;
  margin: 0 auto;
  color: white !important;
}
.card-tall .content {
  padding: 15px 0;
}
.button-right {
  display: flex;
  /* justify-content: flex-start; */
  padding: 40px;
}
.button-left {
  display: flex;
  justify-content: flex-end;
  padding: 40px;
}
.buttons-align{
  display: flex;
  flex-direction: row;
}
.btn {
  background: #962422;
  color: white;
  padding: 5px 100px;
  font-size: 20px;
}
.btn:hover {
  color: white;
  background: #962422 !important;
  opacity: 0.8;
}
.arrow-left,
.arrow-right {
  background-color: #b3b3b3;
  width: 80px;
  height: 60px;
  position: relative;
  cursor: pointer;
}
.arrow-left{
  margin-right: 0.1%;
}
.arrow-right{
  margin-left: 0.1%;
}
.icon-left {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  bottom: -45%;
}
.icon-left:before {
  font-size: 100px;
}
>>> .vueperslides--fixed-height.vueperslides--bullets-outside {
  margin-bottom: 0;
}
>>> .vueperslides__track {
  width: auto;
}
.vueperslide__content-wrapper {
  cursor: pointer;
}
.vueperslide__content-wrapper .color {
  color: #962422;
} 
.icon-right {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  bottom: -45%;
}
.icon-right:before {
  font-size: 100px;
}
.points-interest {
  position: relative;
  justify-content: space-between;
  color: white;
  margin-bottom: 5rem;
}
.map {
  position: relative;
  -webkit-clip-path: ellipse(16vw 30vw at 20% 50%);
  clip-path: ellipse(16vw 30vw at 20% 50%);
  width: 25%;
  height: 40vw;
  background-color: white;
  box-sizing: border-box;   
  z-index: 9;      
}
.map .shape-border {
  position: absolute;
  -webkit-clip-path: ellipse(16vw 30vw at 17% 50%);
  clip-path: ellipse(16vw 30vw at 17% 50%);
  top: 0px;
  left: 0px;
  width: 90%;
  height: 40vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.points-interest .image-2 {
  position: absolute;
  left: 0;
  right: 0;
  height: 40vw;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}
.image-2 img {
  height: 100%;
  object-fit: cover;
}
.details {
  position: relative;
  -webkit-clip-path: ellipse(16vw 30vw at 75% 50%);
  clip-path: ellipse(16vw 30vw at 75% 50%);
  background-color: white;
  width: 25%;
  height: 40vw;
}
.details .shape-border {
  position: absolute;
  -webkit-clip-path: ellipse(16vw 30vw at 77% 50%);
  clip-path: ellipse(16vw 30vw at 77% 50%);
  overflow-y: auto;
  overflow-x:hidden ;
  top: 0;
  right: 0;
  width: 90%;
  height: 40vw;
  background-color: #962422;
  display: flex;
  justify-content: center;
}
.details .shape-border .description{
  text-align: end;
  align-self: center;
  margin: 0 2rem 0 5rem;
}
/* ScrollBar */
/* width */
::-webkit-scrollbar {
  width: 10px;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #b3b3b3; 
  border-radius: 10px;
}
>>>.vueperslide{
  /* width: 160px!important; */
  margin-right: 0.2%!important;
}
.vueperslide--active{
  background-color: #962422!important;
  color: white!important;
}
.vueperslide--active .color
{
  color: yellow!important;
}

.day-title
{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 90%;
}

.hidden-arrow{
  display: none!important;
}
.icon-style{
  color: white;
  font-size: 50px;
  margin-top: 0.5rem;
}
@media screen and (min-width: 1200px) {
  .card-tall,
  .details{
    overflow: hidden;
  }
  .card-tall .card-height,
  .details .details-height {
    overflow-y: scroll;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
  }
  .details .details-height::-webkit-scrollbar,
  .card-tall .card-height::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
  }
}
@media screen and (max-width: 1696px) {
    #price-days{
      justify-content: center!important;
    }
}
@media screen and (max-width: 1100px) {
    #share-width{
    width: unset!important;
  }
}
@media screen and (min-width: 991px) 
{
  .vueperslide__content-wrapper:hover {
    background-color: #962422;
    color: white;
  }
  .vueperslide__content-wrapper:hover .color{
    color: yellow;
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #b3b3b3; 
  }
  .vueperslide:hover > div
  {
    background-color: #962422!important;
    color: white!important;
  }
}
@media screen and (max-width: 991px) {
  .images-description{
    padding: 0;
  }
  .seasons img{
    margin: 0!important;
  }
  #icon-info{
    padding: 0!important;
  }
  .buttons-align{
    flex-direction: column;
    align-items: center;
  }
  .button-right {
    justify-content: center;
  }
  .button-left {
    justify-content: center;
  }
}
@media screen and (max-width: 576px) {
  .bar-section
  {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .arrow {
    width: 120px;
  }
  .icon-left,
  .icon-right{
    left: unset;
  }
  .btn {
    padding: 5px 50px;
  }
  >>>.vueperslide{
    width: 100%!important;
    margin-right: 1%!important;
  }
}
</style>
<template>
    <!-- Based on Vue-Multiselect -->
    <multiselect ref="uniselect" v-model="selectedItem" :options="options" :multiple="false" :close-on-select="true" :clear-on-select="false" :placeholder="placeholder" label="name" track-by="value" :showLabels="false" :searchable="false" :max-height="999999" @open="opened">
        <!-- Add radio buttons -->
        <template slot="option" slot-scope="props">
            <div class="option__desc d-flex align-items-center cursor-pointer">
                <input type="radio" class="option__title cursor-pointer" :checked="selectedItem && selectedItem.value == props.option.value"/>
                <span>{{ props.option.name ? props.option.name : props.option.value }}</span>
            </div>
        </template>
    </multiselect>
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
    components: {
        Multiselect
    },
    props: {
        value: Object,
        options: Array,
        placeholder: String
    },
    data () {
        return {
            selectedItem: this.value ? this.options.find(option => option.value == this.value) : null,
        }
    },
    methods:
    {
        opened()
        {
            // The user may have closed the dropdown without applying
            this.selectedItem = this.value;
        }
    },
    watch: {
        selectedItem: function()
        {
            if(this.selectedItem)
            {
                this.$emit('input', this.selectedItem);
            }
        },
        options: function()
        {
            this.selectedItem = this.value ? this.options.find(option => option.value == this.value) : (this.options.length ? this.options[0] : null);
        },
        value: function(val)
        {  
            this.selectedItem = this.options.find(option => option.value == val);
        }
    },
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.apply-button
{
    background-color: #962422;
    border: none;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
}
input[type='radio']
{
    margin-right: 0.5rem;
}
input[type="radio"] {
  /* remove standard background appearance */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* create custom radiobutton appearance */
  display: inline-block;
  width: 15px;
  height: 15px;
  padding: 2px;
  /* background-color only for content */
  background-clip: content-box;
  border: 2px solid #962422;
  background-color: white;
  border-radius: 50%;
}
/* appearance for checked radiobutton */
input[type="radio"]:checked {
  background-color: #962422;
}
/deep/.multiselect__tags {
    min-height: 30px!important;
    border-radius: 0px!important;
    height: 30px!important;
    padding: 3px 0 3px 10px!important;
}
/deep/.multiselect__content
{
    padding: 6px 12px;
}
/deep/.multiselect__option
{
    line-height: 36px;
    padding: unset;
    min-height: unset;
}
/deep/.multiselect__option--highlight
{
    background: none;
    color: unset;
}

/deep/.multiselect__option--selected
{
    background: unset;
    font-weight: normal;
}

/deep/.multiselect__tags
{
    border: 1px solid #a3161c;
}
/deep/.multiselect__select
{
    height: 30px!important;
}
/deep/.multiselect__select:before
{
    color: #a3161c;
    border-color: #a3161c transparent;
}

/deep/.multiselect__placeholder
{
    display: block !important;
    margin-bottom: 0px!important;
    padding-top: 0px!important;
}
/deep/.multiselect__placeholder,
/deep/.multiselect__tags-wrap
{
    color: #a3161c;
}

/deep/.multiselect__content-wrapper
{
    width: max-content;
    min-width: 100%;
}
</style>
<script>
import { mapState, mapActions } from 'vuex'
import { API_HELPER } from "@/helpers/api.js";

export default {
    methods: 
    {
        ...mapActions('account', ['logout']),
    },
    computed:
    {
        ...mapState({ 
            alertType: state => state.alert.type,
            alertOutput: state => state.alert.output
        }),
    },
    mounted: function()
    {
        if(this.$route.query.username && this.$route.query.token)
        {
            this.login({username: this.$route.query.username, token: this.$route.query.token});
        }
        else
        {
            this.logout()
            var fullUrl = encodeURI(API_HELPER.pathJoin([process.env.VUE_APP_FRONT, "Login"]));
            window.location = fullUrl;
        }
    },
    watch:
    {
        alertType: function(val)
        {
            if(val == "alert-danger")
            {
                this.$router.push("/404");
            }
        }
    }
}
</script>
<template>
    <div class="image-gallery d-flex flex-column">
        <!-- Slides -->
        <vueper-slides ref="gallerySlides" class="slider no-shadow mb-0" :fixed-height="'500px'" :bullets="false" :arrows="false" :touchable="false" :visibleSlides="1" :lazy="true" :slide-ratio="ratio">        
           <vueper-slide v-for="(image, index) in images" :key="index" :image="image">
            </vueper-slide>
        </vueper-slides>
        <!-- Navigation buttons -->
        <div id="btn-icons" class="d-flex justify-content-end mt-2" :style="{'z-index': '1'}">
            <button class="btn-icon" @click="$refs.gallerySlides.previous()"><span class="icon-left"></span></button>
            <button class="btn-icon" @click="$refs.gallerySlides.next()"><span class="icon-right"></span></button>
        </div>
    </div>
</template>

<script>
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";

export default {
  components: {
    VueperSlides,
    VueperSlide
  },
  props: {
    ratio: {
      type: Number,
      default: 21/20
    },
    images: Array
  }, 
}   
</script>
<style scoped>
[class^="icon-left"], [class*="icon-right"] {
    font-size: 100px;
    line-height: 65px;
}
.btn-icon{
  border: none;
  width: 98px !important;
  height: 50px !important;
  margin-left: 5px;
  cursor: pointer !important;
}
>>>.vueperslides__parallax-wrapper{
  padding-bottom: 100%!important;
}
@media (min-width: 1200px) {
  >>>.vueperslides{
    width: 600px;
  }
  >>>.vueperslide{
    width: 600px!important;
    height: 500px!important;
  }
}
@media (max-width: 767px) {
  #btn-icons{
    margin-top: 1.5rem!important;
    margin-bottom: 1.5rem!important;
  }
}
</style>
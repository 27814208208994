import { POSTS_SERVICE } from "@/services/posts.service.js";


const state = { listOutput: null, datesOutput: null, postOutput: null };

const actions = {
    getList({ dispatch, commit }, inputGetPosts) {
        dispatch('alert/clear', null, { root: true });
        commit('listRequest');
        
        POSTS_SERVICE.list(inputGetPosts)
            .then(
                output => {
                    commit('listSuccess', output);
                },
                error => {
                    commit('listFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    getDates({ dispatch, commit }) {
        dispatch('alert/clear', null, { root: true });
        commit('listDatesRequest');
        
        POSTS_SERVICE.listDates()
            .then(
                output => {
                    commit('listDatesSuccess', output);
                },
                error => {
                    commit('listDatesFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    getDetail({ dispatch, commit }, postId) {
        dispatch('alert/clear', null, { root: true });
        commit('detailRequest');
        
        POSTS_SERVICE.get(postId)
            .then(
                output => {
                    commit('detailSuccess', output);
                },
                error => {
                    commit('detailFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
};

const mutations = {
    /* List */
    listRequest(state) {
        state.listOutput = null;
    },
    listSuccess(state, output) {
        state.listOutput = output;
    },
    listFailure(state) {
        state.listOutput = false;
    },
    /* List Dates */
    listDatesRequest(state) {
        state.datesOutput = null;
    },
    listDatesSuccess(state, output) {
        state.datesOutput = output;
    },
    listDatesFailure(state) {
        state.datesOutput = false;
    },
    /* Detail */
    detailRequest(state) {
        state.postOutput = null;
    },
    detailSuccess(state, output) {
        state.postOutput = output;
    },
    detailFailure(state) {
        state.postOutput = false;
    },
};


export const posts = {
    namespaced: true,
    state,
    actions,
    mutations
};
<template>
    <div class="video container d-flex align-items-center">
        <div class="video-content d-flex flex-column align-items-center justify-content-center">
            <!-- Close -->
            <div class="close-button cursor-pointer d-flex w-100 justify-content-end">
                <p @click="closeClicked"><img src="../assets/close.svg" class="w-100"/></p>
            </div>
            <!-- Video -->
            <iframe width="1600" height="720" :src="src + '?rel=0'" title="YouTube video player" controls=0 frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        src: String
    },
    methods:
    {
        closeClicked: function()
        {
            this.$emit("close-request");
        }
    },
}
</script>

<style scoped>
.close-button p
{
    width: 25px;
}

.video{
  position:fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.8);  
  z-index: 9999;
  margin: 0;
  max-width: unset;
  padding-left: 10vw;
  padding-right: 10vw;
  padding-top: 5vw;
  padding-bottom: 5vw;
  gap: 16px;
}
.video-content
{
    height: 100%;
    width: 100%; 
    min-height: 50%;
}
iframe
{
    width: 100%;
    height: 100%;
}
@media (max-width: 991px) {
  .video-content
    {
        height: 50%;
        width: 100%; 
    }
}
@media (max-width: 576px) {
  .video-content
    {
        height: 50%;
        width: 100%; 
    }
    iframe
    {
        height: 50%;
    }
}
</style>
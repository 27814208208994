<template>
  <div class="chat">
    <CrispChat id="crispMethod"/>
  </div>
</template>

<script>
import CrispChat from '@dansmaculotte/vue-crisp-chat'
export default {
  components: {
    CrispChat
  }
}
</script>
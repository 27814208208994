<template>
  <section>
      <div class="container p-3" id="newsletter">
        <div class="d-flex justify-content-center align-items-center gap-4 flex-wrap">
          <div>
              <p class="title">{{ $t("newsletter.title") }}</p>
              <p>{{ $t("newsletter.paragraph") }}</p>
          </div>
          <div>
              <a @click="isShow = !isShow" class="button">{{ $t("newsletter.sign") }}</a>
          </div>
        </div>
        <form v-if="isShow" class="row justify-content-between" action="" @submit.prevent="handleMessageSubmit">
          <div class="col-12 col-lg-3 mt-4 mb-4">
            <input v-model="firstName" type="text" :placeholder=" $t('newsletter.name')" id="first-name" :class="{ 'is-invalid': submitted && hasError('FirstName') }"/>
            <div v-if="submitted && hasError('FirstName')" class="invalid-feedback">
              <span v-if="!$v.firstName.required">{{$t("newsletter.firstName")}}</span>
              <span v-if="errors.FirstName">{{errors.FirstName}}</span>
            </div>
          </div>
          <div class="col-12 col-lg-3 mt-4 mb-4">
            <input v-model="lastName" type="text" :placeholder=" $t('newsletter.lastName')" id="last-name" :class="{ 'is-invalid': submitted && hasError('LastName') }"/>
            <div v-if="submitted && hasError('LastName')" class="invalid-feedback">
              <span v-if="!$v.lastName.required">{{$t("newsletter.lastNameVal")}}</span>
              <span v-if="errors.LastName">{{errors.LastName}}</span>
            </div>            
          </div>
          <div class="col-12 col-lg-4 mt-4 mb-4">
            <input v-model="email" type="text" placeholder="E-mail" id="email" :class="{ 'is-invalid': submitted && hasError('Email') }"/>
            <div v-if="submitted && hasError('Email')" class="invalid-feedback">
              <span v-if="!$v.email.required">{{$t("newsletter.email")}}</span>
              <span v-if="errors.Email">{{errors.Email}}</span>
            </div>
          </div>
          <div class="col-12 col-lg-2 mt-4 mb-4">
            <button class="button w-100" :class="{'disabled': !isCompleted}">
              <span v-show="!loading">{{$t("newsletter.subscribe")}}</span>
              <span v-show="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              <span v-show="loading" class="sr-only">{{$t("loading")}}</span>
            </button> 
          </div>
        </form>
      </div>
  </section>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { required, email } from "vuelidate/lib/validators";
import { UTILS_HELPER } from "@/helpers/utils.js";

export default {
  name: "ToggleDiv",
  data: function() {
    return {
      isShow: this.startOpened,
      firstName: "",
      lastName: "",
      email: "",
      submitted: false,
      loading: false,
    };
  },
  validations: {
    firstName: { required },
    lastName: { required },
    email: { email, required },
  },
  props: {
    startOpened: Boolean,
  },
  computed: {
    ...mapState("account", ["processNewsletter"]),
    ...mapState({
      alertType: (state) => state.alert.type,
      alertOutput: (state) => state.alert.output,
    }),
    errors: function() {
      return this.alertOutput && this.alertOutput.Errors ? this.alertOutput.Errors : {};
    },
    isCompleted() {
      return this.firstName && this.lastName && this.email;
    },
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
    }),
    ...mapActions("account", ["submitNewsletter"]),

    handleMessageSubmit() {
      this.clearAlert();
      this.submitted = true;

      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.loading = true;

      const { firstName, lastName, email } = this;
      this.submitNewsletter({ firstName, lastName, email });
      this.resetForm();
    },
    resetForm() {
      this.submitted = false;
      this.firstName = "";
      this.lastName = "";
      this.email = "";
    },
    hasError(fieldName) {
      return ( this.$v[UTILS_HELPER.convertToCamelCase(fieldName)].$error || Object.prototype.hasOwnProperty.call(this.errors, fieldName));
    },
  },
  watch: {
    startOpened: function(val) {
      this.isShow = val;
    },
    processNewsletter: function(val) {
      if (val != null) {
        this.loading = false;
        if (val) {
          this.$emit("success-alert-requested");
        }
      }
    },
  },
};
</script>

<style scoped>
.title {
  padding: 20px 0;
  color: #962422;
  font-weight: bold;
  font-size: 25px;
  margin-bottom: -1rem;
}
p {
  text-align: right;
}
.button {
  background-color: #962422;
  border: none;
  color: white;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none !important;
  display: inline-block;
  font-size: 20px;
  cursor: pointer;
  height: 40px;
}
>>> input:focus,
textarea:focus {
  border-color: rgba(150, 36, 34, 1);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset,
    0 0 8px rgba(150, 36, 34, 0.075);
  outline: 0 none;
}
a {
  color: white !important;
}
input[type="text"] {
  border: 2px solid #962422;
  height: 40px;
  width: 100%;
}
::placeholder {
  color: rgb(226, 167, 119);
  opacity: 0.6;
}
@media screen and (max-width: 576px) {
  .title {
    margin-bottom: 0;
    text-align: center;
  }
  p {
    text-align: center;
  }
}
</style>
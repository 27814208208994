export default class TailorMadeInfo
{
    constructor()
    {
        this.arrivalDate = "";
        this.departureDate = "";
        this.flexibleDates = false;
        this.people = null;
        this.children = null;
        this.locations = [];
        this.inspirations = [];
        this.accommodations = [];
        this.budget = null;
        this.additionalInfo = "";
        this.firstName = "";
        this.lastName = "";
        this.email = "";
        this.nationality = "";
    }
}
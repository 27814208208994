<template>
  <div class="container-fluid">
    <section>
      <div id="backgroundcolor">
          <div class="row" id="tailor-made-experience">
            <div class="d-flex justify-content-center" @click="isShow = !isShow">
                <p class="title text-light p-0 m-0 align-self-center">{{ $t("tailorMade.title") }}
                <img src="../assets/arrow_right.svg" class="img-fluid cursor-pointer"/>

                </p>
            </div>
          </div>
      </div> 
      <div id="root-container" class="row m-0 justify-content-center" v-if="isShow">
        <!-- Top row -->
        <div class="col-12 col-xl-2 mt-4">
        <div class="row">
          <div id="dates-container" class="col-12 col-sm-6 col-xl-12">
            <!-- Arrival date -->
            <div class="row mb-3">
              <div class="col-12">
                <center>
                  <p class="subsection-title mb-0">{{ $t("tailorMade.arrival") }}</p>
                  <datepicker v-model="info.arrivalDate" placeholder="DD-MM-YYYY" :disabled-dates="disabledDates" :class="{ 'is-invalid': submitted && hasError('arrivalDate') }"></datepicker>
                  <div v-if="submitted && hasError('arrivalDate')" class="invalid-feedback">
                    <span v-if="!$v.info.arrivalDate.required">{{$t("tailorMade.valArrivalDate")}}</span>
                    <span v-if="errors.ArrivalDate">{{errors.ArrivalDate}}</span>
                  </div>
                </center>
              </div>
            </div>
            <!-- Departure date -->
            <div class="row mb-3">
              <div class="col-12">
                <center>
                  <p class="subsection-title mb-0">{{ $t("tailorMade.departure") }}</p>
                  <datepicker v-model="info.departureDate" placeholder="DD-MM-YYYY" :disabled-dates="disabledDates" :class="{ 'is-invalid': submitted && hasError('departureDate') }"></datepicker>
                  <div v-if="submitted && hasError('departureDate')" class="invalid-feedback">
                    <span v-if="!$v.info.departureDate.required">{{$t("tailorMade.valDepartureDate")}}</span>
                    <span v-if="errors.DepartureDate">{{errors.DepartureDate}}</span>
                  </div>
                </center>
              </div>
            </div>
            <!-- Flexibility dates -->
            <div class="row">
                <div class="col-12">
                    <label class="cursor-pointer d-flex justify-content-center align-items-center gap-2 text-radio"><input type="checkbox" id="flexibility-dates" name="flexibility-dates" value="flexibility-dates" v-model="info.flexibleDates" :class="{ 'is-invalid': submitted && hasError('flexibleDates') }">{{ $t("tailorMade.flex") }}</label>
                    <div v-if="submitted && hasError('flexibleDates')" class="invalid-feedback">
                      <span v-if="!$v.info.flexibleDates.required">{{$t("tailorMade.valFlexibleDates")}}</span>
                      <span v-if="errors.FlexibleDates">{{errors.FlexibleDates}}</span>
                    </div>
                </div>
            </div>
          </div> 
          <div id="people-container" class="col-12 col-sm-6 col-xl-12">
            <!-- Total of persons -->
            <div class="row mb-3">
              <div class="col-12">
                <center>
                  <p class="subsection-title mb-0">{{ $t("tailorMade.Tpers") }}</p>
                  <input type="number" v-model="info.people" :class="{ 'is-invalid': submitted && hasError('People') }">
                  <div v-if="submitted && hasError('People')" class="invalid-feedback">
                    <span v-if="!$v.info.people.required">{{$t("tailorMade.valPeople")}}</span>
                    <span v-if="errors.People">{{errors.People}}</span>
                  </div>
                </center>
              </div>
            </div>
            <!-- Total of children -->
            <div class="row">
              <div class="col-12">
                <center>
                  <p class="subsection-title mb-0">{{ $t("tailorMade.chil") }}</p>
                  <input type="number" v-model="info.children" :class="{ 'is-invalid': submitted && hasError('Children') }">
                  <div v-if="submitted && hasError('children')" class="invalid-feedback">
                    <span v-if="!$v.info.children.required">{{$t("tailorMade.valChildren")}}</span>
                    <span v-if="errors.Children">{{errors.Children}}</span>
                  </div>
                </center>
              </div>
            </div>
          </div>
          </div>
        </div>
        <hr class="d-xl-none">
        <!-- Locations -->
        <div id="location-container" class="col-12 col-sm-6 col-xl-3 mt-4 borderleft">
          <p class="subsection-title" :class="{ 'is-invalid': submitted && hasError('Locations') }">{{ $t("tailorMade.desLoc") }}</p>
          <div v-for="location in locations" :key="location.id">
            <label class="cursor-pointer d-flex align-items-center gap-2 text-radio my-1"><input type="checkbox" :id="location.name" :value="location.name" v-model="info.locations">{{location.name}}</label>
          </div>
          <div v-if="submitted && hasError('Locations')" class="invalid-feedback">
            <span v-if="!$v.info.locations.required">{{$t("tailorMade.valLocation")}}</span>
            <span v-if="errors.Location">{{errors.ChooseLocation}}</span>
          </div>
        </div>
        <hr class="d-sm-none">
        <!-- Inspirations -->
        <div id="inspiration-container" class="col-12 col-sm-6 col-xl-2 mt-4 borderleft"> 
          <p class="subsection-title">{{ $t("tailorMade.insp") }}</p>
          <div v-for="inspiration in inspirations" :key="inspiration.id">
            <label class="cursor-pointer d-flex align-items-center gap-2 text-radio my-1"><input type="checkbox" :id="inspiration.name" :value="inspiration.name" v-model="info.inspirations">{{inspiration.name}}</label>
          </div>
        </div>
        <hr class="d-xl-none">
        <!-- Accommodations -->
        <div id="accommodation-container" class="col-12 col-sm-6 col-xl-2 mt-4 borderleft">
          <p class="subsection-title">{{ $t("tailorMade.accType") }}</p>
          <div v-for="accommodation in accommodations" :key="accommodation.id">
            <label class="cursor-pointer d-flex align-items-center gap-2 text-radio my-1"><input type="checkbox" :id="accommodation.name" :value="accommodation.name" v-model="info.accommodations">{{accommodation.name}}</label>
          </div>
        </div>
        <hr class="d-sm-none">
        <!-- Budget -->
        <div id="budget-container" class="col-12 col-sm-6 col-xl-2 mt-4 borderleft">
          <p class="subsection-title" :class="{ 'is-invalid': submitted && hasError('Budget') }">{{ $t("tailorMade.budPer") }}</p>
          <div v-for="(budget, i) in budgets" :key="i">
            <label class="cursor-pointer d-flex align-items-center gap-2 text-radio my-1"><input type="radio" class="text-radio-round" :value="budget" v-model="info.budget">{{budget}}</label>
          </div>
          <div v-if="submitted && hasError('Budget')" class="invalid-feedback">
            <span v-if="!$v.info.budget.required">{{$t("tailorMade.valBudget")}}</span>
            <span v-if="errors.Budget">{{errors.Budget}}</span>
          </div>
        </div>
        <!-- Middle row -->
        <hr>
        <p class="col-12" id="text-above-big-input">{{ $t("tailorMade.otherInfo") }}</p>
        <div class="col-12 px-sm-3 px-xl-4">
          <textarea class="w-100" type="text" :placeholder='$t("tailorMade.aler")' v-model="info.additionalInfo"></textarea>
        </div>
        <!-- Bottom row -->
        <div class="justify-content-between col-12 row px-sm-3 px-xl-4 my-1">
          <!-- Name -->
          <div class="col-12 col-lg-2 my-2 p-0">
            <input type="text" class="w-100" :placeholder='$t("profile.name")'  id="first-name" v-model="info.firstName" :class="{ 'is-invalid': submitted && hasError('FirstName') }">
            <div v-if="submitted && hasError('FirstName')" class="invalid-feedback">
                <span v-if="!$v.info.firstName.required">{{$t("tailorMade.valName")}}</span>
                <span v-if="errors.FirstName">{{errors.FirstName}}</span>
            </div>
          </div>
          <!-- Last name -->
          <div class="col-12 col-lg-2 my-2 p-0">
            <input type="text" class="w-100" :placeholder='$t("profile.lastName")' id="last-name" v-model="info.lastName"  :class="{ 'is-invalid': submitted && hasError('LastName') }">
            <div v-if="submitted && hasError('LastName')" class="invalid-feedback">
              <span v-if="!$v.info.lastName.required">{{$t("tailorMade.valLastName")}}</span>
              <span v-if="errors.LastName">{{errors.LastName}}</span>
            </div>
          </div>
          <!-- Email -->
          <div class="col-12 col-lg-2 my-2 p-0">
            <input type="text" class="w-100" placeholder="E-mail" id="email" v-model="info.email" :class="{ 'is-invalid': submitted && hasError('Email') }">
            <div v-if="submitted && hasError('Email')" class="invalid-feedback">
              <span v-if="!$v.info.email.required">{{$t("tailorMade.valEmail")}}</span>
              <span v-if="!$v.info.email.email">{{$t("login.invEmail")}}</span>
              <span v-if="errors.Email">{{errors.Email}}</span>
            </div>
          </div>
          <!-- Nationality -->
          <div class="col-12 col-lg-2 my-2 p-0">
            <input type="text" class="w-100" :placeholder='$t("tailorMade.nat")' id="nacionality" v-model="info.nationality">           
          </div>
          <!-- Terms and conditions -->
          <div class="d-flex justify-content-end col-12 mt-2">
            <div class="d-flex gap-2 align-items-center">
                <input type="checkbox" v-model="terms">
                <label id="textradiobtn" v-html='$t("termsTailor")'></label>
            </div>
          </div>
          <!-- Submit -->
          <div class="d-flex justify-content-end">
            <button class=" col-12 col-lg-2 my-2 button1" v-bind:class="{disabled: !terms}" @click="submitClicked">
              <span v-if="!loading" class="text-uppercase">{{ $t("tailorMade.submit") }}</span>
              <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              <span v-if="loading" class="sr-only">{{$t("loading")}}</span>
            </button>   
          </div>
        </div>
        <!-- <p id="success-message">{{ $t("tailorMade.info") }}</p> -->
      </div>
    </section>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { required, email } from "vuelidate/lib/validators";
import { UTILS_HELPER } from "@/helpers/utils.js";
import TailorMadeInfo from "@/classes/TailorMadeInfo";
import Datepicker from 'vuejs-datepicker';

export default {
    name: "ToggleDiv",
    components: {
      Datepicker,
    },
    data: function () {
    return {
      info: new TailorMadeInfo(),
      //
      terms: null,
      loading: false,
      submitted: false,
      isShow: this.startOpened,
      disabledDates: {
        to: new Date()
      },
      locations: [
        {name: this.$t("tailorMade.north")},
        {name: this.$t("tailorMade.center")},
        {name: this.$t("tailorMade.lisbon")},
        {name: this.$t("tailorMade.alentejo")},
        {name: this.$t("tailorMade.algarve")},
        {name: this.$t("tailorMade.acores")},
        {name: this.$t("tailorMade.madeira")}
      ],
       inspirations: [
         {name: this.$t("tailorMade.food")},
         {name: this.$t("tailorMade.roads")},
         {name: this.$t("tailorMade.events")},
         {name: this.$t("tailorMade.life")},
         {name: this.$t("tailorMade.heritage")},
         {name: this.$t("tailorMade.kids")},
         {name: this.$t("tailorMade.nature")},
         {name: this.$t("tailorMade.spa")},
         {name: this.$t("tailorMade.surf")},
         {name: this.$t("tailorMade.work")}
      ],
       accommodations: [
        {name: this.$t("tailorMade.specHotel")},
        {name: this.$t("tailorMade.hotel")},
        {name: this.$t("tailorMade.apart")},
        {name: this.$t("tailorMade.resorts")},
        {name: this.$t("tailorMade.villas")},
        {name: this.$t("tailorMade.inn")},
        {name: this.$t("tailorMade.glamping")},
        {name: this.$t("tailorMade.estates")}
      ],
       budgets: [
        "< € 1.000",
        "€ 1.000 - € 3.000",
        "€ 3.000 - € 5.000",
        "€ 5.000 - € 7.000",
        "€ 7.000 - € 9.000",
        "> € 9.000",
      ]
    };
  },
  validations: {
    info: {
      arrivalDate: { required },
      departureDate: { required },
      flexibleDates: { required },
      people: { required },
      children: { required },
      locations: { required },
      // inspirations: { required },
      // accommodations: { required },
      budget: { required },
      firstName: { required },
      lastName: { required },
      email: { required, email },
      // nationality: { required }
    },
  },
  props: {
    msg: String,
    startOpened: Boolean
  },
  computed:
  {
    ...mapState('messages', ['processTask']),
    ...mapState({ 
      alertType: state => state.alert.type,
      alertOutput: state => state.alert.output
    }),
    errors: function()
    {
        return this.alertOutput && this.alertOutput.Errors ? this.alertOutput.Errors : {};   
    },
    disabledButton:function(){
      return this.info.arrivalDate && this.info.departureDate && this.info.budget && this.info.firstName && this.info.lastName && this.info.email ? false : true;
    },
  },
  methods:
  {
    ...mapActions("messages", ["send"]),
    ...mapActions({
      clearAlert: 'alert/clear' 
    }),
    submitClicked: function()
    {
      this.clearAlert();
      this.submitted = true;

      // stop here if form is invalid
      this.$v.info.$touch();

      if (this.$v.info.$invalid) {
        return;
      }

      this.loading = true;
      this.send(this.info);
      this.resetForm();
    },
    resetForm(){
      this.submitted = false;
      this.info = new TailorMadeInfo();
    },
    hasError(fieldName)
    {
      return this.$v.info[UTILS_HELPER.convertToCamelCase(fieldName)].$error || Object.prototype.hasOwnProperty.call(this.errors, fieldName);
    }
  },
  watch:
  {
    processTask: function(val)
    {
      if(val != null)
      {
        this.loading = false;
        if(val)
        {
          this.$emit("success-alert-requested");
        }
        else{
          this.$emit("error-alert-requested");
        }         
      }    
    },
    startOpened: function(val)
    {
      this.isShow = val;
    }
  },
}
</script>

<style scoped>
section{
  padding: 0 73.5px;
}
#backgroundcolor{
  background-color: #a3161c;
  margin-top: 2rem;
}
.title{
  font-size: 20px;
  text-align: center;
  cursor: pointer;
}
.subsection-title{
  color: #962422;
  font-size: 18px;
  margin-bottom: 0.5rem;
}
::placeholder {
  color: rgb(226, 167, 119);
  opacity: 0.6;
}
input[type="radio"],
input[type="checkbox"]{
  /* remove standard background appearance */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* create custom radiobutton appearance */
  display: inline-block;
  width: 15px;
  height: 15px;
  padding: 2px;
  /* background-color only for content */
  background-clip: content-box;
  border: 2px solid #962422;
  background-color: unset;
  border-radius: 50%;
}
/* appearance for checked radiobutton */
input[type="radio"]:checked,
input[type="checkbox"]:checked {
  background-color: #962422;
}
>>>textarea:focus , >>>input:focus{
  border-color: rgba(150, 36, 34, 1);
  outline: 0 none;
}
input[type="text"],
input[type="number"]
{
  border: 2px solid #962422;
  height: 40px;
  text-align: center;
  width: 175px;
}
.vdp-datepicker{
  text-align: center;
}
>>>.vdp-datepicker input{
  border: 2px solid #962422!important;
  width: 175px;
  height: 40px;
}
textarea
{
  border: 2px solid #962422;
  height: 80px;
}
#root-container{
  background-color: #EACCC0;
}
.text-radio,
.text-check-box{
  color: #962422;
  font-size: 16px;
}
#flexibility-btn{
  color: #962422;
  margin-left: 0.5rem;
  margin-top: 0.6rem;
}
hr{
  opacity: 1 !important;
  background-color:transparent;
  border: 1px dashed #962422 !important;
  margin: 1rem auto;
  width: 95%;
}
input[type="radio"],
input[type="checkbox"]{
  color: #962422 !important;
  background-color: white;
}
#text-above-big-input{
  color: #962422;
  font-size: 18px;
  text-align: center;
}
.button1 {
  background-color: #962422;
  border: none;
  color: white;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none !important;
  display: inline-block;
  font-size: 20px;
  margin: 4px 2px;
  cursor: pointer;
  height: 40px;
}
#success-message{
  color: #962422;
  font-size: 18px;
  text-align: center;
}
/* Borders */
#dates-container
{
  border-bottom: 2px dotted #962422;
  padding-bottom: 2rem;
}
#people-container
{
  padding-top: 2rem;
}
#textradiobtn{
  color: #962422 !important;
  margin-left: 0.5rem;
}
>>>#termsTailor{
  color: #962422 !important;
}
@media screen and (min-width: 1200px) {
  .borderleft{
    border-left: 2px dotted #962422;
    margin-left: 1rem;
  }
}
@media screen and (max-width: 1199px) and (min-width: 576px) {
  #people-container,
  #inspiration-container,
  #budget-container
  {
    border-left: 2px dotted #962422;
  }

  #dates-container
  {
    border-bottom: unset;
    padding-bottom: 0;
  }

  #people-container
  {
    padding-top: 0
  }
}
@media screen and (max-width: 576px) {
  section{
    padding: 0;
  }
  >>>.vdp-datepicker input{
    width: 100%;
  }
}
</style>
<template>
    <div class="card shadow d-flex flex-column cursor-pointer h-100" @click="clicked">
        <img class="blog-img" :src="imageUrl" alt="Blog image cap">
        <div class="d-flex flex-column p-3 flex-grow-1">
            <h6 class="card-title">{{categories}}</h6>
            <h4 class="font-weight-bold flex-grow-1">{{post.title}}</h4> 
            <div class="d-flex align-items-center justify-content-between">
                <img class="author-thumb" :src="imageAuthor" alt="Card image cap">
                <div class="readtime text-right mt-2">
                    <h6 class="mb-0">{{ $t("readTime") }}</h6>
                    <h6 class="mb-0">{{readTime}}</h6>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { UTILS_HELPER } from "@/helpers/utils.js";
import { API_HELPER } from "@/helpers/api.js";

export default {
  props: {
    post: Object,
  },
  computed:{
      imageUrl: function()
    {
      return this.post && this.post.image ? API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.post.image]) : "";  
    },
    imageAuthor: function(){
      return this.post && this.post.author && this.post.author.photos ? API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.post.author.photos[2].url]) : [];  
    },
     categories: function() {
      return this.post && this.post.categories ? this.post.categories.map(x=> x.name).join(", ") : '';
    },
    readTime: function ()
    {
      return this.post.body ? UTILS_HELPER.readingTime(this.post.body) : "";
    },
  },
  methods: 
  {
    clicked: function()
    {
      this.$router.push({name: "BlogDetail", params: {post: this.post}, query: {slug: this.post.slug} });
    },
  }
}
</script>

<style scoped>
.author-thumb
{
  border-radius: 100%;
  width: 60px;
  height: 60px;
}
.card{
  background-color: white;
  z-index: 2;
}
.card-title,
h6
{
  color: #a3161c;
}
.blog-img{
  height: 193px !important;
  object-fit: cover;
}
@media screen and (min-width: 991px) 
{
  .card:hover
  {
    transform: scale(1.05);
  }
}
</style>
export default class InputGetList
{
  constructor(pageSize, pageNumber, searchText, orderBy, propertyName) 
  {
    this.pageSize = pageSize;
    this.pageNumber = pageNumber;
    this.searchText = searchText;
    this.orderBy = orderBy;
    this.propertyName = propertyName;
  }    
}
<template>
  <section>
    <div class="color-background">
        <div class="container">
            <form action="" @submit.prevent="handleProfileSubmit">
                    <div class="col-lg-12 col-sm-6 d-flex justify-content-center pt-5 w-100">
                        <GalleryPicker v-model="photoUrl" :isPicker="true" :ratioWidth="1" :ratioHeight="1"/>
                    </div>
                <div class="row">
                    <div class="col-12 text-light">
                       <center><span id="span-text">{{profile.firstName}}</span> <span id="span-text">{{profile.lastName}}</span></center>    
                    </div>
                </div>
                <div class="row mt-2">
                    <center>
                        <div class="col-12 text-light">
                            <span><h4>{{profile.company.name}}</h4></span>
                        </div>
                    </center>
                </div>

                <div class="d-flex flex-wrap mt-5" id="radio-input">
                        <div>    
                            <input type="radio" name="radio" :value="false" v-model="isCompanyProfile">
                            <label for="PersonalAccount" id="textradiobtn">{{$t("profile.personalAccount")}}</label>
                        </div>
                        <div>
                            <input type="radio" name="radio" :value="true" v-model="isCompanyProfile">
                            <label for="CorporateAccount" id="textradiobtn">{{$t("profile.corporateAccount")}}</label>
                        </div>
                </div>

                <div class="row mt-5" v-if="isCompanyProfile">
                    <div class="col-lg-5 col-sm-12">
                        <hr class="hrregister">
                    </div>
                    <div class="col-lg-2 col-sm-12">
                        <center>
                            <h4 class="text-light font-weight-bold">{{$t("profile.user")}}</h4>
                        </center>
                    </div>
                    <div class="col-lg-5 col-sm-12">
                        <hr class="hrregister">
                    </div>
                </div>

                <div class="row mt-2">
                    <div class="col-lg-6 col-sm-12">
                        <label class="text-light" for="inputName">{{$t("profile.name")}}</label>
                        <input class="form-control" id="inputName" v-model="profile.firstName"  :class="{ 'is-invalid': submitted && hasProfileError('FirstName') }"/>
                        <div v-if="submitted && hasProfileError('FirstName')" class="invalid-feedback">
                            <span v-if="!$v.profile.firstName.required">{{$t("profile.valName")}}</span>
                            <span v-if="errors.FirstName">{{errors.FirstName}}</span>
                        </div>
                    </div>
                    <div class="col-lg-6 col-sm-12">
                        <label class="text-light" for="inputLastName">{{$t("profile.lastName")}}</label>
                        <input class="form-control" id="inputLastName" v-model="profile.lastName"  :class="{ 'is-invalid': submitted && hasProfileError('LastName') }"/>
                         <div v-if="submitted && hasProfileError('LastName')" class="invalid-feedback">
                            <span v-if="!$v.profile.lastName.required">{{$t("profile.valLastName")}}</span>
                            <span v-if="errors.LastName">{{errors.LastName}}</span>
                        </div>
                    </div>
                </div>

                <div class="row mt-2">
                    <div class="col-lg-6 col-sm-12">
                        <label class="text-light" for="inputContactNumber">{{$t("profile.contactNumber")}}</label>
                        <VuePhoneNumberInput @update="profile.phoneNumberCode = $event.countryCode" :default-country-code="profile.phoneNumberCode" v-model="profile.phoneNumber" id="inputContactNumber" :class="{ 'is-invalid': submitted && hasProfileError('PhoneNumber') }" :error="submitted && hasProfileError('PhoneNumber')"/>
                         <div v-if="submitted && hasProfileError('PhoneNumber')" class="invalid-feedback">
                            <span v-if="!$v.profile.phoneNumber.required">{{$t("profile.valContactNumber")}}</span>
                            <span v-if="errors.PhoneNumber">{{errors.PhoneNumber}}</span>
                        </div>
                    </div>
                    <div class="col-lg-6 col-sm-12">
                        <label class="text-light" for="inputEmail">{{$t("profile.email")}}</label>
                        <input class="form-control" id="inputEmail" v-model="profile.email"  :class="{ 'is-invalid': submitted && hasProfileError('Email') }"/>
                        <div v-if="submitted && hasProfileError('Email')" class="invalid-feedback">
                            <span v-if="!$v.profile.email.required">{{$t("profile.valEmail")}}</span>
                            <span v-if="errors.Email">{{errors.Email}}</span>
                        </div>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-lg-4 col-sm-12">
                        <label class="text-light" for="inputGender">{{$t("profile.gender")}}</label>
                        <select class="form-control" id="inputGender" v-model="profile.gender" :class="{ 'is-invalid': submitted && hasProfileError('Gender') }">
                            <option value='Male'>{{$t("profile.male")}}</option>
                            <option value='Female'>{{$t("profile.female")}}</option>
                            <option value='NotSure'>{{$t("profile.notSure")}}</option>
                        </select>
                         <div v-if="submitted && hasProfileError('Gender')" class="invalid-feedback">
                            <span v-if="!$v.profile.gender.required">{{$t("profile.valGender")}}</span>
                            <span v-if="errors.Gender">{{errors.Gender}}</span>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-12">
                        <label class="text-light" for="inputMarital">{{$t("profile.marital")}}</label>
                        <select class="form-control" id="inputMarital" v-model="profile.maritalStatus" :class="{ 'is-invalid': submitted && hasProfileError('MaritalStatus')}">
                            <option value='Single'>{{$t("profile.single")}}</option>
                            <option value='Married'>{{$t("profile.married")}}</option>
                            <option value='Divorced'>{{$t("profile.divorced")}}</option>
                            <option value='Widower'>{{$t("profile.widower")}}</option>
                            <option value='Complicated'>{{$t("profile.complicated")}}</option>
                        </select>
                        <div v-if="submitted && hasProfileError('MaritalStatus')" class="invalid-feedback">
                            <span v-if="!$v.profile.maritalStatus.required">{{$t("profile.valBirthDate")}}</span>
                            <span v-if="errors.MaritalStatus">{{errors.MaritalStatus}}</span>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-12">
                        <label class="text-light" for="inputBirthDate">{{$t("profile.birthDate")}}</label>
                        <input type="date" class="form-control" id="inputBirthDate" v-model="profile.birthDate" :class="{ 'is-invalid': submitted && hasProfileError('BirthDate') }"/>
                        <div v-if="submitted && hasProfileError('BirthDate')" class="invalid-feedback">
                            <span v-if="!$v.profile.birthDate.required">{{$t("profile.valBirthDate")}}</span>
                            <span v-if="errors.BirthDate">{{errors.BirthDate}}</span>
                        </div>
                    </div>
                </div>

                <div class="row mt-2" v-if="!isCompanyProfile">
                    <div class="col-lg-12 col-sm-12">
                        <label class="text-light" for="inputAddress">{{$t("profile.address")}}</label>
                        <input class="form-control" id="inputAddress" v-model="profile.address"  :class="{ 'is-invalid': submitted && hasProfileError('Address') }"/>
                        <div v-if="submitted && hasProfileError('Address')" class="invalid-feedback">
                            <span v-if="!$v.profile.address.required">{{$t("profile.valAddress")}}</span>
                            <span v-if="errors.Address">{{errors.Address}}</span>
                        </div>
                    </div>
                </div>

                <div v-if="isCompanyProfile">
                    <div class="row mt-5">
                        <div class="col-lg-5 col-sm-12">
                            <hr class="hrregister">
                        </div>
                        <div class="col-lg-2 col-sm-12">
                            <center>
                                <h4 class="text-light font-weight-bold">{{$t("profile.company")}}</h4>
                            </center>
                        </div>
                        <div class="col-lg-5 col-sm-12">
                            <hr class="hrregister">
                        </div>
                    </div>

                    <div class="row mt-2">
                        <div class="col-lg-4 col-sm-12">
                            <label class="text-light" for="inputCompanyName">{{$t("profile.companyName")}}</label>
                            <input class="form-control" id="inputCompanyName" v-model="profile.company.name"  :class="{ 'is-invalid': submitted && hasCompanyError('Name') }"/>
                            <div v-if="submitted && hasCompanyError('Name')" class="invalid-feedback">
                                <span v-if="!$v.profile.company.name.required">{{$t("profile.valCompanyName")}}</span>
                                <span v-if="errors.Name">{{errors.Name}}</span>
                            </div>
                        </div>
                        <div class="col-lg-8 col-sm-12">
                            <label class="text-light" for="inputDescription">{{$t("profile.description")}}</label>
                            <input class="form-control" id="inputDescription" v-model="profile.company.description"  :class="{ 'is-invalid': submitted && hasCompanyError('Description') }"/>
                            <div v-if="submitted && hasCompanyError('Description')" class="invalid-feedback">
                                <span v-if="!$v.profile.company.description.required">{{$t("profile.valDescription")}}</span>
                                <span v-if="errors.Description">{{errors.Description}}</span>
                            </div>
                        </div>
                    </div>

                    <div class="row mt-2">
                        <div class="col-12">
                            <label class="text-light" for="inputAddress">{{$t("profile.address")}}</label>
                            <input class="form-control" id="inputAddress" v-model="profile.address" :class="{ 'is-invalid': submitted && hasProfileError('Address') }"/>
                            <div v-if="submitted && hasProfileError('Address')" class="invalid-feedback">
                                <span v-if="!$v.profile.address.required">{{$t("profile.valAddress")}}</span>
                                <span v-if="errors.Address">{{errors.Address}}</span>
                            </div>
                        </div>
                    </div>

                    <div class="row mt-2">
                        <div class="col-lg-6 col-sm-12">
                            <label class="text-light" for="inputMainContact">{{$t("profile.numberMain")}}</label>
                            <VuePhoneNumberInput @update="profile.company.primaryPhoneNumberCode = $event.countryCode" :default-country-code="profile.company.primaryPhoneNumberCode" v-model="profile.company.primaryPhoneNumber" id="inputContactNumber" :class="{ 'is-invalid': submitted && hasCompanyError('PrimaryPhoneNumber') }" :error="submitted && hasCompanyError('PrimaryPhoneNumber')"/>
                            <div v-if="submitted && hasCompanyError('PrimaryPhoneNumber')" class="invalid-feedback">
                                <span v-if="!$v.profile.company.primaryPhoneNumber.required">{{$t("profile.valNumberMain")}}</span>
                                <span v-if="errors.PrimaryPhoneNumber">{{errors.PrimaryPhoneNumber}}</span>
                            </div>
                        </div>
                        <div class="col-lg-6 col-sm-12">
                            <label class="text-light" for="inputOtherContact">{{$t("profile.numberOther")}}</label>
                            <VuePhoneNumberInput @update="profile.company.secondaryPhoneNumberCode = $event.countryCode" :default-country-code="profile.company.secondaryPhoneNumberCode" v-model="profile.company.secondaryPhoneNumber" id="inputContactNumber" :class="{ 'is-invalid': submitted && hasCompanyError('SecondaryPhoneNumber') }" :error="submitted && hasCompanyError('SecondaryPhoneNumber')"/>
                            <div v-if="submitted && hasCompanyError('SecondaryPhoneNumber')" class="invalid-feedback">
                                <span v-if="!$v.profile.company.secondaryPhoneNumber.required">{{$t("profile.valOtherNumber")}}</span>
                                <span v-if="errors.SecondaryPhoneNumber">{{errors.SecondaryPhoneNumber}}</span>
                            </div>
                        </div>
                    </div>

                    <div class="row mt-2">
                        <div class="col-lg-6 col-sm-12">
                            <label class="text-light" for="inputEmail">{{$t("profile.email")}}</label>
                            <input class="form-control" id="inputEmail" v-model="profile.company.email"  :class="{ 'is-invalid': submitted && hasCompanyError('Email') }"/>
                            <div v-if="submitted && hasCompanyError('Email')" class="invalid-feedback">
                                <span v-if="!$v.profile.company.email.required">{{$t("profile.valEmail")}}</span>
                                <span v-if="errors.Email">{{errors.Email}}</span>
                            </div>
                        </div>
                        <div class="col-lg-6 col-sm-12">
                            <label class="text-light" for="inputWebSite">{{$t("profile.website")}}</label>
                            <input class="form-control" id="inputWebSite" v-model="profile.company.website"  :class="{ 'is-invalid': submitted && hasCompanyError('Website') }"/>
                            <div v-if="submitted && hasCompanyError('Website')" class="invalid-feedback">
                                <span v-if="!$v.profile.company.website.required">{{$t("profile.valWebsite")}}</span>
                                <span v-if="errors.Website">{{errors.Website}}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row mt-2">
                    <div class="col-lg-2 col-sm-12">
                        <label class="text-light" for="inputZipCode">{{$t("profile.zipCode")}}</label>
                        <input class="form-control" id="inputZipCode" v-model="profile.zipCode"  :class="{ 'is-invalid': submitted && hasProfileError('ZipCode') }"/>
                        <div v-if="submitted && hasProfileError('ZipCode')" class="invalid-feedback">
                            <span v-if="!$v.profile.zipCode.required">{{$t("profile.valZipCode")}}</span>
                            <span v-if="errors.ZipCode">{{errors.ZipCode}}</span>
                        </div>
                    </div>
                    <div class="col-lg-5 col-sm-12">
                        <label class="text-light" for="inputCity">{{$t("profile.city")}}</label>
                        <input class="form-control" id="inputCity" v-model="profile.city"  :class="{ 'is-invalid': submitted && hasProfileError('City') }"/>
                        <div v-if="submitted && hasProfileError('City')" class="invalid-feedback">
                            <span v-if="!$v.profile.city.required">{{$t("profile.valCity")}}</span>
                            <span v-if="errors.City">{{errors.City}}</span>
                        </div>
                    </div>
                    <!-- <div class="col-lg-5 col-sm-12">
                        <label class="text-light" for="inputCountry">{{$t("profile.country")}}</label>
                        <input class="form-control" id="inputCountry" v-model="profile.country"  :class="{ 'is-invalid': submitted && hasProfileError('Country') }"/>
                         <div v-if="submitted && hasProfileError('Country')" class="invalid-feedback">
                            <span v-if="!$v.profile.country.required">{{$t("profile.valCountry")}}</span>
                            <span v-if="errors.Country">{{errors.Country}}</span>
                        </div>
                    </div> -->
                    <div class="col-lg-5 col-sm-12">
                        <label class="text-light" for="fname">{{ $t("profile.country") }}*</label>
                            <select id="country" name="country" class="form-control" v-model="profile.country">
                                <option v-for="(item, key) in countries" :key="key" :value="item.Name">{{item.Name}}</option>
                            </select>
                        <div v-if="submitted && $v.profile.country.$error" class="invalid-feedback">
                            <span v-if="!$v.profile.country.required">{{$t("profile.valCountry")}}</span>
                        </div>
                    </div>
                </div>

                <div class="row mt-2">
                    <div class="col-lg-4 col-sm-12">
                        <label class="text-light" for="inputVat">{{$t("profile.vat")}}</label>
                        <input type="number" class="form-control" id="inputVat" v-model="profile.vat"  :class="{ 'is-invalid': submitted && hasProfileError('Vat') }"/>
                        <div v-if="submitted && hasProfileError('Vat')" class="invalid-feedback">
                            <span v-if="!$v.profile.vat.required">{{$t("profile.valVat")}}</span>
                            <span v-if="errors.Vat">{{errors.Vat}}</span>
                        </div>
                    </div>
                </div>

                <div class="row mt-5" v-if="isCompanyProfile">
                    <div class="col-lg-5 col-sm-12">
                        <hr class="hrregister">
                    </div>
                    <div class="col-lg-2 col-sm-12">
                        <center>
                            <h4 class="text-light font-weight-bold">{{$t("profile.other")}}</h4>
                        </center>
                    </div>
                    <div class="col-lg-5 col-sm-12">
                        <hr class="hrregister">
                    </div>
                </div>

                 <div class="d-flex mt-2" v-if="discount">
                    <input type="checkbox" v-model="profile.company.seeDiscount">
                    <label id="textradiobtn">{{$t("profile.seePrices")}} <span class="percentage">{{discount}}%</span></label>
                </div>
                <br>
                <div class="d-flex">
                    <input type="checkbox" v-model="acceptTerms"> 
                    <label id="textradiobtn" v-html='$t("terms")'></label>
                </div>
                <br>
                <div class="d-flex">
                    <input type="checkbox" v-model="profile.newsletter"> 
                    <label id="textradiobtn">{{$t("profile.comunic")}}</label>
                </div>
                <div class="row pb-5">
                   <div class="form-group w-100" v-bind:class="{disabled: loading || !acceptTerms}">
                        <button class="button1 w-100">
                            <span v-show="!loading">{{$t("profile.save")}}</span>
                            <span v-show="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            <span v-show="loading" class="sr-only">{{$t("loading")}}</span>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Profile from "@/classes/Profile";
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import GalleryPicker from "@/components/GalleryPicker.vue";
import { required, requiredIf } from "vuelidate/lib/validators";
import { UTILS_HELPER } from "@/helpers/utils.js";
import { API_HELPER } from "@/helpers/api.js";
import countriesJson from '@/helpers/countries.json';

const PERSONAL = "Personal";
const CORPORATE = "Corporate";

export default {
      metaInfo () {
      return { 
            title: `${this.$t("vueRoutes.profileTitle")} - Wild Douro`, 
            htmlAttrs: {
                lang: this.$i18n.locale,
            },
            link: [
                {rel: 'alternate', hreflang: 'en', href: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.$t("vueRoutes.profile", 'en')])},
                {rel: 'alternate', hreflang: 'pt', href: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.$t("vueRoutes.profile", 'pt')])},
            ]
        } 
    },
    components: {
        GalleryPicker,
        VuePhoneNumberInput
    },
    data(){
        return{
            countries: countriesJson,
            profile: new Profile({}),
            submitted: false,
            isCompanyProfile: false,
            loading: false,
            acceptTerms: false,
            photoUrl: Object
        }  
    },
    validations: {
      profile: {
        firstName: { required },
        lastName: { required },
        phoneNumber: { required },
        phoneNumberCode: { required },
        email: { required },
        gender: { required },
        maritalStatus: { required },
        birthDate: { required },
        address: { required },
        zipCode: { required },
        city: { required },
        country: { required },
        vat: { required },
        company:
        {
            description: { required: requiredIf(function() { return this.isCompanyProfile; }) },
            name: { required: requiredIf(function() { return this.isCompanyProfile; }) },
            primaryPhoneNumber: { required: requiredIf(function() { return this.isCompanyProfile; }) },
            primaryPhoneNumberCode: { required: requiredIf(function() { return this.isCompanyProfile; }) },
            secondaryPhoneNumber: { required: requiredIf(function() { return this.isCompanyProfile; }) },
            secondaryPhoneNumberCode: { required: requiredIf(function() { return this.isCompanyProfile; }) },
            website: { required: requiredIf(function() { return this.isCompanyProfile; }) },
            email: { required: requiredIf(function() { return this.isCompanyProfile; }) },
        }
      }
    },
    methods:
    {
        ...mapActions({
            clearAlert: 'alert/clear' 
        }),
        ...mapActions('account', ['updateProfile']),
        handleProfileSubmit() {
            this.clearAlert();
            this.submitted = true;

            // // stop here if form is invalid
            this.$v.profile.$touch();
            if (this.$v.profile.$invalid || !this.acceptTerms) {
                return;
            }

            this.loading = true;

            if(this.isCompanyProfile)
            {
                this.profile.accountType = CORPORATE;
            }
            else
            {
                this.profile.accountType = PERSONAL;
                this.profile.company = {};
            }

            this.profile.photo = this.photoUrl.data && this.photoUrl.mime ? this.photoUrl : null;
            this.updateProfile(this.profile);
        },
        hasProfileError(fieldName)
        {
            return this.$v.profile[UTILS_HELPER.convertToCamelCase(fieldName)].$error || Object.prototype.hasOwnProperty.call(this.errors, fieldName);
        },
        hasCompanyError(fieldName)
        {
            return this.$v.profile.company[UTILS_HELPER.convertToCamelCase(fieldName)].$error || Object.prototype.hasOwnProperty.call(this.errors, fieldName);
        }
    },  
    mounted: function()
    {
        if(!this.userInfo)
        {
            this.$router.push({name: "Login" });
        }

        this.profile = this.userInfo.profile;
        this.profile.birthDate = this.profile.birthDate ? new Date(this.profile.birthDate).toISOString().substr(0, 10) : null;
        this.profile.company = this.userInfo.company ?? {};
        this.isCompanyProfile = Boolean(this.userInfo.company);
        this.photoUrl = this.profile && this.profile.photo ? {url: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.profile.photo])} : [{url: require("@/assets/Profile/ImageDefault.svg")}];
    },
    computed: 
    {
        ...mapState('account', ['user']),
        ...mapState('account', ['status']),
        ...mapState({ 
            alertType: state => state.alert.type,
            alertOutput: state => state.alert.output
        }),
        userInfo: function()
        {
            return this.user && this.user.data ? this.user.data : null;
        },
        discount: function()
        {
            return this.userInfo && this.userInfo.company && this.userInfo.company.discount ? this.userInfo.company.discount : null;
        },
        errors: function()
        {
            return this.alertOutput && this.alertOutput.Errors ? this.alertOutput.Errors : {};   
        }
    },
    watch:
    {
        action: function()
        {
            this.submitted = false;
        },
        alertType: function(val)
        {
            if(val != null)
            {
                this.loading = false;
                    
                if(val == "alert-danger")
                {
                    this.$emit("error-alert-requested", { message: "Something went wrong. Please validate your data and try again." });
                }
            
                else if(val == "alert-success")
                {
                        this.$emit("success-alert-requested", { message: "Your account was updated!"});
                }
            }
                        
        }
    }
}
</script>

<style scoped>
.container{
    max-width: 1000px;
}
.color-background{
    background-color:rgb(133, 42, 33) !important;
}
.hrregister{
    border: 2px solid white !important;
}
hr{
    opacity: 1 !important;
    background-color:#962422 !important;
}
#radio-input{
    gap: 16px;
}
#span-text{
    font-size: 32px;
}
#textradiobtn{
  color: white;
  margin-left: 0.5rem;
  margin-top: -0.3rem;
}
>>>#terms{
    color: rgb(229, 211, 17) !important;
    text-decoration: none;
}
.button1 {
  background-color: #d48a89;
  border: none;
  color: white !important;
  padding: 5px 150px;
  text-align: center;
  text-decoration: none !important;
  display: inline-block;
  font-size: 24px;
  margin: 4px 2px;
  cursor: pointer;
  margin-top: 3rem;
  border: 2px solid white;
}
>>>.input-tel.is-valid .input-tel__input[data-v-e59be3b4], .input-tel.is-valid .input-tel__input[data-v-e59be3b4]:hover{
  border-color: #ced4da !important;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(150, 36, 34, 0.075) !important;
  outline: 0 none !important;
}
>>>#inputContactNumber-53_country_selector{
  border-color: #ced4da !important;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(150, 36, 34, 0.075) !important;
  outline: 0 none !important;
}
>>>#inputContactNumber > div.select-country-container > div > label{
  color: rgba(150, 36, 34, 1) !important;
}
>>>#inputContactNumber > div.flex-1 > div > label{
  color: rgba(150, 36, 34, 1) !important;
}
.percentage{
    background-color: #d48a89;
    padding: 5px 15px;
}
>>>.vueperslides:not(.no-shadow):not(.vueperslides--3d) .vueperslides__parallax-wrapper::before{
    box-shadow: none;
}
>>>.vueperslides:not(.no-shadow):not(.vueperslides--3d) .vueperslides__parallax-wrapper::after, .vueperslides:not(.no-shadow):not(.vueperslides--3d) .vueperslides__parallax-wrapper::before{
    box-shadow: none;
}
@media screen and (max-width: 768px) {
  .button1 {
      padding: 5px 50px;
      font-size: 16px;
  }
}

</style>
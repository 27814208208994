<!-- This component can be used with inspirations and products -->
<template>
    <div class="d-flex flex-column card cursor-pointer">
      <div class="d-flex justify-content-center align-items-center card-image mb-2">
          <img v-if="!loading" :src="imageUrl" class="w-100 h-100" @click="sendClicked"/>
          <SkeletonLoading v-else class="h-100 w-100"></SkeletonLoading>
          <span class="p-2 text position-absolute">{{type}}</span>
          <div @click="sendClicked" :class="{'type-experience': type == EXPERIENCE, 'type-voucher': type == VOUCHER}"></div>
          <span :class="{path: isFavorite}" @click="favoriteButtonClicked" id="icon-fav2">
            <img src="../assets/Fav/fav-02.svg" class="fav" alt="">
          </span>
      </div>
      <div class="d-flex flex-column mt-2 card-text p-3 w-100"  @click="sendClicked">
          <h4>{{ favorite.title }}</h4>
          <p>{{excerpt}}</p>
      </div>
    </div>
</template>

<script>
import { API_HELPER } from "@/helpers/api.js";
import { mapState, mapActions } from "vuex";
import SkeletonLoading from "@/components/SkeletonLoading.vue"
import InputGetList from "@/classes/InputGetList";

const EXCERPT_LENGTH = 100;
const EXPERIENCE = "Experience";
const VOUCHER = "Voucher";

export default {  
  components: {
    SkeletonLoading,
  },
  props: {
    favorite: Object
  },
  data() {
    return {
      clicked: false,
      //
      EXPERIENCE: EXPERIENCE,
      VOUCHER: VOUCHER
    }
  },
  methods: {
    sendClicked () {
      this.$router.push({name: "ExperienceDetail", params: {sku: this.favorite.sku} });
    },
    ...mapActions('favorites', ['getList']),
    ...mapActions('favorites', ['removeFavorite']),
    favoriteButtonClicked()
    {
      this.clicked = true;
      if(this.isFavorite)
      {
        this.$emit("warning-alert", this.favorite);
      }
    },
    updateItemsRequested()
    {
      const currentPage = 1;
      const searchText = '';
      const sorter = 1; // asc
      const propertyName = "id";
        
      //Make request
      var input = new InputGetList(this.itemsPerPage, currentPage, searchText, sorter, propertyName);    
      input.visible = true;
      // Get favs
      this.getList(input);    
    }
  },
  mounted: function()
  {
    if(!this.listOutput)
    {
      this.updateItemsRequested();
    }
  },
  computed:{
    ...mapState('account', ['user']),
    ...mapState('favorites', ['listOutput']),
    ...mapState('favorites', ['removeTask']),
    type: function()
    {
      return this.favorite.type == EXPERIENCE || 'pdfFile' in this.favorite ? EXPERIENCE : VOUCHER; // type check for loggedin, 'pdfFile' check as a fallback for guests
    },
    isFavorite: function()
    {
      // The thumb may be used for inspirations/vouchers or products; it also may be used to represent favorites for logged in or non logged in users, in which cases the saved information changes: favorites may have products (logged in) or vouchers/experiences (not logged in)
      if(this.favorite.productId) // Inspiration || voucher
      {
        if(this.user)
        {
          return this.listOutput && this.listOutput.data ? this.listOutput.data.find(x=> x.id == this.favorite.productId) : false;
        }
        else
        {
          return this.listOutput && this.listOutput.data ? this.listOutput.data.find(x=> x.productId == this.favorite.productId) : false;
        }
      }
      else // Product
      {
        return this.listOutput && this.listOutput.data ? this.listOutput.data.find(x=> x.productId == this.favorite.productId) : false;
      }
    },
    loading: function()
    {
      // return this.clicked && (this.removeTask == null);
      return false;
    },
    imageUrl: function()
    {
      // var relativePath = this.favorite ? this.favorite.mediaFile : this.favorite.mediaFiles[0].url;
      var relativePath = this.favorite.mediaFiles ? this.favorite.mediaFiles[0].url : this.favorite.mediaFile ? this.favorite.mediaFile : this.favorite.bannerImageFile;
      return API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, relativePath]);      
    },
    excerpt: function()
    {
      var result = '';

      if(this.favorite && this.favorite.description)
      {
        var div = document.createElement('div');
        div.innerHTML = this.favorite.description.trim();

        result = div.textContent || div.innerText || "";
        if(result.length > EXCERPT_LENGTH)
        {
          result = result.substring(0, EXCERPT_LENGTH) + "...";
        }
      }

      return result;
    }
  },
  watch: 
  {
    removeTask: function(val)
    {
      if(val && this.clicked)
      {
        this.updateItemsRequested();
      }
      
      if(val != null) this.clicked = false;
    },

  }
}
</script>

<style scoped>
img
{
  object-fit: cover;
}

.card {
  border: 1px solid #962422;
  background: white;
  min-height: 400px;
  overflow: hidden;
}

.card-image
{
  position: relative;
  height: 60%;
}
.text{
  top: 0;
  left: 0;
  z-index: 1;
  color: white;
  margin: -5px auto;
}
.type-experience, .type-voucher{
  position: absolute;
  top: 0;
  left: 0;
  width: 250px;
  border-right: 25px solid transparent;
}
.type-experience
{
    border-top: 30px solid #a3161c;
}
.type-voucher{
  border-top: 30px solid #4D4D4D;
}
.fav{
  width: 35px;
  margin: -5px 5px;
}
#icon-fav2{
  position: absolute;
  top: 0;
  right: 0;
}
.icon-fav2 {
  font-size: 40px;
}
.path1:before {
  content: "\e938";
  color: rgb(0, 0, 0);
}
.path2:before {
  content: "\e939";
  margin-left: -0.70703125em;
  color: rgb(255, 255, 255);
}
.path3:before {
  content: "\e93a";
  margin-left: -0.70703125em;
  color: rgb(255, 255, 255);
}
.card-image .icon {
  position: absolute;
  top: 0;
  right: 0;
}

.card-text
{
  height: 40%;
  overflow: hidden;
}

.card h4 {
  text-align: center;
  font-weight: bold;
}
.card h5 {
  text-align: center;
  color: #962422;
  margin-bottom: 10px;
  font-size: 15px;
}
.card p {
  text-align: center;
  width: 90%;
  /* border: 1px dashed black; */
  margin: 0 auto;
  font-size: 16px;
}

@media screen and (max-width: 1400px) {
  .md-layout-item span{
    left: -60%;
  }
}
@media screen and (max-width: 1140px) {
  .md-layout-item span{
    left: -55%;
    font-size: 13px;
  }
}
@media screen and (max-width: 960px) {
  .md-layout-item span{
    display: none;
  }
}
@media (max-width: 767px) /* Two columns */
{
  .type,
  .type-voucher{
    width: 150px;
  }
}
@media screen and (max-width: 405px) {
  .container-list .card {
    width: 300px;
    height: 400px;
  }
  .card p {
    margin: 25px auto;
    font-size: 13px;
  }
}
</style>
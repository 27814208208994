<template>
<div class="modal d-flex justify-content-center align-items-center">
    <div class="modal-container d-flex flex-column justify-content-center align-items-center text-center" :style="{'background-image': 'url(' + require(`@/assets/Popups/${asset}`) + ')'}">
        <b class="p-2"><h2>{{title}}</h2></b>
        <p class="mb-0 px-5">{{message}}</p>
        <div class="d-flex justify-content-center p-2">
            <button class="mx-2 px-3" @click="primaryButtonClicked">{{primaryButtonText}}</button>
            <button class="mx-2 px-3" @click="secondaryButtonClicked" v-if="secondaryButtonText">{{secondaryButtonText}}</button>
        </div>
    </div>
</div>
</template>

<script>
import i18n from '@/helpers/i18n';

const SUCCESSASSET = "successPopup.svg"
const WARNINGASSET = "warningPopup.svg"
const ERRORASSET = "errorPopup.svg"

const SUCCESS = 0;
const WARNING = 1;
const ERROR = 2;

export default {
    props: {
        type: 
        {
            type: Number,
            default: SUCCESS
        },
        message: String,
        primaryButtonText:
        {
            type: String,
            default: i18n.t("ok")
        },
        primaryButtonCallback: { type: Function },
        secondaryButtonText:
        {
            type: String,
            default: null
        },
        secondaryButtonCallback: { type: Function }
    },
    computed:
    {
        asset: function()
        {
            var result = null;

            switch(this.type)
            {
                case SUCCESS:
                {
                    result = SUCCESSASSET;
                    break;
                }
                case WARNING:
                {
                    result = WARNINGASSET;
                    break;
                }
                case ERROR:
                {
                    result = ERRORASSET;
                    break;
                }
                default:
                {
                    throw("Type is not supported");
                }
            }

            return result;
        },
      title: function()
        {
            var result = null;

            switch(this.type)
            {
                case SUCCESS:
                {
                    result = i18n.t("success");
                    break;
                }
                case WARNING:
                {
                    result = i18n.t("warning");
                    break;
                }
                case ERROR:
                {
                    result = i18n.t("error");
                    break;
                }
                default:
                {
                    throw("Type is not supported");
                }
            }

            return result;
        },
    },
    methods:
    {
        primaryButtonClicked: function()
        {
            this.$emit("primary-click");
            if(this.primaryButtonCallback) this.primaryButtonCallback();

            this.$destroy();
            this.$el.parentNode.removeChild(this.$el);
        },
        secondaryButtonClicked: function()
        {
            this.$emit("secondary-click");
            if(this.secondaryButtonCallback) this.secondaryButtonCallback();

            this.$destroy();
            this.$el.parentNode.removeChild(this.$el);
        }
    }
}
</script>

<style scoped>
.modal
{
    background-color: rgba(0, 0, 0, 0.6);
}

.modal-container
{
    width: 90vw;
    max-width: 400px;
    height: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position-y: center;
}

h1, button
{
    text-transform: uppercase;
}

button
{
    background-color: #4d4d4d;
    color: white;
}
.px-5{
    padding-right: 4rem !important;
    padding-left: 4rem !important;
}

</style>
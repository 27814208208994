<template>
<section>
  <!-- Post -->
  <div v-if="post" class="container-fluid">
    <div class="d-flex">
      <!-- Menu -->
      <div class="col-3 col-lg-2" id="menu-sidebar">
        <BlogMenu @filter-category-requested="filterCategory" @filter-date-requested="filterDate" @filter-author-requested="filterAuthor"/>
      </div>
      <!-- Content -->
      <div class="col-12 col-sm-7 col-lg-8 d-flex flex-column container-fluid blog-content mt-3">
        <!-- Header info (date and category) -->
        <div class="row col-12">
            <p>{{readableDate.toUpperCase()}}  | <a v-for="category in post.categories" :key="category.id" @click="filterCategory(category)">{{category.name}}</a></p>
        </div>
        <!-- Image and sharing -->
        <div class="col-12 position-relative p-0">
          <img class="blog-img" :src="imageUrl">
          <Sharing></Sharing>
        </div>
        <!-- Title -->
        <div class="row mt-5 col-12 m-0">
          <h2 class="text-center title-style font-bolder">{{post.title}}</h2>
        </div>
        <!-- Description -->
        <div class="row body-style col-12 m-0" v-html="post.body"></div>
        <hr>
        <!-- Go back and author info -->
        <div class="row">
          <div class="col-6">
            <router-link :to="{name: 'Blog'}" class="btn d-flex align-items-center"><span class="font-weight-bold icon-slot_arrow_left"></span><span class="font-weight-bold">{{$t("blogDetail.back")}}</span></router-link>
          </div>
          <div class="col-6 mt-2">
            <p class="text-right">{{$t("blogDetail.escrito")}} <span> <a class="cursor-pointer" @click="showAuthorPopUp = !showAuthorPopUp">{{post.author.name}}</a></span></p>
          </div>
        </div>
      </div>
      <!-- Blank column -->
      <div class="col-2" id="empty-sidebar"></div>
    </div>
  </div>
  <!-- Loading -->
  <div v-else class="container vh-100 py-5">
    <SkeletonLoading class="h-100 w-100"/>
  </div>
  <!-- Author pop up -->
  <AuthorPopup v-if="showAuthorPopUp" @close-request="showAuthorPopUp = false" :author="post.author"></AuthorPopup>
</section>
</template>

<script>
import { API_HELPER } from "@/helpers/api.js";
import Sharing from "@/components/Sharing.vue";
import AuthorPopup from "@/components/popups/AuthorPopup.vue";
import BlogMenu from "@/components/BlogMenu.vue";
import { UTILS_HELPER } from "@/helpers/utils.js";
import router from '@/router';
import SkeletonLoading from "@/components/SkeletonLoading.vue"
import { mapActions, mapState } from "vuex";

export default {
    metaInfo () {
      return { 
            title: this.post ? `${this.post.title} - Wild Douro` : "Wild Douro", 
            htmlAttrs: {
                lang: this.$i18n.locale,
            },
            meta: 
            [
                {name: 'description', content: this.$t("vueRoutes.blogDescription")},
                {name: 'keywords', content: this.$t("vueRoutes.defaultKeywords")},
                // Twitter Card
                {name: 'twitter:card', content: "summary"},
                {name: 'twitter:title', content: this.post ? `${this.post.title} - Wild Douro` : "Wild Douro"},
                {name: 'twitter:description', content: this.$t("vueRoutes.blogDescription")},
                {name: 'twitter:image', content: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, "assets/hero/hero.jpg"]) },

                //Facebook OpenGraph
                {property: 'og:title', content: this.post ? `${this.post.title} - Wild Douro` : "Wild Douro"},
                {property: 'og:site_name', content: 'Wild Douro'},
                {property: 'og:type', content: 'website'},
                {property: 'og:image', content: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, "assets/hero/hero.jpg"]) },
                {property: 'og:description', content: this.$t("vueRoutes.blogDescription")}
            ], 
            link: [
                {rel: 'alternate', hreflang: 'en', href: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.$t("vueRoutes.blog", 'en')])},
                {rel: 'alternate', hreflang: 'pt', href: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.$t("vueRoutes.blog", 'pt')])},
            ]
            
        } 
    },
  components: {
   Sharing,
   AuthorPopup,
   BlogMenu,
   SkeletonLoading
  },
  name: "ToggleDiv",
  data: function () {
    return {
      showAuthorPopUp: false,
      post: null,
      title: null
    };
  },
  props: {
    msg: String,
  },
  computed:
  {
    ...mapState('posts', ['postOutput']),
    imageUrl: function()
    {
      return this.post && this.post.image ? API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.post.image]) : "";      
    },
    readableDate: function (){
      return this.post.createdAt ? UTILS_HELPER.formatDateDotted(this.post.createdAt) : "";
    }
  },
  mounted() {
    if(!this.$route.params.post && !this.$route.query.slug)
    {
      alert("fu")
      router.push({ name: 'Blog' });
    }
    else
    {
      if(this.$route.params.post)
      {
        this.post = this.$route.params.post;
      }
      else
      {
        this.getDetail(this.$route.query.slug);
      }
    }
  },
  methods: 
  {
    ...mapActions('posts', ['getDetail']),
    filterCategory(category)
    {
      this.$router.push({name: "Blog", params: {categoryId: category.id}});
    },
    filterDate(date)
    {
      this.$router.push({name: "Blog", params: {date: date}});
    },
    filterAuthor(author)
    {      
      this.$router.push({name: "Blog", params: {authorId: author.id}});
    }
  },
  watch:
  {
    postOutput: function(val)
    {  
      if(val === false)
      {
        router.push({ name: 'Blog' });
      }
      else if(val && val.data)
      {
        this.post = val.data;
      }
    },
  }
};
</script>

<style scoped>
/* Structure */
.blog-content{
  padding: 1vw 6vw;
}

#menu-sidebar{
  border-width: 10px 10px 10px 0;
  border-style: solid;
  -webkit-border-image: -webkit-gradient(linear, 0 0, 100% 0, from(#F2E0D8,), to(#F8EEEB)) 1 100%;
  -webkit-border-image: -webkit-linear-gradient(left, #F2E0D8, #F8EEEB) 1 100%;
  -moz-border-image: -moz-linear-gradient(left, #F2E0D8, #F8EEEB) 1 100%; 
  -o-border-image: -o-linear-gradient(left, #F2E0D8, #F8EEEB) 1 100%;
  border-image: linear-gradient(to left, #F2E0D8, #F8EEEB) 1 100%;    
}

#empty-sidebar{
  border-width: 10px 0 10px 10px;
  border-style: solid;
  -webkit-border-image: -webkit-gradient(linear, 100% 0, 0 0, from(#F2E0D8), to(#F8EEEB)) 1 100%;
  -webkit-border-image: -webkit-linear-gradient(right, #F2E0D8, #F8EEEB) 1 100%;
  -moz-border-image: -moz-linear-gradient(right, #F2E0D8, #F8EEEB) 1 100%;  
  -o-border-image: -o-linear-gradient(right, #F2E0D8, #F8EEEB) 1 100%;
  border-image: linear-gradient(to right, #F2E0D8, #F8EEEB) 1 100%;
}

#share
{
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 0;
}

/* Text elements */
a
{
  color: #a3161c !important;
  text-decoration: none;
  font-weight: bold;
  cursor: pointer;
}

p{
  font-size: 14px;
}

/* Others */
.blog-img{
  object-fit: cover;
  width:100%;
  height: 100%;
}

hr{
  opacity: 1 !important;
  background-color:#962422 !important;
  border: 1.5px solid #962422 !important;
}

.icon-slot_arrow_left {
  font-size: 25px;
}
.body-style{
  word-break: break-word;
}
.title-style{
  word-break: break-word;
}
@media screen and (max-width: 575px) {
  #menu-sidebar {
    display: none !important;
  }
  #empty-sidebar {
    display: none !important;
  }

}

</style>
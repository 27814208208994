<template>
<div class="container-fluid">
  <!-- Title -->
  <div class="row color-faq">
    <div class="col-12 pt-3 pb-3">
      <center><h1>{{ $t("faq.faq") }}</h1></center>
    </div>
  </div>
  <!-- Subtitles -->
  <div class="row">
    <div class="col-12 mt-2">
      <center><h4 class="font-bolder">{{ $t("faq.title") }}</h4></center>
    </div>
  </div>
  <!-- Loading -->
  <SkeletonLoading v-if="loading" class="container w-100 d-flex justify-content-center mb-3"/>
  <!-- Questions -->
 <div v-else class="container img-background">
   <!-- FAQ group -->
    <div class="d-flex flex-column" v-for="faqGroup in faqGroups" :key="faqGroup.id">
      <!-- Title -->
      <div class="row title-faq">
          <div class="col-12">
              <span class="text-light title-faqs font-bolder">{{faqGroup.title}}</span>
          </div>
      </div>
      <QuestionsAnswers :items="faqGroup.items" :separatorBorder="false"></QuestionsAnswers>
    </div>
  </div>
</div>
</template>

<script>
import SkeletonLoading from "@/components/SkeletonLoading.vue"
import QuestionsAnswers from "@/components/QuestionsAnswers.vue";
import { mapState, mapActions } from "vuex";
import InputGetList from "@/classes/InputGetList";
import { API_HELPER } from "@/helpers/api.js";

export default {
  metaInfo () {
      return { 
            title: `${this.$t("vueRoutes.faqTitle")} - Wild Douro`, 
            htmlAttrs: {
                lang: this.$i18n.locale,
            },
            meta: 
            [
                {name: 'description', content: this.$t("vueRoutes.intro")},
                {name: 'keywords', content: this.$t("vueRoutes.defaultKeywords")},

                // // Twitter Card
                // {name: 'twitter:card', content: "summary"},
                // {name: 'twitter:title', content: `${this.$t("vueRoutes.faqTitle")} - Wild Douro`},
                // {name: 'twitter:description', content: this.$t("vueRoutes.intro")},
                // {name: 'twitter:image', content: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, "assets/hero/hero.jpg"]) },

                // //Facebook OpenGraph
                // {property: 'og:title', content: `${this.$t("vueRoutes.faqTitle")} - Wild Douro`},
                // {property: 'og:site_name', content: 'Wild Douro'},
                // {property: 'og:type', content: 'website'},
                // {property: 'og:image', content: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, "assets/hero/hero.jpg"]) },
                // {property: 'og:description', content: this.$t("vueRoutes.intro")}
            ], 
            link: [
                {rel: 'alternate', hreflang: 'en', href: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.$t("vueRoutes.faq", 'en')])},
                {rel: 'alternate', hreflang: 'pt', href: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.$t("vueRoutes.faq", 'pt')])},
            ]
            
        } 
    }, 
  components: { QuestionsAnswers, SkeletonLoading },
  data: function () {
    return {
      isShow: false,
    };
  },
  computed: {
    ...mapState('faqs', ['listOutput']),
    loading: function()
    {
      return this.listOutput == null;
    },
    faqGroups: function() {
      var result = [];

      if(this.listOutput && this.listOutput.data)
      {
        result = this.listOutput.data;
        result.sort((a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase()));
      }

      return result;
    },
  },
  methods: {
    ...mapActions("faqs", ["getList"]),
  },
  mounted() {
    const itemsPerPage = 900;
    const currentPage = 1;
    const searchText = '';
    const sorter = 2; // desc
    const propertyName = "id";

    var input = new InputGetList(itemsPerPage, currentPage, searchText, sorter, propertyName);
     // List specific parameters
    input.visible= true;
    this.getList(input);
  },
}
</script>

<style scoped>
.container
{
  min-height: 60vh;
}
h1{
  font-size: 64px;
  color: #962422;
  font-weight: 900;
}
.title-faqs{
  font-size: 24px;
}
.color-faq{
  background-color: rgb(230, 230, 230);
}
.title-faq{
    background-color:#962422 ;
}
.img-background{
  background-image: url("../assets/Faq/faq-background.png");
  background-repeat: no-repeat;
}

</style>
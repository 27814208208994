<template>
  <div class="vueperslide__content-wrapper flex-row imageslider">
    <div class="card-voucher" @click="isShow = !isShow" :style="{width: '300px', height: '440px'}">
      <div div class="container">
        <div class="row justify-content-center">
          <img
            class="blog-image"
            :src="image"
            @click="hover = !hover" 
            @mouseover="hover = true"
            @mouseleave="hover = false"
            alt="Blog image cap"
          />
        </div>
        <div 
          @mouseover="hoverInfo = true" 
          @mouseleave="hoverInfo = false"
          @click="hoverInfo = !hoverInfo" 
          class="row info-team mt-2">
            <div class="title-content text-white">
              <span>{{ member.name }}</span>
            </div>
            <center><hr class="hr" /></center>
            <div class="content text-white">
              <span>{{ member.role }}</span>
            </div>
        </div>
      </div>
    </div>
    <div v-if="hoverInfo" class="container container-show slide-up">
      <div class="row mt-3">
        <div class="col-lg-6 col-sm-12">
          <span class="text-light">{{$t("team.origin")}} {{ member.city }}</span>
        </div>
        <div class="col-lg-3 col-sm-12">
          <span class="text-light">{{ member.zodiacSign }}</span>
        </div>
        <div class="col-lg-3 col-sm-12">
          <div id="zodiacSign-id">
            <img
              class="Zodiac-Img"
              :src="require(`../assets/zodiacSign/${zodiacImg}`)"
              alt=""
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <p v-html="member.description" class="text-member text-light"></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { API_HELPER } from "@/helpers/api.js";
import { UTILS_HELPER } from "@/helpers/utils.js";

export default {
  data() {
    return {
      isShow: false,
      hover: false,
      hoverInfo: false
    };
  },
  props: {
    member: Object,
  },
  computed: {
    image: function () {
      var index = this.hover ? 1 : 0;

      return this.member && this.member.photos && this.member.photos.length >= 2
        ? API_HELPER.pathJoin([
            process.env.VUE_APP_URL_ROOT,
            this.member.photos[index].url,
          ])
        : null;
    },
    zodiacImg: function () {
      var camelCaseVersion = this.member.zodiacSign ? UTILS_HELPER.convertToCamelCase(this.member.zodiacSign) : null;
      return camelCaseVersion ? `${camelCaseVersion}.svg` : null;
    },
  },
};
</script>

<style scoped>
.icon-arrow {
  font-size: 80px;
  color: white;
}
.find-more a {
  text-decoration: none !important;
  color: white !important;
}
.title-content {
  font-size: 20px;
}
.content {
  margin: auto;
  font-size: 14px;
  padding: 0;
}
.icon-voucherLogo {
  position: absolute;
  bottom: -22%;
  left: 0;
}
.icon-arrow {
  position: absolute;
  bottom: 0;
  right: 5%;
}
.find-more {
  position: absolute;
  right: 5%;
  bottom: 15%;
  text-decoration: none !important;
  color: white !important;
}
hr {
  opacity: 1 !important;
  background-color: #962422 !important;
  width: 10%;
}
.hr {
  border: 1.5px solid #962422 !important;
}
.blog-image {
  width: 300px;
  height: 440px;
  margin: 0;
  padding: 0;
  object-fit: cover;
}
.Zodiac-Img {
  width: 30px;
  height: 30px;
}
.container-show {
  background: linear-gradient(to top, rgba(0, 0, 0, 1), #962422);
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  cursor: pointer;
  height: 440px;
  width: 300px;
}
p {
  word-wrap: wrap;
  word-break: break-word;
}
.imageslider {
  position: relative;
  cursor: pointer;
}
.slide-up {
  height: 440px;
  overflow: hidden;
  margin-top: 130px;
}
#zodiacSign-id {
  height: 50px;
  background-repeat: no-repeat;
}
@media screen and (max-width: 500px) {
  .container-show {
    width: unset;
  }
}
</style>
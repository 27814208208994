<template>
    <div class="d-flex flex-column" v-bind:style="{ width: `${width}px` }">
        <!-- Slides -->
        <vueper-slides ref="galleryPicker" class="mb-1 cursor-pointer" v-bind:style="{ width: `${width}px` }" :fixed-height="`${height}px`" @slide="onSlideChanged" :class="{'disabled': !isPicker}">
            <!-- Images -->
            <vueper-slide class="position-relative" v-for="(image, index) in images" :key="index">
                <template v-slot:content>
                    <div @click="onChooseImageClicked" title="Choose an image" class="base-image-input col-12 col-lg-6 p-0" v-bind:style="{ 'background-image': `url(${image.url})`,  width: `${width}px`,  height: `${height}px` }">
                    </div>     
                </template>     
            </vueper-slide>
        </vueper-slides>
        <!-- Picker -->
        <input ref="fileInput" type="file" accept=".jpg,.png" class="d-none" @change="onFileSelect" />
        <!-- Cropper -->
        <BasePopUp v-if="showModal" v-model="showModal" @detailsClosed="showModal = false">
            <VueCropper v-show="selectedFile" ref="cropper" :src="selectedFile" alt="Source Image" @ready="onCropStart" :aspectRatio="applyRatio" :initialAspectRatio="applyRatio"></VueCropper>
            <div class="d-flex justify-content-between m-0 p-1">
                <Button v-show="selectedFile" class="btn" color="secondary" text @click="showModal = false">{{ $t("cancel") }}</Button>
                <Button v-show="selectedFile" class="btn" color="primary" @click="confirmClicked">{{ $t("crop") }}</Button>
            </div>
        </BasePopUp>
    </div>
</template>

<script>
import BasePopUp from "@/components/popups/BasePopUp.vue";
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'
import { UTILS_HELPER } from "@/helpers/utils.js";
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'

const MAX_SIZE = 5; // MB
const MAX_SIDE = 250; // Maximum value for width and height

export default {
    components: { VueCropper, BasePopUp, VueperSlides, VueperSlide },
        props: {
        value: null, // MAX 1 means object, others is array
        ratioWidth: {
            type: Number,
            default: 16
        },
        ratioHeight: {
            type: Number,
            default: 9
        },
        isPicker: Boolean,
        maxSide: { // Maximum value for width and height
            type: Number,
            default: MAX_SIDE
        }, 
    },
    data() {
        return {
            images: this.value ? (Array.isArray(this.value) ? this.value : [this.value]) : [{url: require("@/assets/Profile/HeaderDefault.svg")}],
            mime_type: '',
            fileName: '',
            selectedFile: '',
            showModal: false,
            currentSlide: 0
        }
    },
    computed:
    {
        applyRatio: function()
        {
            return this.ratioWidth / this.ratioHeight;
        },
        readableRatio: function()
        {
            return UTILS_HELPER.getReducedFraction(this.ratioWidth, this.ratioHeight);
        },
        width: function()
        {
            if(this.applyRatio <= 1)
            {
                return this.maxSide;
            }
            else
            {
                return this.applyRatio * this.maxSide;
            }
        },
        height: function()
        {
            if(this.applyRatio >= 1)
            {
                return this.maxSide;
            }
            else
            {
                return this.applyRatio * this.maxSide;
            }
        },        
    },
    created() {
        window.addEventListener("resize", this.onWindowResize);
    },
    destroyed() {
        window.removeEventListener("resize", this.onWindowResize);
    },
    methods: {
        onSlideChanged(currentSlide)
        {
            this.currentSlide = currentSlide;
        },
        remove(index)
        {
            this.images.splice(index, 1);
        },
        onChooseImageClicked()
        {
            this.$refs.fileInput.click();
        },
        onFileSelect(e) 
        {
            try
            {
                const file = e.target.files[0];
                const maxSize = 1024 * 1024 * MAX_SIZE;
                this.mime_type = file.type;
                this.fileName = file.name;

                // Validations
                if (typeof FileReader != 'function') {
                    throw 'Sorry, FileReader API not supported';
                } 

                if(this.mime_type != 'image/jpg' && this.mime_type != 'image/jpeg' && this.mime_type != 'image/png')
                {
                    throw this.$t('imageErrorMessage2');
                }

                if(file.size > maxSize)
                {
                    throw this.$t('imageErrorMessage1');
                }

                // Process
                this.showModal = true

                const reader = new FileReader()
                reader.onload = (event) => {
                    this.showModal = true;
                    this.selectedFile = event.target.result;
                    this.$refs.cropper.replace(this.selectedFile);
                }
                reader.readAsDataURL(file);
            }
            catch(err)
            {
                alert(err);
            }
        },
        confirmClicked()
        {
            var imageData = this.$refs.cropper.getCroppedCanvas().toDataURL();
            this.$refs.cropper.getCroppedCanvas().toBlob((blob) => {
                blob.lastModifiedDate = new Date();
                blob.name = this.fileName;
                this.selectedFile = blob;

                UTILS_HELPER.convertToBase64(blob)
                .then(base64Image =>{
                    var newImage = {mime: this.mime_type, data: base64Image.split(',')[1], url: imageData};
                    this.images = [newImage];
                });
            }, this.mime_type);

            this.showModal = false;
        },
                setupCanvasAndCrop()
        {
            const cropper = this.$refs.cropper;
 
            if(cropper)
            {
                const image = cropper.getImageData();
                const imageHeight = image.height;
                const imageWidth = image.width;

                const container = document.querySelector(".modal-body > div");
                const containerHeight = container.clientHeight;
                const containerWidth = container.clientWidth;

                if(imageHeight > imageWidth)
                {
                    if(imageHeight > containerHeight)
                    {
                        const height = containerHeight;
                        const width = imageWidth * height / imageHeight;
                        const left = containerWidth / 2 - width / 2;
                        const top = 0;

                        cropper.setCanvasData({ left: left, top:top, width: width, height: height });
                        cropper.setCropBoxData({ left: left, top:top, height: height });
                    }
                }
                // // This logic doesn't seem to be necessary
                // else
                // {
                //     if(imageWidth > containerWidth)
                //     {

                //     }
                // }
            }
        },
        onWindowResize()
        {
            this.setupCanvasAndCrop();
        },
        onCropStart()
        {
            this.setupCanvasAndCrop();
        }
    },
    watch:
    {
        value: function(val)
        {
            this.images = val ? (Array.isArray(val) ? val : [val]) : []
        },
        showModal: function(val)
        {
            if(!val)
            {
                this.$refs.fileInput.value=null;
            }
        },
        images: function(val) 
        { 
            var image = val && val.length ? val[0] : null;
            this.$emit('input', image);
        }
    }
}
</script>

<style scoped>
img{
    height: 100%;
    cursor: pointer;
}
.delete-button
{
    position: absolute;
    top: 5%;
    right: 0;
    height: 10%;
    width: 4%;
}

.is-invalid .base-image-input .placeholder
{
    background-color: #e55353;
}

.base-image-input {
  display: block;
  background-size: cover;
  background-position: center center;
  max-width: unset !important;
  border-radius: 100%;
  border: 2px solid white;
}

.placeholder {
  background: #F0F0F0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333;
  font-size: 18px;
  font-family: Helvetica, Arial, Verdana, Tahoma, sans-serif;
}

.placeholder:hover {
  background: #E0E0E0;
}

button
{
    border-color: #d8dbe0;
    border-top-color: rgb(216, 219, 224);
    border-right-color: rgb(216, 219, 224);
    border-bottom-color: rgb(216, 219, 224);
    border-left-color: rgb(216, 219, 224);
    border-radius: 0.25rem;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}
/deep/.cropper-container {
    max-height: 80vh;
}

.btn {
  background: #962422;
  color: white;
  padding: 5px 20px;
  font-size: 16px;
}
.btn:hover {
  color: white;
}

.disabled
{
    opacity: 1;
}
</style>
<template>
  <div>
    <!-- Navbar -->
      <b-sidebar id="sidebar-right" right no-header>
        <div v-b-toggle.sidebar-right class="cursor-pointer d-flex justify-content-end" id="btnclose">
            <p class="mt-3"><img class="sizebtn" src="../assets/close.svg"/></p>
        </div>
        <div class="px-3 py-2 mt-lg-5">
          <div v-if="userInfo && userInfo.profile" class="d-none d-lg-flex flex-column">
            <center><h2 class="text-light">{{ $t("rightMenu.hello") }}</h2></center>
            <center><h4 class="text-light">{{userInfo.profile.firstName}} {{userInfo.profile.lastName}}</h4></center>
            <center v-if="userInfo.company && userInfo.company.name"><p class="text-light">{{userInfo.company.name}}</p></center>
            <center v-else><p class="text-light">{{userInfo.profile.email}}</p></center>
          </div>
          <nav class="mb-3 mt-5">
              <b-nav vertical>
                <router-link class="nav-link" :to="{name: 'ProfilePage'}">{{ $t("rightMenu.profile") }}</router-link>
                <b-nav-item :to="{name: 'Favorites'}">{{ $t("rightMenu.fav") }}</b-nav-item>
                <b-nav-item :to="{name: 'Cart'}">{{ $t("rightMenu.bagTrip") }}</b-nav-item>
                <b-nav-item :to="{name: 'Orders'}">{{ $t("rightMenu.consultOrders") }}</b-nav-item>
                <b-nav-item href="/#profile-quiz">{{ $t("rightMenu.quiz") }}</b-nav-item>
                <b-nav-item href="mailto:info@wild-douro.pt">{{ $t("rightMenu.support") }}</b-nav-item>
                <b-nav-item v-if="userInfo && userInfo.profile && role" @click="goToBackoffice">{{ $t("rightMenu.backoffice") }}</b-nav-item>
                <b-nav-item v-if="user" @click="processLogout">{{ $t("rightMenu.logout") }}</b-nav-item>
              </b-nav>
            </nav>
        </div>
      </b-sidebar>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { API_HELPER } from "@/helpers/api.js";

export default {
  name: "ToggleDiv",
  computed: 
  {
    ...mapState('account', ['user']),
    userInfo: function()
    {
      return this.user && this.user.data ? this.user.data : null;
    },
    role: function()
    {
      return this.userInfo && this.userInfo.role ? this.userInfo.role == "Admin" : "User"
    }
  },
  methods:
  {
    ...mapActions('account', ['logout']),
    processLogout: function()
    {
      document.getElementById("btnclose").click();
      this.logout();
    },
    goToBackoffice: function()
    {
      var fullUrl = encodeURI(API_HELPER.pathJoin([process.env.VUE_APP_BACK]));
      window.location = fullUrl;
    }
  },
  props: {
    msg: String,
  }
}
</script>

<style scoped>

>>>.b-sidebar-outer
{
  z-index: 50;
}

>>>.b-sidebar{
  background-color:rgb(133, 42, 33) !important;
    border-width: 20px 0 20px 20px;
  border-style: solid;
  -webkit-border-image: 
    -webkit-gradient(linear, 100% 0, 0 0, from rgba(0, 0, 0, 0.7), to(rgb(133, 42, 33))) 1 100%;
  -webkit-border-image: 
    -webkit-linear-gradient(right, rgba(0, 0, 0, 0.7), rgb(133, 42, 33)) 1 100%;
  -moz-border-image:
    -moz-linear-gradient(right, rgba(0, 0, 0, 0.7), rgb(133, 42, 33)) 1 100%;  
  -o-border-image:
    -o-linear-gradient(right, rgba(0, 0, 0, 0.7), rgb(133, 42, 33)) 1 100%;
  border-image:
    linear-gradient(to right, rgba(0, 0, 0, 0.7), rgb(133, 42, 33)) 1 100%;
}
>>>.b-sidebar > .b-sidebar-body{
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}
>>>.b-sidebar > .b-sidebar-body::-webkit-scrollbar{
  display: none;  /* Safari and Chrome */
}
>>>.nav-link {
    color: white !important;
    text-align: right;
    font-size: 20px;
    text-decoration:  none;
}
>>>.b-sidebar.b-sidebar-right > .b-sidebar-header .close{
    margin-left: auto !important;
    margin-right: 0 !important;
}
.sizebtn{
  width: 20px;
}

#btnclose
{
  margin-right: 2rem;
  padding-top: 5em;
}

</style>
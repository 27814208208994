<template>
  <div class="vueperslide__content-wrapper cursor-pointer d-flex position-relative" :style="{'background-image': 'url(' + imageUrl + ')'}" @click="sendClicked">
    <img class="position-absolute logo-fixed" src="@/assets/vouchers/icon.svg"/>
    <div class="overlay d-flex flex-column position-relative">
        <div class="card-voucher flex-grow-1">
          <div class="title-content text-white mt-3">
              <span>{{voucher.title}}</span>
          </div>
          <div class="text-warning subtitle-content">
              <span>{{subtitle}}</span>
          </div>
          <div class="content text-white mt-2">
              <span>{{excerpt}}</span>
          </div>
        </div>
        <div class="cursor-pointer p-3 bottom">
          <img class="position-absolute logo" src="@/assets/vouchers/icon.svg"/>
          <div class="position-absolute find-more-content">
            <div class="find-more text-white">
              <span>{{ $t("findMore") }}</span>
            </div>
            <img class="next-arrow" src="@/assets/vouchers/next.svg"/>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import { UTILS_HELPER } from "@/helpers/utils.js";
import { API_HELPER } from "@/helpers/api.js";

const EXCERPT_LENGTH = 100;

export default {
  props: {
    voucher: Object,
    favoritesView: Boolean
  },
  methods: {
    sendClicked () {
      if(this.voucher.productId) // Voucher
      {
        this.$router.push({name: "VoucherDetail", params: {voucher: this.voucher}, query: {productId: this.voucher.productId} });
      }
      else // Product
      {
        this.$router.push({name: "VoucherDetail", query: { productId: this.voucher.id} });
      }
    },
  },
  computed: {
    subtitle: function()
    {
      if(!this.favoritesView){

        return this.$tc('voucherSubtitle', this.voucher.people, {prefix: this.$t(UTILS_HELPER.convertToCamelCase(this.voucher.peopleType)), number: this.voucher.people, price: this.voucher.finalPrice});
      }
      return ''
    },
    imageUrl: function()
    {
      var relativePath = this.voucher.images && this.voucher.images.length ? this.voucher.images[0].url : this.voucher.files[0];
      return API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, relativePath]); 
    },
    excerpt: function()
    {
      var result = '';
      if(this.voucher && this.voucher.description)
      {
        var div = document.createElement('div');
        div.innerHTML = this.voucher.description.trim();
        
        result = div.textContent || div.innerText || "";
        if(result.length > EXCERPT_LENGTH)
        {
          result = result.substring(0, EXCERPT_LENGTH) + "...";
        }
      }

      return result;
    },
    isFav() {
        return !this.isFavorite ? require('../assets/Fav/fav-01.svg') : require('../assets/Fav/fav-exp.svg');
    }
  },
};
</script>

<style scoped>
.vueperslide__content-wrapper
{
  background-position: 50%;
  background-size: cover;
}
.vueperslide__content-wrapper .logo-fixed{
  width: 45%;
  height: 27%;
  bottom: 0;
  left: 3.5%;
}
.vueperslide__content-wrapper:not(.vueperslide__content-wrapper--outside-top):not(.vueperslide__content-wrapper--outside-bottom){
  display: inline-table;
  width: 100%;
}
.find-more a {
  text-decoration: none !important;
  color: white !important;
}

.next-arrow
{
  width: 50%;
  margin-top: 0.5rem;
  margin-left: 3rem;
}

.title-content {
  font-size: 30px;
}
.subtitle-content {
  font-size: 15px;
}
.content {
  padding: 5px;
  width: 90%;
  margin: 0 auto;
  font-size: 16px;
}

.overlay {
  height: 100%;
  width: 100%;
  background-color: hsla(1, 63%, 36%, 0.7);
  min-height: 285px;
}

.vueperslide .overlay
{
  visibility: hidden;
  transition: 1s ease;
  background-color: hsla(1, 63%, 36%, 0);
  min-height: 285px;
  max-height: unset;
}
.fav{
  width: 33px;
}
.bottom .logo{
  width: 45%;
  height: 27%;
  bottom: 0;
  left: 3.5%;
}
.find-more-content{
  bottom: 5%;
  right: 5%;
}
@media screen and (min-width: 991px) 
{
  .vueperslide__content-wrapper:hover .overlay {
    visibility: visible;
    background-color: hsla(1, 63%, 36%, 0.7);
    opacity: 1;
  }
}
@media screen and (max-width: 1858px) {
  .title-content {
    font-size: 25px;
  }
  .content {
    font-size: 13px;
  }
}
@media screen and (max-width: 1400px) {
  .overlay {
  min-height: 340px;
  }
  .vueperslide .overlay
  {
    min-height: 340px;
  }
}
@media screen and (max-width: 1200px) {
  .overlay {
  min-height: 280px;
  }
  .vueperslide .overlay
  {
    min-height: 280px;
  }
}
@media screen and (max-width: 1150px) {
  .title-content {
    font-size: 25px;
  }
  .content {
    font-size: 14px;
  }
}
@media screen and (max-width: 983px) {
  .title-content {
    font-size: 25px;
  }
  .content {
    font-size: 15px;
  }
}
@media screen and (max-width: 831px) {
  .content {
    font-size: 14px;
  }
}
@media screen and (max-width: 750px) {
  .title-content {
    font-size: 20px;
  }
  .content {
    font-size: 13px;
  }
}
@media screen and (max-width: 700px) {
  .title-content {
    font-size: 20px;
  }
}
@media screen and (max-width: 669px) {
  .title-content {
    font-size: 30px;
  }
  .content {
    font-size: 16px;
  }
}
@media screen and (max-width: 560px) {
  .title-content {
    font-size: 25px;
  }
  .subtitle-content {
    font-size: 14px;
  }
  .content {
    font-size: 13px;
  }
}
@media screen and (max-width: 420px) {
  .title-content {
    font-size: 20px;
  }
  .subtitle-content {
    font-size: 11px;
  }
  .content {
    font-size: 12px;
  }
}
@media screen and (max-width: 370px) {
  .title-content {
    font-size: 14px;
  }
  .subtitle-content {
    font-size: 10px;
  }
  .content {
    font-size: 11px;
  }
  .find-more {
    font-size: 12px;
  }
}

</style>
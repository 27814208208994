<template>
  <div v-if="author" id="author-popup" class="container-fluid d-lg-flex justify-content-lg-center align-items-lg-center"> 
    <!-- LG or bigger devices -->
    <div class="d-none d-lg-flex flex-column author-content position-relative">
      <!-- Close button -->
      <div class="close-button p-3" @click="closeClicked">
        <p><img class="w-100" src="@/assets/close.svg"/></p>
      </div>
      <!-- Image on the left, close and see more buttons on the right -->
      <div class="row m-0">
        <!-- Image -->
        <div class="col-6 align-self-center">
          <img class="img-fluid" :src="imageUrl" alt="write">
        </div>
        <!-- Text information -->
        <div class="col-6" id="author-info" >
          <h4 class="mb-4" id="author-name">{{author.name}} |<span id="author-role"> {{role}}</span></h4>
          <p class="textbox">{{$t('zodiacSign')}}: {{$t(author.zodiacSign)}} - {{$t('origin')}}: {{author.city}} </p>
          <p class="textbox">{{author.description}}</p>
          <!-- See more button -->
          <div id="author-see-more" class="d-flex justify-content-end p-3">
            <div @click="filterAuthor">{{$t("authorPopup.authorPopup")}} {{author.name}}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- MD or smaller devices -->
    <div class="d-block d-lg-none d-flex flex-column author-content">
      <!-- Close button -->
      <div class="d-flex justify-content-end close-button" @click="closeClicked">
        <p><img class="w-100" src="@/assets/close.svg"/></p>
      </div>
      <!-- Text information -->
      <h4 id="author-name" class=" mb-4">{{author.name}} |<span id="author-role"> {{role}}</span></h4>
      <p class="textbox">{{$t('zodiacSign')}}: {{$t(author.zodiacSign)}} - {{$t('origin')}}: {{author.city}} </p>
      <p class="textbox">{{author.description}}</p>
      <!-- Image -->
      <div class="author-image-container d-flex justify-content-center py-3">
        <img class="author-image row" :src="imageUrl" alt="write">
      </div>
      <!-- See more button -->
      <div id="author-see-more" class="d-flex justify-content-center py-3">
        <div @click="filterAuthor">{{$t("authorPopup.authorPopup")}} {{author.name}}</div>
      </div>
    </div>
  </div> 
</template>

<script>
import { API_HELPER } from "@/helpers/api.js";

export default {
  props: {
    author: Object,
  },
  computed:
  {
    role: function()
    {
      return this.author && this.author.roles ? this.author.roles[this.$i18n.locale] : '';
    },
    imageUrl: function()
    {
      return this.author && this.author.photos && this.author.photos.length ? API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.author.photos[2].url]) : "";      
    },
  },
  methods:
  {
    filterAuthor: function()
    {
      this.$router.push({name: "Blog", params: {authorId: this.author.id}});
    },
    closeClicked: function()
    {
      this.$emit("close-request");
    }
  },
}
</script>

<style scoped>
#author-popup{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  padding-top: 5rem;
  overflow-y: scroll;
}
.author-content
{
  background-color: black;
  width: 60vw;
  min-width: 400px;
  height: auto;
}
.close-button > p
{
  width: 25px;
  height: 25px;
  margin: 10px;
}
.close-button
{
  cursor: pointer;
  z-index: 99;
  position: absolute;
  right: 0;
}
#author-name{
  color: white;
  margin-top: 3rem;
}
#author-role{
  color: white;
  font-size: 16px;
}
.textbox{
  color:white;
  font-size: 16px;
}
#author-see-more > div {
  border: 2px solid white !important;
  color: white !important;
  text-align: center;
  text-decoration: none !important;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  padding: 1rem;
}
@media only screen and (max-width: 991px) {
  .author-image{
    width: 70%;
  }
  #author-name{
    margin-left: 1rem!important;
    margin-top: 1rem!important;
  }
  .textbox{
    margin-left: 1rem!important;
    margin-right: 1rem!important;
  }
  .author-content
  {
    width: 100%!important;
    min-width: unset!important;
  }
  #author-popup{
    padding-top: 6rem!important;
    overflow-y: scroll;
  }
  .close-button {
    position: unset;
  }
}
</style>
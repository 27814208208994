<template>
    <div class="alert-banner row justify-content-between p-3">
        <!-- Close (very small devices)-->
        <div class="col-12 d-flex cursor-pointer justify-content-end">
            <p class="d-flex justify-content-end w-100" @click="removeBanner"><img class="alert-close" src="../assets/close.svg"/></p>
        </div>
        <!-- Icon -->
        <div v-if="icon" class="d-none d-md-flex col-3 flex-column justify-content-center">
            <img :src="icon" class="w-100"/>
        </div>
        <!-- Text -->
        <div class="col-12 col-sm-11 col-md-8">
            <p class="title">{{title}}</p>
            <p class="message" v-html="messageHtml"></p>
        </div>
        <!-- Btn -->
        <div v-if="isQuizBanner == true" class="col-12 mt-3 d-flex justify-content-center">
            <a href="/#profile-quiz">{{ $t("openQuiz") }}</a>
        </div>
        <!-- Close (>= sm) -->
        <!-- <div class="col-1 d-none d-sm-block cursor-pointer">
            <p class="d-flex justify-content-end w-100" @click="removeBanner"><img class="alert-close" src="../assets/close.svg"/></p>
        </div> -->
    </div>
</template>

<script>
const STORAGEPREFIX = "alertbanner-";

export default {
  props: {
    identifier: String, // Necessary to save that it was deleted
    icon: String,
    title: String,
    messageHtml: String,
    isQuizBanner: Boolean
  },
  methods:
  {
    removeBanner: function()
    {
        this.$destroy();
        this.$el.parentNode.removeChild(this.$el);

        var storageIdentifier = STORAGEPREFIX + this.identifier;
        localStorage.setItem(storageIdentifier, Date.now());
    }
  }
}
</script>

<style scoped>
.alert-banner
{
    position: fixed;
    width: 25%;
    min-width: 500px;
    right: 12px;
    top: 80%;
    transform: translate(0, -50%);
    z-index: 3;
    background-color: #962422;
}

p
{
    color: white;
}

/deep/a
{
  color: #FFCC00;
}

.alert-close
{
    width: 100%;
    height: 100%;
    max-width: 20px;
}

.title{
    font-size: 14px;
}

.message{
    font-size: 12px;
}

@media (max-width: 767px) 
{
    .alert-banner
    {
        position: fixed;
        width: 100vw;
        right: 0;
        top: unset;
        transform: unset;
        z-index: 6;
        min-width: unset;
        bottom: 0;
        left: 0;
    }

    .title{
        font-size: 15px;
    }

    .message{
        font-size: 10px;
    }
}

</style>
<template>
    <!-- Based on Vue-Multiselect -->
    <multiselect ref="uniselect" v-model="selectedItem" :options="options" :multiple="false" :close-on-select="true" :clear-on-select="false" :preselect-first="true" :allow-empty="false" label="name" track-by="value" :showLabels="false" :searchable="false">
        <!-- Add radio buttons -->
        <template slot="option" slot-scope="props">
            <div class="option__desc d-flex align-items-center">
                <input v-show="props.option.value" type="radio" class="option__title cursor-pointer" :checked="selectedItem && selectedItem.value == props.option.value"/>
                <span v-show="props.option.value" class="option__small">{{ props.option.name }}</span>
            </div>
        </template>
        <!-- Change preview -->
        <template slot="singleLabel" slot-scope="props">
            <div class="cursor-pointer d-flex align-items-center" @click="previewClicked">
                <img src="@/assets/Experiences/uni_selection_arrow.svg" alt="">
                <span class="selected-name">{{props.option.name}}</span>
            </div>
        </template>
        <!-- Remove default caret -->
        <template slot="caret">
            <span class="select-caret"></span>
        </template>
    </multiselect>
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
    components: {
        Multiselect
    },
    props: {
        value: Number,
        options: Array,
    },
    data () {
        return {
            selectedItem: this.value ? this.options.find(option => option.value == this.value) : (this.options.length ? this.options[0] : null)
        }
    },
    methods:
    {
        previewClicked()
        {
            // document.getElementsByClassName(".select-caret")[0].click();
        }
    },
    watch:
    {
        selectedItem: function()
        {
            if(this.selectedItem)
            {
                this.$emit('input', this.selectedItem.value);
            }
        },
        options: function()
        {
            this.selectedItem = this.value ? this.options.find(option => option.value == this.value) : (this.options.length ? this.options[0] : null);
        },
        value: function(val)
        {  
            this.selectedItem = this.options.find(option => option.value == val);
        }
    }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.selected-name
{
    color:  #962422;
    font-size: 1.8rem;
    font-weight: bold;
    line-height: 1;
}
.apply-button
{
    background-color: #962422;
    border: none;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
}
input[type='radio']
{
    margin-right: 0.5rem;
}
input[type="radio"] {
  /* remove standard background appearance */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* create custom radiobutton appearance */
  display: inline-block;
  width: 15px;
  height: 15px;
  padding: 2px;
  /* background-color only for content */
  background-clip: content-box;
  border: 2px solid #962422;
  background-color: white;
  border-radius: 50%;
}
/* appearance for checked radiobutton */
input[type="radio"]:checked {
  background-color: #962422;
}
/deep/.multiselect__content
{
    padding: 6px 12px;
}
/deep/.multiselect__option
{
    line-height: 36px;
    padding: unset;
    min-height: unset;
}
/deep/.multiselect__single {
  padding-left: 0;
}
/deep/.multiselect__option--highlight
{
    background: none;
    color: unset;
}

/deep/.multiselect__option--selected
{
    background: unset;
    font-weight: normal;
}

/deep/.multiselect__tags
{
    border: none;
    padding: 8px 40px 0 0;
}

/deep/.multiselect__content-wrapper
{
    width: max-content;
    max-height: unset !important;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
</style>
import { render, staticRenderFns } from "./MultiSelect.vue?vue&type=template&id=4752eb86&scoped=true&"
import script from "./MultiSelect.vue?vue&type=script&lang=js&"
export * from "./MultiSelect.vue?vue&type=script&lang=js&"
import style0 from "vue-multiselect/dist/vue-multiselect.min.css?vue&type=style&index=0&prod&lang=css&"
import style1 from "./MultiSelect.vue?vue&type=style&index=1&id=4752eb86&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4752eb86",
  null
  
)

export default component.exports
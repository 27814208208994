<template>
  <div class="d-flex flex-column" id="app">
    <router-view v-if="!isApp" name="header"></router-view>
    <router-view class="flex-grow-1" ref="container" @success-alert-requested="showSuccessAlert" @warning-alert-requested="showWarningAlert" @error-alert-requested="showErrorAlert"/>
    <router-view v-if="!isApp" name="footer"></router-view>
    <Chat v-if="loaded && !isApp"/>
  </div>
</template>

<script>
import ResultPopup from "@/components/popups/ResultPopup.vue"
import Vue from "vue";
import Chat from "@/components/Chat.vue";

const SUCCESS = 0;
const WARNING = 1;
const ERROR = 2;

export default{
  data: function () {
    return {
      loaded: false
    };
  },
  components: {
    Chat
  },
  mounted: function()
  {
    this.$nextTick(() => {
      this.loaded = true;
    });
  },
  computed:
  {
    isApp: function()
    {
      return this.$route.query.mode == "mobileapp";
    }
  },
  methods:
  {
    showSuccessAlert({message, primaryButton, primaryCallback, secondaryButton, secondaryCallback})
    {
      this.showAlert(SUCCESS, message, primaryButton, primaryCallback, secondaryButton, secondaryCallback);
    },
    showWarningAlert({message, primaryButton, primaryCallback, secondaryButton, secondaryCallback})
    {
      this.showAlert(WARNING, message, primaryButton, primaryCallback, secondaryButton, secondaryCallback);
    },
    showErrorAlert({message, primaryButton, primaryCallback, secondaryButton, secondaryCallback})
    {
      this.showAlert(ERROR, message, primaryButton, primaryCallback, secondaryButton, secondaryCallback);
    },
    showAlert(type, message, primaryButton, primaryCallback, secondaryButton, secondaryCallback)
    {
      var ComponentClass = Vue.extend(ResultPopup)
      var instance = new ComponentClass({
        propsData: { type: type, message: message, primaryButtonText: primaryButton, primaryButtonCallback: primaryCallback, secondaryButtonText: secondaryButton, secondaryButtonCallback: secondaryCallback }
      })
      instance.$mount() // pass nothing
      this.$refs.container.$el.appendChild(instance.$el)
    }
  }
}
</script>


<style>
/* Structure */
html, body, #app
{
  height: 100vh;
  max-width: 100vw;
}

/* Icomoon */
@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?ngx849');
  src:  url('fonts/icomoon.eot?ngx849#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?ngx849') format('truetype'),
    url('fonts/icomoon.woff?ngx849') format('woff'),
    url('fonts/icomoon.svg?ngx849#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-size: 50px;
}
.icon-arrow-left-team:before {
  content: "\e936";
  color: #fff;
}
.icon-arrow-right-team:before {
  content: "\e937";
  color: #fff;
}
.icon-briefcase:before {
  content: "\e900";
}
.icon-heart:before {
  content: "\e901";
}
.icon-search:before {
  content: "\e902";
}
.icon-user:before {
  content: "\e903";
}
.icon-face:before {
  content: "\e904";
  font-size: 80px;
}
.icon-insta:before {
  content: "\e905";
  font-size: 80px;

}
.icon-linkedin:before {
  content: "\e906";
  font-size: 80px;

}
.icon-twitter:before {
  content: "\e907";
  font-size: 80px;

}
.icon-youtube:before {
  content: "\e908";
  font-size: 80px;

}
@media (max-width: 1199px) {
  .icon-face:before {
    font-size: 60px;
  }
  .icon-insta:before {
    font-size: 60px;

  }
  .icon-linkedin:before {
    font-size: 60px;

  }
  .icon-twitter:before {
    font-size: 60px;

  }
  .icon-youtube:before {
    font-size: 60px;

  }
}
@media (max-width: 400px) {
  .icon-face:before {
    font-size: 40px;
  }
  .icon-insta:before {
    font-size: 40px;

  }
  .icon-linkedin:before {
    font-size: 40px;

  }
  .icon-twitter:before {
    font-size: 40px;

  }
  .icon-youtube:before {
    font-size: 40px;

  }
}
.icon-slot_arrow_left:before {
  content: "\e909";
}
.icon-slot_arrow_right:before {
  content: "\e90a";
}
.icon-voucherLogo:before {
  content: "\e90b";
}
.icon-arrow:before {
  content: "\e90c";
}
.icon-autumn:before {
  content: "\e90d";
}
.icon-spring:before {
  content: "\e90e";
}
.icon-summer:before {
  content: "\e90f";
}
.icon-winter:before {
  content: "\e910";
}
.icon-seeMore:before {
  content: "\e911";
}
.icon-playbtn:before {
  content: "\e912";
}
.icon-msg:before {
  content: "\e913";
}
.icon-people:before {
  content: "\e914";
}
.icon-summerEx:before {
  content: "\e915";
}
.icon-springEx:before {
  content: "\e916";
}
.icon-autumnEx:before {
  content: "\e917";
}
.icon-winterEx:before {
  content: "\e918";
}
.icon-right:before {
  content: "\e919";
}
.icon-left:before {
  content: "\e91a";
}
.icon-arrow_down .path1:before {
  content: "\e91b";
}
.icon-arrow_down .path2:before {
  content: "\e91c";
}
.icon-chat .path1:before {
  content: "\e91d";
  color: rgb(162, 25, 29);
}
.icon-chat .path2:before {
  content: "\e91e";
  margin-left: -0.70703125em;
  color: rgb(255, 255, 255);
}
.icon-chat .path3:before {
  content: "\e91f";
  margin-left: -0.70703125em;
  color: rgb(255, 255, 255);
}

/* Defaults */
@import url('https://fonts.googleapis.com/css2?family=Ruda:wght@400;500;600;700;800;900&display=swap');

html, body, #app {
  font-family: 'Ruda', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
a
{
  color: #FFCC00;
}

/* Global classes */
#app .was-validated :invalid ~ .invalid-feedback, 
#app .was-validated :invalid ~ .invalid-tooltip, 
#app .is-invalid ~ .invalid-feedback, 
#app .is-invalid ~ .invalid-tooltip
{
  display: flex;
  flex-direction: column;
}

.cursor-pointer
{
  cursor: pointer;
}

.text-underlined
{
  text-decoration: underline;
}

.font-weight-bold
{
  font-weight: bold;
}
.font-bolder
{
  font-weight: 900;
}
.px-6{
  padding-right: 5rem!important;
  padding-left: 5rem!important;
}
.p-l-1{
  padding: 0 0 0 1rem!important;
}
.h-30{
  height: 30px!important;
}
.text-right
{
  text-align: right;
}
.text-center
{
  text-align: center;
}
.text-left
{
  text-align: left;
}

.primary-color
{
  color: #a3161c;
}
.background-color
{
  background-color: #962422;
}

.primary-background
{
  background-color: #a3161c;
}
.btn-check:focus+.btn, .btn:focus{
  box-shadow: none !important;
}

.disabled
{
  pointer-events: none;
  opacity: 0.5;
}

.hidden
{
  visibility: hidden;
}
</style>
<template>
    <div class="d-flex justify-content-center align-items-center">
        <a href="https://www.facebook.com/wild.douro" target="_blank"><span class="icon-face"></span></a>
        <a href="https://www.instagram.com/wild_douro/" target="_blank"><span class="icon-insta"></span></a>
        <a href="https://twitter.com/wilddouro" target="_blank"><span class="icon-twitter"></span></a>
        <a href="https://www.linkedin.com/company/wild-douro/" target="_blank"><span class="icon-linkedin"></span></a>
        <a href="https://www.youtube.com/channel/UChg6L0lRuPFljnSG42z_iKQ" target="_blank"><span class="icon-youtube"></span></a>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
a{
    color: white !important;
    text-decoration: none;
}

</style>
import { FAVORITES_SERVICE } from "@/services/favorites.service.js";
import { UTILS_HELPER } from "@/helpers/utils.js";

const favoritesList = JSON.parse(localStorage.getItem('favorites'));
const state = favoritesList
    ? { listOutput: favoritesList, addTask: undefined, removeTask: undefined }
    : { listOutput: { data: [] }, addTask: undefined, removeTask: undefined };

const actions = {
    getList({ dispatch, commit }, inputGetFavorites) {
        var result = state.listOutput ? UTILS_HELPER.deepClone(new Object, state.listOutput) : {data: []};

        dispatch('alert/clear', null, { root: true });
        commit('listRequest');

        const loggedIn = localStorage.getItem('user');
        if(loggedIn)
        {
            FAVORITES_SERVICE.list(inputGetFavorites)
            .then(
                output => {
                    commit('listSuccess', output);
                },
                error => {
                    commit('listFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
        }
        else
        {
            commit('listSuccess', result);
        }
    },
    addFavorite({ dispatch, commit }, product) {
        var result = state.listOutput ? UTILS_HELPER.deepClone(new Object, state.listOutput) : {data: []};
        dispatch('alert/clear', null, { root: true });
        commit('addRequest');

        if(product.productId)
        {
            if(!result.data.find(x=> x.productId == product.productId))
            {
                result.data.push(product);
            }
        }
        else if(product.id)
        {
            if(!result.data.find(x=> x.id == product.id))
            {
                result.data.push(product);
            }
        }

        const loggedIn = localStorage.getItem('user');
        if(loggedIn)
        {
            FAVORITES_SERVICE.add([product.productId])
                .then(
                    output => {
                        commit('addSuccess', output);
                    },
                    error => {
                        commit('addFailure', error);
                        dispatch('alert/error', error, { root: true });
                    }
                );
        }
        else
        {
            // By delaying addSuccess, addRequest is called. Otherwise it is not.
            setTimeout(function() {
                commit('addSuccess', result);
            }, 500); 
        }
    },
    sync({ dispatch, commit })
    {        
        var ids = state.listOutput.data.map(x=> x.productId);
        FAVORITES_SERVICE.add(ids)
        .then(
            output => {
                commit('addSuccess', output);
            },
            error => {
                commit('addFailure', error);
                dispatch('alert/error', error, { root: true });
            }
        );

    },
    removeFavorite({ dispatch, commit }, product) {
        var result = state.listOutput ? UTILS_HELPER.deepClone(new Object, state.listOutput) : {data: []};

        dispatch('alert/clear', null, { root: true });
        commit('removeRequest');

        var favorites = result.data;
        if(product.productId)
        {
            if(result.data.find(x=> x.productId == product.productId))
            {
                favorites.splice(favorites.findIndex(item => item.productId === product.productId), 1);
            }
        }
        else if(product.id)
        {
            if(result.data.find(x=> x.id == product.id))
            {
                favorites.splice(favorites.findIndex(item => item.id === product.id), 1);
            }
        }

        const loggedIn = localStorage.getItem('user');
        if(loggedIn)
        {
            var id = product.productId ? product.productId : product.id;
            FAVORITES_SERVICE.remove(id)
                .then(
                    output => {
                        console.log(output);
                        commit('removeSuccess', result);
                    },
                    error => {
                        commit('removeFailure', error);
                        dispatch('alert/error', error, { root: true });
                    }
                );
        }
        else
        {
            // By delaying removeSuccess, removeRequest is called. Otherwise it is not.
            setTimeout(function() {
                commit('removeSuccess', result);
            }, 500);
        }
    },
    clear({ dispatch, commit })
    {
        dispatch('alert/clear', null, { root: true });
        commit('clearRequested');
    },
};

const mutations = {
    /* List */
    listRequest(state) {
        state.listOutput = null;
    },
    listSuccess(state, output) {
        state.listOutput = output;
        localStorage.setItem('favorites', JSON.stringify(state.listOutput));
    },
    listFailure(state) {
        state.listOutput = false;
        localStorage.setItem('favorites', JSON.stringify(state.listOutput));
    },
    /* Add */
    addRequest(state) {
        state.addTask = null;
    },
    addSuccess(state, output) {
        state.addTask = true;
        state.listOutput = output;
        localStorage.setItem('favorites', JSON.stringify(state.listOutput));
    },
    addFailure(state) {
        state.addTask = false;

        state.listOutput = false;
        localStorage.setItem('favorites', JSON.stringify(state.listOutput));
    },
    /* Remove */
    removeRequest(state) {
        state.removeTask = null;
    },
    removeSuccess(state, output) {
        state.removeTask = true;

        state.listOutput = output;
        localStorage.setItem('favorites', JSON.stringify(state.listOutput));
    },
    removeFailure(state) {
        state.removeTask = false;

        state.listOutput = false;
        localStorage.setItem('favorites', JSON.stringify(state.listOutput));
    },
     // clear
     clearRequested(state)
     {
        state.listOutput =  {data: []};
        localStorage.setItem('favorites', JSON.stringify(state.listOutput));
     }
   
};

export const favorites = {
    namespaced: true,
    state,
    actions,
    mutations
};
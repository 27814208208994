<!-- This component can be used with inspirations and products -->
<template>
    <div class="d-flex flex-column card cursor-pointer">
      <div class="d-flex justify-content-center align-items-center card-image">
          <img :src="imageUrl" class="w-100 h-100" @click="sendClicked(inspiration.productId, inspiration.sku)"/>
          <!-- <img v-if="!loading" :src="imageUrl" class="w-100 h-100" @click="sendClicked"/> -->
          <!-- <SkeletonLoading v-else class="h-100 w-100"></SkeletonLoading> -->
          <span :class="{path: isFavorite}" @click="favoriteButtonClicked" id="icon-fav2">
            <img :src="isFav" class="fav" alt="">              
          </span>
      </div>
      <div class="d-flex flex-column card-text p-3 w-100"  @click="sendClicked(inspiration.productId, inspiration.sku)">
          <h4>{{ inspiration.title }}</h4>
          <h5 v-if="subtitle && this.inspiration.nights < 999">{{ subtitle }}</h5>
          <p>{{excerpt}}</p>
      </div>
    </div>
</template>

<script>
import { API_HELPER } from "@/helpers/api.js";
import { mapState, mapActions } from "vuex";
// import SkeletonLoading from "@/components/SkeletonLoading.vue"
import InputGetList from "@/classes/InputGetList";

const EXCERPT_LENGTH = 120;

export default {  
  components: {
    // SkeletonLoading,
  },
  props: {
    inspiration: Object,
    item: Object,
  },
  data() {
    return {
      clicked: false,
    }
  },
  methods: {
    sendClicked (id, sku) {
      this.$router.push({name: "ExperienceDetail", params: { id: id, sku: sku}});
    },
    ...mapActions('favorites', ['getList']),
    ...mapActions('favorites', ['addFavorite']),
    favoriteButtonClicked()
    {
      this.clicked = true;
      
      if(this.isFavorite)
      {
        this.$emit("warning-alert", this.inspiration);
      }
      else
      {
        this.addFavorite(this.inspiration);
      }
    },
    updateItemsRequested()
    {
      const currentPage = 1;
      const searchText = '';
      const sorter = 1; // asc
      const propertyName = "id";
        
      //Make request
      var input = new InputGetList(this.itemsPerPage, currentPage, searchText, sorter, propertyName);    
      input.visible = true;
      // Get favs
      this.getList(input);    
    }
  },
  mounted: function()
  {
    if(!this.listOutput)
    {
      this.updateItemsRequested();
    }
  },
  computed:{
    ...mapState('account', ['user']),
    ...mapState('favorites', ['listOutput']),
    ...mapState('favorites', ['addTask']),
    ...mapState('favorites', ['removeTask']),
    isFavorite: function()
    {
      // The thumb may be used for inspirations or products; it also may be used to represent favorites for logged in or non logged in users, in which cases the saved information changes: favorites may have products (logged in) or vouchers/experiences (not logged in)
      if(this.inspiration.productId) // Inspiration
      {
        if(this.user)
        {
          return this.listOutput && this.listOutput.data ? this.listOutput.data.find(x=> x.id == this.inspiration.productId) : false;
        }
        else
        {
          return this.listOutput && this.listOutput.data ? this.listOutput.data.find(x=> x.productId == this.inspiration.productId) : false;
        }
      }
      else // Product
      {
        return this.listOutput && this.listOutput.data ? this.listOutput.data.find(x=> x.productId == this.inspiration.productId) : false;
      }
    },
    loading: function()
    {
      return this.clicked && (this.addTask == null || this.removeTask == null);
    },
    imageUrl: function()
    {
      var relativePath = this.inspiration ? this.inspiration.mediaFile : null;
      return API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, relativePath]);      
    },
    subtitle: function()
    {
      // return this.inspiration.nights ? this.$tc('experienceSubtitle', this.inspiration.nights, {count: this.inspiration.nights, price: this.inspiration.finalPrice}) : '';
      return this.inspiration.nights ? `${this.inspiration.nights} night(s)` : '';
    },
    excerpt: function()
    {
      var result = '';

      if(this.inspiration && this.inspiration.description)
      {
        var div = document.createElement('div');
        div.innerHTML = this.inspiration.description.trim();

        result = div.textContent || div.innerText || "";
        if(result.length > EXCERPT_LENGTH)
        {
          result = result.substring(0, EXCERPT_LENGTH) + "...";
        }
      }

      return result;
    },
    isFav() {
        return !this.isFavorite ? require('../assets/Fav/fav-01.svg') : require('../assets/Fav/fav-exp.svg');
    }
  },
  watch: 
  {
    addTask: function(val)
    {
      if(val && this.clicked)
      {
        this.$emit("success-alert-requested", 'add');
        this.updateItemsRequested();
      }

      if(val != null) this.clicked = false;
    },
    removeTask: function(val)
    {
      if(val && this.clicked)
      {
        this.updateItemsRequested();
      }

      if(val != null) this.clicked = false;
    },
  }
}
</script>

<style scoped>
img
{
  object-fit: cover;
}

.card {
  border: 1px solid #962422;
  background: white;
  width: 400px;
  min-height: 450px;
  overflow: hidden;
}

.card-image
{
  position: relative;
  height: 260px;
}

#icon-fav2{
  position: absolute;
  top: 0;
  right: 2%;
}

.card-image .icon {
  position: absolute;
  top: 0;
  right: 0;
}

.card-text
{
  min-height: 190px;
  height: auto;
  overflow: hidden;
}

.card h4 {
  text-align: center;
  font-weight: bold;
}
.card h5 {
  text-align: center;
  color: #962422;
  margin-bottom: 10px;
  font-size: 15px;
}
.card p {
  text-align: center;
  width: 90%;
  /* border: 1px dashed black; */
  margin: 0 auto;
  font-size: 16px;
}
.fav{
  width: 33px;
}

@media screen and (max-width: 1400px) {
  .md-layout-item span{
    left: -60%;
  }
}
@media screen and (max-width: 1140px) {
  .md-layout-item span{
    left: -55%;
    font-size: 13px;
  }
}
@media screen and (max-width: 960px) {
  .md-layout-item span{
    display: none;
  }
}
@media screen and (max-width: 405px) {
  .container-list .card {
      width: 300px;
    height: 400px;
  }
  .card p {
    margin: 25px auto;
    font-size: 13px;
  }
}
</style>
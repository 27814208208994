import { CART_SERVICE } from "@/services/cart.service.js";

const products = JSON.parse(localStorage.getItem('products'));
const state = products
    ? { products: products, processTask: null }
    : { products: { data: [] }, processTask: null};

const actions = {
    getList({ dispatch, commit }) {
        dispatch('alert/clear', null, { root: true });
        commit('listRequest');
        
        const loggedIn = localStorage.getItem('user');
        if(loggedIn)
        {
            CART_SERVICE.list()
            .then(
                output => {
                    commit('listSuccess', output);
                },
                error => {
                    commit('listFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
        }
        else
        {
            commit('listSuccess', state.products);
        }
    },
    // This will create a local save if the user is not logged in or call the service if he/she is logged in
    updateCart({ dispatch, commit }, { product, quantity }) {
        commit('updateRequest');
        if(quantity == undefined) quantity = 1; // Default
        
        const loggedIn = localStorage.getItem('user');
        if(loggedIn)
        {
            CART_SERVICE.update(product.productId ?? product.id, quantity)
            .then(
                output => {
                    commit('updateSuccess', output);
                },
                error => {
                    commit('updateFailure', error);
                    dispatch('alert/error', error, { root: true }); 
                }
            );
        }
        else
        {
            try
            {
                var result = state.products.data ? state.products.data.slice(0) : [];

                if(quantity == 0)
                {
                    result.splice(result.findIndex(item => item.productId === product.productId), 1);
                }
                else
                {
                    if(result.filter(cartProduct => cartProduct.productId === product.productId).length == 0)
                    {
                        result.push(product);
                    }
        
                    product.quantity = quantity;
                }

                commit('updateSuccess', {data: result });
            }
            catch(error)
            {
                commit('updateFailure', error);
            }
        }
    },
    sync({ dispatch, commit }, callback) {
        dispatch('alert/clear', null, { root: true });
        commit('updateRequest');

        CART_SERVICE.sync(state.products.data)
        .then(
            output => {
                commit('updateSuccess', output);

                if(callback)
                {
                    callback();
                }
            },
            error => {
                commit('updateFailure', error);
                dispatch('alert/error', error, { root: true }); 
            }
        )
    },
    clear({ dispatch, commit })
    {
        dispatch('alert/clear', null, { root: true });
        commit('clearRequested');
    },
};
    
const mutations = {
    //list
    listRequest() {
    },
    listSuccess(state, output) {
        state.products = output;
        localStorage.setItem('products', JSON.stringify(state.products));
    },
    listFailure(state) {
        state.products = {data: []};
        localStorage.setItem('products', JSON.stringify(state.products));
    },
    //updateQt
    updateRequest(state) {
        state.processTask = null;
    },
    updateSuccess(state, output) {
        state.products = output;
        localStorage.setItem('products', JSON.stringify(state.products));

        state.processTask = true;
    },
    updateFailure(state) {
        // state.products = [];
        state.processTask = false;
    },
    // clear
    clearRequested(state)
    {
        state.products =  {data: []};
        localStorage.setItem('products', JSON.stringify(state.products));
    }
}; 
    

export const cart = {
    namespaced: true,
    state,
    actions,
    mutations
};
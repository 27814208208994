<template>
  <div class="header">
    <RightLateralMenu></RightLateralMenu>
    <nav class="navbar navbar-expand-md navbar-dark bg_color_primary sticky-top d-flex justify-content-between nav">
    <div class="navbar-nav d-flex align-items-start" id="left-side-element" >
        <b-button id="navbar-toggle" v-b-toggle.sidebar-1 variant="transparent" size="lg"
          ><b-icon icon="list" variant="light"></b-icon
        ></b-button>
        <b-sidebar id="sidebar-1" width="auto" no-header bg-variant="no-light">
          <template #default="{ hide }">
            <SideMenu @close="hide"></SideMenu>
          </template>
        </b-sidebar>
    </div>
      <div class="d-none d-xl-flex justify-content-between" id="main-menu">
        <ul class="navbar-nav justify-content-between w-100 ">
          <li class="nav-item">
            <a class="nav-link" id="logo-position" href="/">
              <img src="../assets/TopMenu/Logo/logo.png" class="logo" title="Logo"/>
            </a>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" :to="{name: 'Team'}">{{ $t("topMenu.aboutUs") }}</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" :to="{ name: 'Experiences'}">{{ $t("topMenu.ourExperiences") }}</router-link>        
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/#tailor-made-experience" id="tailorMade">{{ $t("topMenu.taylorYourTrip") }}</a>
            <!-- <router-link class="nav-link" to="/#tailor-made-experience">{{ $t("topMenu.taylorYourTrip") }}</router-link> -->
          </li>
          <!-- <li class="nav-item">
            <a class="nav-link" href="/#vouchers">{{ $t("topMenu.vouchers") }}</a>
            <router-link class="nav-link" :to="{ name: 'Vouchers'}">{{ $t("topMenu.vouchers") }}</router-link>
          </li> -->
          <li class="nav-item">
            <router-link class="nav-link" :to="{name: 'Blog'}">{{ $t("topMenu.blog") }}</router-link>
          </li>
        </ul>
      </div>
      <div class="d-flex justify-content-end" id="right-side-element">
        <li class="nav-item2" id="heart">
          <router-link class="nav-link" :to="{ name: 'Favorites'}">
            <img :src="isFav" class="fav" alt="">
          </router-link>
        </li>
        <!-- <li class="nav-item2" id="icon">
          <router-link class="nav-link" :to="{ name: 'Cart'}"><span class="icon-briefcase"></span></router-link>
        </li> -->
      <div class="div-lang">
        <li class="nav-item2">
          <div class="search_field d-flex align-items-cemter" id="search">
            <input class="inp" :placeholder=" $t('topMenu.search')" v-model="searchQuery" v-on:keyup.enter="searchClicked"/>
            <button class="search-button" type="submit" @click="searchClicked">
              <span class="icon-search"></span>
            </button>
          </div>
        </li>
        <li class="nav-item2">
          <div class="dropdown" id="dropdown">
            <button class="btn .bg-white dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">{{ $i18n.locale.toUpperCase() }}</button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <li>
                <a href="#" @click.prevent="setLocale('en')" class="dropdown-item">
                  <span class="ml-2 h-8 w-8">English</span>
                </a>
              </li>
              <li>
                <a href="#" @click.prevent="setLocale('pt')" class="dropdown-item">
                  <span class="ml-2">Portuguese</span>
                </a>
              </li>
            </ul>
          </div>
        </li>
      </div>
        <li class="nav-item2" id="user">
            <!-- Icon and text -->
            <router-link v-if="!user" :to="{name: 'Login'}" class="user-container d-flex flex-column justify-content-center">
              <span class="icon-user"></span>
              <p class="user-text">{{$t("login.login").toUpperCase()}}</p>
            </router-link>
            <div v-else v-b-toggle.sidebar-right class="user-container d-flex flex-column justify-content-center">
              <GalleryPicker :value="photoUrl" :isPicker="false" :maxSide="65" :ratioWidth="1" :ratioHeight="1"/>
            </div>
        </li>
      </div>
    </nav>
  </div>
</template>

<script>
import SideMenu from "@/components/SideMenu.vue";
import RightLateralMenu from "@/components/RightLateralMenu.vue";
import { mapState } from 'vuex';
import GalleryPicker from "@/components/GalleryPicker.vue";
import { API_HELPER } from "@/helpers/api.js";
import { UTILS_HELPER } from "@/helpers/utils.js";

export default {
  name: "TopMenu",
  props: {
    msg: String,
  },
  components: {
    SideMenu,
    RightLateralMenu,
    GalleryPicker
  },
  data() {
    return {
      searching: false,
      searchQuery: '',
    };
  },
  computed:{
     ...mapState('account', ['user']),
      userInfo: function()
      {
        return this.user && this.user.data ? this.user.data : null;
      },
      photoUrl: function()
      {
        var result = null;

        if(this.userInfo)
        {
          if(this.userInfo.profile && this.userInfo.profile.photo)
          {
            result = {url: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.userInfo.profile.photo])};
          }
          else if(this.userInfo.questions && this.userInfo.questions.length >= 4)
          {
            const tourismType = this.userInfo.questions[3].answer;
            const gender = this.userInfo.questions[4].answer == "male" ? "Male" : "Female";

            result = {url: API_HELPER.pathJoin([process.env.VUE_APP_FRONT, "profile", `${UTILS_HELPER.convertToPascalCase(tourismType)}${gender}.png`])};
          }
        }

        return result;
      },
      isFav() {
        return this.$route.name == 'Favorites' ? require('../assets/Fav/fav-02.svg') : require('../assets/Fav/fav-01.svg');
      }
  },
  methods: {
    setLocale(locale) {
      this.$i18n.locale = locale;
      localStorage.setItem('locale', locale);
      this.$router.push("/");
      this.$router.go(0);
    },
    searchClicked()
    {
      if(this.searchQuery)
      {
        this.$router.push({name: 'SearchResults', query: { query: this.searchQuery } });
        this.searchQuery = '';
        this.searching = false;
      }
      else
      {
        this.searching = !this.searching;
      }
    },
    sendClicked (id) {
      this.$router.push({name: 'Page', query: {id: id} });
    },
  }
};
</script>
<style scoped>
a {
  text-decoration: none;
  cursor: pointer;
}
[class^="icon-"],
[class*=" icon-"] {
  color: white;
}
.btn .b-icon.bi,
.nav-link .b-icon.bi,
.dropdown-toggle .b-icon.bi,
.dropdown-item .b-icon.bi,
.input-group-text .b-icon.bi {
  font-size: 40px !important;
}
.dropdown-toggle::after {
  display: none;
}
#left-side-element {
  width: 20%;
}
#main-menu {
  width: 60%;
}
#right-side-element {
  width: 35%;
}
.nav {
  background: linear-gradient(to top, rgb(26, 25, 25), #a3161c);
  padding: 0;
  margin: 0;
  box-shadow: 0 0.5rem 0.5rem rgb(0 0 0 / 70%) !important;
}
.navbar-nav a {
  color: #fff !important;
  text-decoration: none;
}
.nav-link:hover {
  color: #fff !important;
  opacity: 0.8;
  text-decoration: none !important;
}
#logo-position{
  height: 90px;
}
.nav-item {
  position: relative;
  white-space: nowrap;
  margin: auto 30px;
}
.nav-item2 {
  position: relative;
  white-space: nowrap;
  margin: auto 0;
}
.dropdown-menu {
  top: 35px !important;
  left: 25px !important;
}
.dropdown-menu a {
  color: black !important;
  margin: 0 10px !important;
  padding: 0px !important;
  text-decoration: none !important;
}
.dropdown > button {
  color: white;
  float: right;
}
.dropdown ul li {
  width: 145px;
}
.dropdown ul a:focus {
  color: #fff !important;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.9), #a3161c);
  border-color: #a3161c !important;
}
.div-lang {
  margin-right: 1rem;
  margin-left: 1rem;
}
.search_field {
  border-bottom: 1px solid white;
}
.inp {
  border: none;
  background-color: transparent !important;
  color: white !important;
  outline: none;
  font-size: 0.8rem;
  margin-top: 30px;
}
::placeholder {
  color: white;
  opacity: 1;
}
[placeholder]:focus::-webkit-input-placeholder {
  transition: text-indent 0.4s 0.4s ease;
  text-indent: -100%;
  opacity: 1;
}
.search-button {
  background: transparent;
  border: none;
  outline: none;
  /* font-size: 70px; */
  position: relative;
  white-space: nowrap;
  margin: auto 0;
  height: 30px;
}
.search-button:hover {
  color: #fff !important;
  opacity: 0.8;
  text-decoration: none !important;
}
.user-container {
  border-left: 1px solid white !important;
  padding: 0 10px;
}
a.user-container {
  color: transparent;
}
.icon-user {
  font-size: 70px;
  color: white;
  text-align: center;
}
>>> .user-text {
  margin: 0 auto;
  padding: 0 11px;
  position: absolute;
  top: 60%;
  color: white !important;
  font-size: 10px;
}
>>> .user-text_2 {
  margin: 0 auto;
  padding: 0 8px;
  position: absolute;
  top: 60%;
  color: white !important;
  font-size: 10px;
}
>>> .vueperslides:not(.no-shadow):not(.vueperslides--3d)
  .vueperslides__parallax-wrapper::before {
  box-shadow: none !important;
}
.fav {
  width: 27px;
  margin-bottom: 8px;
}
@media (max-width: 1800px) {
  .icon-search:before {
    font-size: 50px;
  }
  #heart,
  #icon {
    width: 50px;
  }
  .navbar-nav {
    font-size: 14px;
  }
  .nav-item {
    margin: auto 25px;
  }
}
@media (max-width: 1650px) {
  .nav-item {
    margin: auto 15px;
  }
}
@media (max-width: 1470px) {
  #left-side-element {
    width: 10%;
  }
}
@media (max-width: 1400px) {
  .navbar-nav {
    font-size: 13px;
  }
  .nav-item {
    margin: auto 10px;
  }
  .icon-search:before {
    font-size: 40px;
  }
}
@media (max-width: 1300px) {
  .navbar-nav {
    font-size: 11px;
  }
}
@media (max-width: 880px) {
  #heart,
  #icon {
    width: 50px;
  }
  .icon-heart:before {
    font-size: 55px;
  }
  .icon-search:before {
    font-size: 55px;
  }
  .icon-briefcase:before {
    font-size: 55px;
  }
}
@media (max-width: 502px) {
  .search_field {
    display: none !important;
  }
  .div-lang {
    margin-top: 1rem;
  }
}
</style>